import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Salary {
  getSalaryYear(
    settlement_of_month_begin,
    settlement_of_month_end,
    organisation_ids,
    department_ids,
    want_mul_exchage
  ) {
    return axios.get(
      `/api/admin/salary/year/list`,
      {
        params: {
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          organisation_ids: organisation_ids,
          department_ids: department_ids,
          want_mul_exchage: want_mul_exchage,
          page: 1,
          page_num: 50000,
        },
      },
      config
    );
  }
  getWaterExcelInfo(
    settlement_of_month_begin,
    settlement_of_month_end,
    recipient_employer_id
  ) {
    return axios.get(
      `/api/admin/salary/water/list`,
      {
        params: {
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          recipient_employer_id: recipient_employer_id,
          want_mul_exchage: true,
          page_num: 50000,
        },
      },
      config
    );
  }
  getWaterExcel(
    settlement_of_month_begin,
    settlement_of_month_end,
    organisation_ids,
    salary_item_ids,
    want_mul_exchage,
    department_ids,
    nationality_ids
  ) {
    return axios.get(
      `/api/admin/salary/water/list`,
      {
        params: {
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          organisation_ids: organisation_ids,
          salary_item_ids: salary_item_ids,
          want_mul_exchage: want_mul_exchage,
          department_ids: department_ids,
          nationality_ids: nationality_ids,
          page_num: 50000,
        },
      },
      config
    );
  }
  getSalaryExcel(
    settlement_of_month_begin,
    settlement_of_month_end,
    organisation_ids,
    salary_item_ids,
    want_mul_exchage,
    department_ids,
    nationality_ids
  ) {
    return axios.get(
      `/api/admin/salary/export/list`,
      {
        params: {
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          organisation_ids: organisation_ids,
          salary_item_ids: salary_item_ids,
          want_mul_exchage: want_mul_exchage,
          department_ids: department_ids,
          nationality_ids: nationality_ids,
          page_num: 50000,
        },
      },
      config
    );
  }
  getSalaryInfo(id) {
    return axios.get(
      `/api/admin/salary/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getSalaryList(
    recipient_employer_id,
    pay_date_begin,
    pay_date_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/salary/list`,
      {
        params: {
          recipient_employer_id: recipient_employer_id,
          pay_date_begin: pay_date_begin,
          pay_date_end: pay_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchSalary(datas) {
    return axios.post(
      `/api/admin/salary/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  settlementSalary(salary_item_id, pay_date, settlement_of_month) {
    return axios.post(
      `/api/admin/salary/settlement/`,
      {
        salary_item_id: salary_item_id,
        pay_date: pay_date,
        settlement_of_month: settlement_of_month,
      },
      config
    );
  }
  settlementIncomeTax(salary_item_id, pay_date, settlement_of_month) {
    return axios.post(
      `/api/admin/salary/settlement/income-tax/`,
      {
        salary_item_id: salary_item_id,
        pay_date: pay_date,
        settlement_of_month: settlement_of_month,
      },
      config
    );
  }
  addSalary(
    salary_item_id,
    recipient_employer_id,
    description,
    dollar,
    currency_id,
    pay_date,
    settlement_of_month
  ) {
    return axios.post(
      `/api/admin/salary`,
      {
        salary_item_id: salary_item_id,
        recipient_employer_id: recipient_employer_id,
        description: description,
        dollar: dollar,
        currency_id: currency_id,
        pay_date: pay_date,
        settlement_of_month: settlement_of_month,
      },
      config
    );
  }
  deleteSalary(id) {
    return axios.delete(
      `/api/admin/salary`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
  deleteSalaryBatch(
    organisation_id,
    salary_item_id,
    settlement_of_month_begin,
    settlement_of_month_end
  ) {
    return axios.delete(
      `/api/admin/salary`,
      {
        data: {
          organisation_id: organisation_id,
          salary_item_id: salary_item_id,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
        },
      },
      config
    );
  }
}
