<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import { useStore } from "vuex";
import { dateFormate } from "../tool/formate";
import pass from "../assets/pass.jpg";
const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({});
const beforemounted = onBeforeMount(() => {});
const mounted = onMounted(() => {});
</script>

<template>
  <div
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div style="width: 100%; text-align: center">
      <h2>南緯集團 <br />員工核薪表</h2>
    </div>
    <div style="width: 100%; text-align: right">
      <p>
        申請日期：
        {{ dateFormate(store.state.salary.created_at, "YYYY年MM月DD日") }}
      </p>
    </div>
    <div style="border: 1px solid black; width: 100%">
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 姓名 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 25%; text-align: center"
        >
          &nbsp;
          {{
            store.state.employer != null
              ? store.state.employer.name +
                "/" +
                store.state.employer.english_name
              : ""
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 25%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 員工代號 &nbsp;
        </div>
        <div style="width: 27%; text-align: center">
          &nbsp;
          {{ store.state.employer != null ? store.state.employer.id : "" }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 報到日期 &nbsp;
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 25%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp;
          {{
            store.state.employer_detail != null
              ? dateFormate(
                  store.state.employer_detail.start_working,
                  "YYYY-MM-DD"
                )
              : ""
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 25%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 生效日期 &nbsp;
        </div>
        <div
          style="
            width: 27%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp;
          {{ dateFormate(store.state.salary.effective_date, "YYYY-MM-DD") }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 薪資異動別 &nbsp;
        </div>
        <div style="width: 76%; text-align: center">
          &nbsp;
          <span :key="index" v-for="(item, index) in store.state.salary.type">
            ◼ {{ item }}
          </span>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 調整說明 &nbsp;
        </div>
        <div style="width: 76%; text-align: center">
          &nbsp;{{ store.state.salary.reason }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 項目 &nbsp;
        </div>
        <div
          style="
            width: 76%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-right: 1px solid black;
              width: 49%;
              text-align: center;
              padding-top: 5px;
              padding-bottom: 5px;
            "
          >
            異動前
          </div>
          <div
            style="
              width: 49%;
              text-align: center;
              padding-top: 5px;
              padding-bottom: 5px;
            "
          >
            異動後
          </div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 服務單位 &nbsp;
        </div>
        <div
          style="
            width: 76%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-right: 1px solid black;
              width: 49%;
              text-align: center;
            "
          >
            &nbsp;
            {{
              store.state.employer != null
                ? store.state.employer.organisation_name
                : ""
            }}
          </div>
          <div style="width: 49%; text-align: center">
            &nbsp;
            {{
              store.state.employer != null
                ? store.state.employer.organisation_name
                : ""
            }}
          </div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 職務 &nbsp;
        </div>
        <div
          style="
            width: 76%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-right: 1px solid black;
              width: 49%;
              text-align: center;
            "
          >
            &nbsp;
            {{
              store.state.employer != null
                ? store.state.employer.positions != [] &&
                  store.state.employer.positions != null
                  ? store.state.employer.positions[0].name
                  : ""
                : ""
            }}
          </div>
          <div style="width: 49%; text-align: center">
            &nbsp;
            {{
              store.state.employer != null
                ? store.state.employer.positions != [] &&
                  store.state.employer.positions != null
                  ? store.state.employer.positions[0].name
                  : ""
                : ""
            }}
          </div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 職等 &nbsp;
        </div>
        <div
          style="
            width: 76%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-right: 1px solid black;
              width: 49%;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div style="width: 49%; text-align: center">&nbsp;</div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 3%; text-align: center"
        >
          薪資結構
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 19%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              width: 100%;
              height: 30px;
              text-align: center;
            "
          >
            本薪
          </div>
          <div
            style="
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              width: 100%;
              height: 30px;
              text-align: center;
            "
          >
            伙食津貼
          </div>
          <div
            style="
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              width: 100%;
              height: 30px;
              text-align: center;
            "
          >
            職務加給
          </div>
          <div
            style="
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              width: 100%;
              height: 30px;
              text-align: center;
            "
          >
            派外津貼
          </div>
          <div
            style="
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              width: 100%;
              height: 30px;
              text-align: center;
            "
          >
            合計
          </div>
        </div>
        <div
          style="
            width: 77.5%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              width: 47.8%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 52%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              width: 47.8%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 52%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              width: 47.8%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 52%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              width: 47.8%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 52%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              width: 47.8%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;{{ store.state.salary.before_salary }}
          </div>
          <div
            style="
              border-bottom: 1px solid black;
              width: 52%;
              height: 30px;
              text-align: center;
            "
          >
            &nbsp;{{ store.state.salary.new_salary }}
          </div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 備註 &nbsp;
        </div>
        <div
          style="
            width: 76%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-right: 1px solid black;
              width: 49%;
              text-align: center;
            "
          >
            &nbsp;
          </div>
          <div style="width: 49%; text-align: center">&nbsp;</div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 主管批示 &nbsp;
        </div>
        <div
          style="
            width: 76%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          &nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: center;
        "
      >
        <h4 style="width: 100%">會簽單位</h4>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: center;
        "
      >
        <h4 style="width: 100%">&nbsp;</h4>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: center;
        "
      >
        <h4 style="width: 100%">申請單位</h4>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 33%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 核准(產區總經理) &nbsp;
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 33%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 副核(事業處主管) &nbsp;
        </div>
        <div
          style="
            width: 33%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 申請(單位主管) &nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 33%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          <h4>
            &nbsp;
            <div v-if="store.state.salary != null">
              <img
                v-if="store.state.salary.status == 2"
                style="height: 130px; width: 140px"
                :src="pass"
              />
            </div>
          </h4>
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 33%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          <h4>&nbsp;</h4>
        </div>
        <div
          style="
            width: 33%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          <h4>&nbsp;</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
