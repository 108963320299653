import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class WorkPermit {
  getWorkPermitInfo(id) {
    return axios.get(
      `/api/admin/work-permit/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getWorkPermitList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/work-permit/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getWorkPermitSelectList(name) {
    return axios.get(
      `/api/admin/work-permit/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchWorkPermit(datas) {
    return axios.post(
      `/api/admin/batch/work-permit`,
      {
        datas: datas,
      },
      config
    );
  }
  addWorkPermit(name, description) {
    return axios.post(
      `/api/admin/work-permit`,
      {
        name: name,
        description: description,
      },
      config
    );
  }
  editWorkPermit(id, name, description) {
    return axios.put(
      `/api/admin/work-permit`,
      {
        id: id,
        name: name,
        description: description,
      },
      config
    );
  }
  deleteWorkPermit(id) {
    return axios.delete(
      `/api/admin/work-permit`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
