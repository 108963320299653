<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

// const i18n = useI18n();
// const toast = useToast();
import { useStore } from "vuex";
import { dateFormate } from "../tool/formate";
import pass from "../assets/pass.jpg";
const store = useStore();
const data = reactive({
  date: new Date(),
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div style="border: 1px solid black; width: 100%">
      <div
        style="border-bottom: 1px solid black; width: 100%; text-align: center"
      >
        <h2>LOAN SLIP<br />借支單</h2>
        <p>
          FACTORY(廠別)： 口TEXRAY 口TQM/TLS 口KASUMI 03 口GOLDEN 口JM
          口_________
        </p>
      </div>
      <!-- <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div style="border-right: 1px solid black; width: 31%">
          testestjosetjo
        </div>
        <div style="border-right: 1px solid black; width: 33%">
          testestjosetjo
        </div>
        <div style="width: 35%">testestjosetjo</div>
      </div> -->
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 13%; text-align: center"
        >
          &nbsp; DATE &nbsp;<br />&nbsp; (日期) &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 17%; text-align: center"
        >
          {{
            store.state.borrow == null
              ? ""
              : dateFormate(store.state.borrow.created_at, "YYYY-MM-DD")
          }}
        </div>
        <div
          style="border-right: 1px solid black; width: 13%; text-align: center"
        >
          &nbsp; DEPT &nbsp;<br />&nbsp; (部門) &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 17%; text-align: center"
        >
          {{
            store.state.employer != null
              ? store.state.employer.organisation_name
              : ""
          }}
        </div>
        <div
          style="border-right: 1px solid black; width: 20%; text-align: center"
        >
          &nbsp; VOUCHER No. &nbsp;<br />&nbsp; (傳票單號) &nbsp;
        </div>
        <div style="width: 17%; text-align: center"></div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 25%; text-align: center"
        >
          &nbsp; CHINESE NAME &nbsp;<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (中文姓名)
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 20%; text-align: center"
        >
          &nbsp; ENGLISH NAME &nbsp;<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (英文姓名)
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 20%; text-align: center"
        >
          US <br />
          &nbsp; AMOUNT &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 18%; text-align: center"
        >
          &nbsp; RATE &nbsp;
        </div>
        <div style="width: 15%; text-align: center">
          &nbsp; RAND &nbsp;<br />
          &nbsp; AMOUNT &nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div style="border-right: 1px solid black; width: 25%; height: 40px">
          &nbsp;{{
            store.state.employer != null ? store.state.employer.name : ""
          }}
        </div>
        <div style="border-right: 1px solid black; width: 20%">
          &nbsp;
          {{
            store.state.employer != null
              ? store.state.employer.english_name
              : ""
          }}
        </div>
        <div style="border-right: 1px solid black; width: 20%">
          &nbsp;
          {{ store.state.borrow != null ? store.state.borrow.dollar : "" }}
        </div>
        <div style="border-right: 1px solid black; width: 18%">
          &nbsp;
          {{
            store.state.borrow != null ? store.state.borrow.exchange_rate : ""
          }}
        </div>
        <div style="width: 15%">
          &nbsp;
          {{
            store.state.borrow != null
              ? store.state.borrow.dollar * store.state.borrow.exchange_rate
              : ""
          }}
        </div>
      </div>
      <div style="width: 100%; text-align: left">
        <h4 style="margin-top: -3px">&nbsp;Description</h4>
      </div>
      <div
        style="border-bottom: 1px solid black; width: 100%; text-align: center"
      >
        <h3 style="text-align: center">
          Salary Loan for month of
          {{
            store.state.borrow != null
              ? dateFormate(store.state.borrow.apply_date, "YYYY-MM")
              : ""
          }}
          <br />Cash or Card
        </h3>
      </div>
      <div
        style="border-bottom: 1px solid black; width: 100%; text-align: left"
      >
        <div v-if="store.state.borrow != null">
          &nbsp;
          <img
            v-if="store.state.borrow.status == 2"
            style="height: 100px; width: 120px; margin-top: 2px"
            :src="pass"
          />
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 16%; text-align: center"
        >
          廠區總經理<br />
          Eswatini Managing Director
        </div>
        <div
          style="border-right: 1px solid black; width: 16%; text-align: center"
        >
          事業部主管<br />
          Chief of Department
        </div>
        <div
          style="border-right: 1px solid black; width: 16%; text-align: center"
        >
          管理部主管<br />
          Chief of Admin Department
        </div>
        <div
          style="border-right: 1px solid black; width: 16%; text-align: center"
        >
          財務部主管<br />
          Finacial Director
        </div>
        <div
          style="border-right: 1px solid black; width: 16%; text-align: center"
        >
          部門主管<br />
          Manager
        </div>
        <div style="width: 16%; text-align: center">
          借支人員<br />
          Applicant Employee
        </div>
      </div>
      <div
        style="border-bottom: 1px solid black; width: 100%; text-align: left"
      >
        <h5 style="padding-left: 5px">
          REMARK備註：<br />
          1.借款一律以美金計算，匯率已核准借支當天銀行匯率換算，領取當地貨幣(RAND)
          <br />
          LOAN IS VALUED AT DOLLAR AND WILL BE PAID AT RAND; THE EXCHANGE RATE
          WILL BE THE SPOT RATE OF APPROVAL DATE.
        </h5>
        <h5 style="padding-left: 5px">
          2.每季借支款不得超過兩個月薪資
          <br />
          ACCUMULATED LOAN AMOUNT SHOULD NOT BE OVER TWO MONTHS SALARY
        </h5>
        <h5 style="padding-left: 5px">
          3.本人同以上借支款從薪資中扣除，不得異議
          <br />
          I AGREE TO DEDUCT LOAN AMOUNT FROM MY SALARY
        </h5>
        <h5 style="padding-left: 5px">
          4.到職未滿三個月，不得借支
          <br />
          ONLY EMPLOYEES WHO SERVED THE COMPANY OVER 3 MONTH COULD APPLY A LOAN
        </h5>
        <h5 style="padding-left: 5px">
          5.本人同意以上備註說明事項，絕無意義
          <br />
          I AGREE ALL THE ABOVE ITEMS
        </h5>

        <h5 style="padding-left: 5px">簽收金額(RECEIVED AMOUNT):</h5>
        <h5 style="padding-left: 5px">簽收人員(SIGNATURE):</h5>
        <h5 style="padding-left: 5px">簽收日期(RECEIVED DATE):</h5>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
