import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class AccountItem {
  getAccountItemInfo(id) {
    return axios.get(
      `/api/admin/account-item/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getAccountItemList(id, name, account_type_id, company_id, page, page_num) {
    return axios.get(
      `/api/admin/account-item/list`,
      {
        params: {
          id: id,
          name: name,
          account_type_id: account_type_id,
          company_id: company_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getAccountItemSelectList(name, company_id) {
    return axios.get(
      `/api/admin/account-item/select-list`,
      {
        params: {
          name: name,
          company_id: company_id,
        },
      },
      config
    );
  }
  batchAccountItem(datas) {
    return axios.post(
      `/api/admin/account-item/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addAccountItem(name, description, account_type_id, company_id) {
    return axios.post(
      `/api/admin/account-item/`,
      {
        name: name,
        description: description,
        account_type_id: account_type_id,
        company_id: company_id,
      },
      config
    );
  }
  editAccountItem(id, name, description, account_type_id, company_id) {
    return axios.put(
      `/api/admin/account-item/`,
      {
        id: id,
        name: name,
        description: description,
        account_type_id: account_type_id,
        company_id: company_id,
      },
      config
    );
  }
  deleteAccountItem(id) {
    return axios.delete(
      `/api/admin/account-item/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
