import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class DocumentType {
  getDocumentTypeInfo(id) {
    return axios.get(
      `/api/manager/document-type/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getDocumentTypeList(id, name, page, page_num) {
    return axios.get(
      `/api/manager/document-type/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getDocumentTypeSelectList(name) {
    return axios.get(
      `/api/manager/document-type/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
}
