<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Sidebar from "primevue/sidebar";
import Paginator from "primevue/paginator";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import { usePDF, VuePDF } from "@tato30/vue-pdf";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import AdminDocumentType from "../../api/salary/admin/document_type";
import AdminDocument from "../../api/salary/admin/document";
import ManagerDocumentType from "../../api/salary/manager/document_type";
import ManagerDocument from "../../api/salary/manager/document";
import SelfDocumentType from "../../api/salary/self/document_type";
import SelfDocument from "../../api/salary/self/document";
import AdminOrganisation from "../../api/salary/admin/organisation";
import AdminDepartment from "../../api/salary/admin/department";
import ManagerOrganisation from "../../api/salary/manager/organisation";
import ManagerDepartment from "../../api/salary/manager/department";
import SelfOrganisation from "../../api/salary/self/organisation";
import SelfDepartment from "../../api/salary/self/department";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let admin_document_typeAPI = new AdminDocumentType();
let manager_document_typeAPI = new ManagerDocumentType();
let self_document_typeAPI = new SelfDocumentType();

let admin_documentAPI = new AdminDocument();
let manager_documentAPI = new ManagerDocument();
let self_documentAPI = new SelfDocument();

let admin_organisationAPI = new AdminOrganisation();
let admin_departmentsAPI = new AdminDepartment();
let manager_organisationAPI = new ManagerOrganisation();
let manager_departmentsAPI = new ManagerDepartment();
let self_organisationAPI = new SelfOrganisation();
let self_departmentsAPI = new SelfDepartment();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  add_document: {
    document_type_id: "",
    title: "",
    content: "",
    organisation_id: "",
    department_id: "",
    status: "",
    published_at: "",
    end_at: "",
  },
  documentLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  documents: { data: null },
  document: null,
  page: 1,
  page_num: 10,
  selecteddocuments: null,
  OrganisationSearch: "",
  DepartmentSearch: "",
  DocumentTypeSearch: "",
  selectOrganisationList: [],
  selectDepartmentsList: [],
  selectDocumentTypeList: [],
  documentFilters: {
    id: { value: "", matchMode: "contains" },
    document_type_id: { value: "", matchMode: "contains" },
    title: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
    department_id: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    published_at_begin: { value: "", matchMode: "contains" },
    published_at_end: { value: "", matchMode: "contains" },
    end_at_begin: { value: "", matchMode: "contains" },
    end_at_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getDocumentList();
    getDepartmentSelectList();
    getOrganisationSelectList();
    getDocumentTypeSelectList();
  }
});
function showPDF(url) {
  data.PDFShowDialog = true;
  data.pdf = usePDF(url);
  data.pdf.page = 1;
  // console.log(data.pdf);
}
function onPDFPage(event) {
  data.pdf.page = event.page + 1;
}
function OpenPdf(tmp_data, e) {
  // console.log(e.target.files[0]);
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["pdf"];
  const arr = file.name.split(".");
  data.PDF_Name = file.name;
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (pdf)");
    return;
  }
  let reader = new FileReader();
  let pdf_file = "";
  //启动函数
  reader.readAsDataURL(file);
  reader.onload = async function () {
    // console.log(reader.result);
    pdf_file = await reader.result.replace("data:application/pdf;base64,", "");
    if (tmp_data.id != 0) {
      switch (store.state.user.role) {
        case 1:
          admin_documentAPI
            .editDocumentPDF(
              tmp_data.id,
              pdf_file,
              tmp_data.title,
              tmp_data.content
            )
            .then((res) => {
              if (res.data.error_code == 0) {
                toast.add({
                  severity: "success",
                  summary: "Success Message",
                  detail: i18n.$t("DocumentType").EditSuccess,
                  life: 3000,
                });

                data.documentLoading = true;
                getDocumentList();
              } else {
                toast.add({
                  severity: "warn",
                  summary: "Warn Message",
                  detail: res.data.error_text,
                  life: 3000,
                });
              }
            });
          break;
        case 2:
          manager_documentAPI
            .editDocumentPDF(
              tmp_data.id,
              pdf_file,
              tmp_data.title,
              tmp_data.content
            )
            .then((res) => {
              if (res.data.error_code == 0) {
                toast.add({
                  severity: "success",
                  summary: "Success Message",
                  detail: i18n.$t("DocumentType").EditSuccess,
                  life: 3000,
                });

                data.documentLoading = true;
                getDocumentList();
              } else {
                toast.add({
                  severity: "warn",
                  summary: "Warn Message",
                  detail: res.data.error_text,
                  life: 3000,
                });
              }
            });
          break;
      }
    }
  };
}

function getOrganisationSelectList() {
  switch (store.state.user.role) {
    case 1:
      admin_organisationAPI.getOrganisationSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectOrganisationList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_organisationAPI.getOrganisationSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectOrganisationList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 3:
      self_organisationAPI.getOrganisationSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectOrganisationList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
function getDepartmentSelectList() {
  switch (store.state.user.role) {
    case 1:
      admin_departmentsAPI.getDepartmentSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectDepartmentsList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_departmentsAPI.getDepartmentSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectDepartmentsList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 3:
      self_departmentsAPI.getDepartmentSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectDepartmentsList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
function getDocumentTypeSelectList() {
  switch (store.state.user.role) {
    case 1:
      admin_document_typeAPI.getDocumentTypeSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectDocumentTypeList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_document_typeAPI.getDocumentTypeSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectDocumentTypeList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 3:
      self_document_typeAPI.getDocumentTypeSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectDocumentTypeList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}

function getDocumentList() {
  CheckUser();
  switch (store.state.user.role) {
    case 1:
      admin_documentAPI
        .getDocumentList(
          data.documentFilters.id.value,
          data.documentFilters.document_type_id.value,
          data.documentFilters.title.value,
          data.documentFilters.organisation_id.value,
          data.documentFilters.department_id.value,
          data.documentFilters.status.value,
          data.documentFilters.published_at_begin.value,
          data.documentFilters.published_at_end.value,
          data.documentFilters.end_at_begin.value,
          data.documentFilters.end_at_end.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.documents.data = res.data.data.datas;
            data.page = res.data.data.page;
            // data.page_num = res.data.data.page_num;
            // console.log(res);
            data.documentLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.documentLoading = false;
          }
        });

      break;
    case 2:
      manager_documentAPI
        .getDocumentList(
          data.documentFilters.id.value,
          data.documentFilters.document_type_id.value,
          data.documentFilters.title.value,
          data.documentFilters.organisation_id.value,
          data.documentFilters.department_id.value,
          data.documentFilters.status.value,
          data.documentFilters.published_at_begin.value,
          data.documentFilters.published_at_end.value,
          data.documentFilters.end_at_begin.value,
          data.documentFilters.end_at_end.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.documents.data = res.data.data.datas;
            data.page = res.data.data.page;
            // data.page_num = res.data.data.page_num;
            // console.log(res);
            data.documentLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.documentLoading = false;
          }
        });

      break;
    case 3:
      self_documentAPI
        .getDocumentList(
          data.documentFilters.id.value,
          data.documentFilters.document_type_id.value,
          data.documentFilters.title.value,
          data.documentFilters.organisation_id.value,
          data.documentFilters.department_id.value,
          data.documentFilters.status.value,
          data.documentFilters.published_at_begin.value,
          data.documentFilters.published_at_end.value,
          data.documentFilters.end_at_begin.value,
          data.documentFilters.end_at_end.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.documents.data = res.data.data.datas;
            data.page = res.data.data.page;
            // data.page_num = res.data.data.page_num;
            // console.log(res);
            data.documentLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.documentLoading = false;
          }
        });

      break;
  }
}

function saveEditDocument() {
  switch (store.state.user.role) {
    case 1:
      admin_documentAPI
        .editDocument(
          data.document.id,
          data.document.document_type_id,
          data.document.title,
          data.document.content,
          data.document.organisation_id,
          data.document.department_id,
          data.document.status,
          data.document.published_at,
          data.document.end_at
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("DocumentType").EditSuccess,
              life: 3000,
            });

            data.documentLoading = true;
            getDocumentList();
            data.editDialog = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
    case 2:
      manager_documentAPI
        .editDocument(
          data.document.id,
          data.document.document_type_id,
          data.document.title,
          data.document.content,
          data.document.organisation_id,
          data.document.department_id,
          data.document.status,
          data.document.published_at,
          data.document.end_at
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("DocumentType").EditSuccess,
              life: 3000,
            });

            data.documentLoading = true;
            getDocumentList();
            data.editDialog = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
  }
}

function saveAddDocument() {
  switch (store.state.user.role) {
    case 1:
      admin_documentAPI
        .addDocument(
          data.add_document.document_type_id,
          data.add_document.title,
          data.add_document.content,
          data.add_document.organisation_id,
          data.add_document.department_id,
          data.add_document.status,
          data.add_document.published_at,
          data.add_document.end_at
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("DocumentType").AddSuccess,
              life: 3000,
            });

            data.documentLoading = true;
            getDocumentList();
            data.addDialog = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
    case 2:
      manager_documentAPI
        .addDocument(
          data.add_document.document_type_id,
          data.add_document.title,
          data.add_document.content,
          data.add_document.organisation_id,
          data.add_document.department_id,
          data.add_document.status,
          data.add_document.published_at,
          data.add_document.end_at
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("DocumentType").AddSuccess,
              life: 3000,
            });

            data.documentLoading = true;
            getDocumentList();
            data.addDialog = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });

      break;
  }
}

function addDocument() {
  data.addDialog = true;

  data.add_document = {
    document_type_id: "",
    title: "",
    content: "",
    organisation_id: "",
    department_id: "",
    status: 1,
    published_at: "",
    end_at: "",
  };
  data.OrganisationAddSearch = "";
  data.DepartmentsAddSearch = "";
  data.DocumentTypeAddSearch = "";
}
function editDocument(document) {
  data.document = { ...document };
  for (let i = 0; i < data.selectOrganisationList.length; i++) {
    if (data.selectOrganisationList[i].id == document.organization_id) {
      data.OrganisationEditSearch = data.selectOrganisationList[i];
      break;
    }
  }
  for (let i = 0; i < data.selectDocumentTypeList.length; i++) {
    if (data.selectDocumentTypeList[i].id == document.document_type_id) {
      data.DocumentTypeEditSearch = data.selectDocumentTypeList[i];
      break;
    }
  }
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(document) {
  data.document = document;
  data.deleteDialog = true;
}
function deleteDocument() {
  switch (store.state.user.role) {
    case 1:
      admin_documentAPI.deleteDocument([data.document.id]).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Document").DeleteSuccess,
            life: 3000,
          });

          data.documentLoading = true;
          getDocumentList();
          data.deleteDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_documentAPI.deleteDocument([data.document.id]).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("Document").DeleteSuccess,
            life: 3000,
          });

          data.documentLoading = true;
          getDocumentList();
          data.deleteDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
function deleteSelectedDocument() {
  let selectDelete = [];
  for (let i = 0; i < data.selecteddocuments.length; i++) {
    selectDelete.push(data.selecteddocuments[i].id);
  }
  switch (store.state.user.role) {
    case 1:
      admin_documentAPI.deleteDocument(selectDelete).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("DocumentType").DeleteSuccess,
            life: 3000,
          });

          data.documentLoading = true;
          data.selecteddocuments = [];
          getDocumentList();
          data.deleteSelectedDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });

      break;
    case 2:
      manager_documentAPI.deleteDocument(selectDelete).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("DocumentType").DeleteSuccess,
            life: 3000,
          });

          data.documentLoading = true;
          data.selecteddocuments = [];
          getDocumentList();
          data.deleteSelectedDialog = false;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });

      break;
  }
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.documentLoading = true;
    getDocumentList();
  }
);

watch(
  () => data.documentFilters.id.value,
  () => {
    data.documentLoading = true;
    getDocumentList();
  }
);

watch(
  () => data.OrganisationSearch,
  () => {
    data.documentLoading = true;
    data.documentFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getDocumentList();
  }
);
watch(
  () => data.DepartmentsSearch,
  () => {
    data.documentLoading = true;
    data.documentFilters.department_id.value =
      data.DepartmentsSearch == null ? "" : data.DepartmentsSearch.id;
    getDocumentList();
  }
);
watch(
  () => data.DocumentTypeSearch,
  () => {
    data.documentLoading = true;
    data.documentFilters.document_type_id.value =
      data.DocumentTypeSearch == null ? "" : data.DocumentTypeSearch.id;
    getDocumentList();
  }
);

watch(
  () => data.OrganisationAddSearch,
  () => {
    data.add_document.organisation_id =
      data.OrganisationAddSearch == null ? "" : data.OrganisationAddSearch.id;
  }
);
watch(
  () => data.DepartmentsAddSearch,
  () => {
    data.add_document.department_id =
      data.DepartmentsAddSearch == null ? "" : data.DepartmentsAddSearch.id;
  }
);
watch(
  () => data.DocumentTypeAddSearch,
  () => {
    data.add_document.document_type_id =
      data.DocumentTypeAddSearch == null ? "" : data.DocumentTypeAddSearch.id;
  }
);

watch(
  () => data.OrganisationEditSearch,
  () => {
    data.document.organisation_id =
      data.OrganisationEditSearch == null ? "" : data.OrganisationEditSearch.id;
  }
);
watch(
  () => data.DepartmentsEditSearch,
  () => {
    data.document.department_id =
      data.DepartmentsEditSearch == null ? "" : data.DepartmentsEditSearch.id;
  }
);
watch(
  () => data.DocumentTypeEditSearch,
  () => {
    data.document.document_type_id =
      data.DocumentTypeEditSearch == null ? "" : data.DocumentTypeEditSearch.id;
  }
);
const rowClass = (data) => {
  return [
    { "bg-border-warning": data.attachment_url == "" },
    { "bg-border-normal": data.attachment_url != "" },
  ];
};
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :resizableColumns="true"
      columnResizeMode="fit"
      :lazy="true"
      :stripedRows="true"
      :value="data.documents.data"
      :loading="data.documentLoading"
      dataKey="id"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.documentFilters"
      v-model:selection="data.selecteddocuments"
      :row-hover="false"
      tableStyle="
       
       "
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
      :rowClass="rowClass"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("DocumentType").DocumentTypeTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.documentFilters[
                        i18n.$t('Document').DocumentTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Document').DocumentTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.OrganisationSearch"
                    :options="data.selectOrganisationList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerTable.Organisation.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1 p-d-none">
                  <Dropdown
                    v-model="data.DepartmentsSearch"
                    :options="data.selectDepartmentsList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerDetailTable.Department.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left">
                  <Dropdown
                    v-model="data.DocumentTypeSearch"
                    :options="data.selectDocumentTypeList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Document').DocumentTable.DocumentTypeID.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div
            v-if="store.state.user.role == 1 || store.state.user.role == 2"
            class="p-col-12 p-d-flex p-jc-end"
          >
            <Button
              :label="i18n.$t('Document').DocumentTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addDocument"
            />
            <Button
              :label="i18n.$t('Document').DocumentTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selecteddocuments || !data.selecteddocuments.length
              "
            />
          </div>
        </div>
      </template>
      <Column
        v-if="store.state.user.role == 1 || store.state.user.role == 2"
        selectionMode="multiple"
        style="width: 2rem"
        headerStyle="color:white;background-color: #518EB3; border-radius: 10px 0px 0px 0px;font-size:20px;  "
      ></Column>
      <!--                <Column
        
        :field="i18n.$t('Document').DocumentTable.ID.Field"
        :header="i18n.$t('Document').DocumentTable.ID.Show"
      ></Column> -->
      <Column
        style="word-wrap: break-word; max-width: 5rem"
        headerStyle="color:white;background-color: #518EB3;font-size:20px; "
        :field="i18n.$t('Document').DocumentTable.OrganizationID.Field"
        :header="i18n.$t('Document').DocumentTable.OrganizationID.Show"
      >
        <template #body="slotProps">
          {{
            data.selectOrganisationList.find(
              (organisation) =>
                organisation.id === slotProps.data.organization_id
            ) == undefined
              ? "未知"
              : data.selectOrganisationList.find(
                  (organisation) =>
                    organisation.id === slotProps.data.organization_id
                ).name
          }}
        </template>
      </Column>
      <!-- <Column
        
        :field="i18n.$t('Document').DocumentTable.DepartmentID.Field"
        :header="i18n.$t('Document').DocumentTable.DepartmentID.Show"
      >
        <template #body="slotProps">
          {{
            data.selectDepartmentsList.find(
              (department) => department.id === slotProps.data.department_id
            ) == undefined
              ? "未知"
              : data.selectDepartmentsList.find(
                  (department) => department.id === slotProps.data.department_id
                ).name
          }}
        </template>
      </Column> -->
      <Column
        style="word-wrap: break-word; width: 5rem"
        headerStyle="color:white;background-color: #518EB3;font-size:20px"
        :field="i18n.$t('Document').DocumentTable.DocumentTypeID.Field"
        :header="i18n.$t('Document').DocumentTable.DocumentTypeID.Show"
      >
        <template #body="slotProps">
          {{
            data.selectDocumentTypeList.find(
              (document_type_id) =>
                document_type_id.id === slotProps.data.document_type_id
            ) == undefined
              ? "未知"
              : data.selectDocumentTypeList.find(
                  (document_type_id) =>
                    document_type_id.id === slotProps.data.document_type_id
                ).name
          }}
        </template>
      </Column>
      <Column
        headerStyle="color:white;background-color: #518EB3;font-size:20px"
        style="word-wrap: break-word; max-width: 500px"
        :field="i18n.$t('Document').DocumentTable.Title.Field"
        :header="i18n.$t('Document').DocumentTable.Title.Show"
      ></Column>
      <!-- <Column
        headerStyle="font-size:20px"
        style="word-wrap: break-word; max-width: 400px"
        :field="i18n.$t('Document').DocumentTable.Content.Field"
        :header="i18n.$t('Document').DocumentTable.Content.Show"
      ></Column> -->
      <!-- <Column
        
        :field="i18n.$t('Document').DocumentTable.Status.Field"
        :header="i18n.$t('Document').DocumentTable.Status.Show"
      ></Column> -->
      <Column
        headerStyle="color:white;background-color: #518EB3;font-size:20px"
        style="width: 160px; overflow: auto"
        :field="i18n.$t('Document').DocumentTable.CreatorName.Field"
        :header="i18n.$t('Document').DocumentTable.CreatorName.Show"
      ></Column>
      <!-- <Column
        
        :field="i18n.$t('Document').DocumentTable.PublishedAt.Field"
        :header="i18n.$t('Document').DocumentTable.PublishedAt.Show"
      >
        <template #body="slotProps">
          {{ slotProps.data.published_at.split("T")[0] }}
        </template>
      </Column>
      <Column
        
        :field="i18n.$t('Document').DocumentTable.EndAt.Field"
        :header="i18n.$t('Document').DocumentTable.EndAt.Show"
      >
        <template #body="slotProps">
          {{ slotProps.data.end_at.split("T")[0] }}
        </template>
      </Column> -->
      <Column
        headerStyle="color:white;background-color: #518EB3;font-size:20px"
        style="word-wrap: break-word; max-width: 80px"
        :field="i18n.$t('Document').DocumentTable.CreatedAt.Field"
        :header="i18n.$t('Document').DocumentTable.CreatedAt.Show"
      >
        <template #body="slotProps">
          {{ slotProps.data.created_at.split("T")[0] }}
        </template>
      </Column>

      <Column
        headerStyle="color:white;background-color: #518EB3; border-radius: 0px 10px 0px 0px;font-size:20px; "
        style="width: 100px"
      >
        <template #body="slotProps">
          <div style="width: 100px; display: flex; flex-wrap: wrap">
            <Button
              icon="pi pi-search"
              class="p-button-rounded p-button-info p-ml-1 p-mr-1 p-button-sm"
              @click="editDocument(slotProps.data)"
            />
            <Button
              v-if="store.state.user.role == 1 || store.state.user.role == 2"
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning p-button-sm p-ml-1 p-mr-1"
              @click="confirmDelete(slotProps.data)"
            />
            <Button
              v-if="slotProps.data.attachment_url != ''"
              icon="pi pi-file-pdf"
              class="p-button-rounded p-button-danger p-ml-1 p-mr-1 p-button-sm"
              @click="showPDF(slotProps.data.attachment_url)"
            />
            <label
              v-if="store.state.user.role == 1 || store.state.user.role == 2"
              style="float: left"
              class="p-button-rounded p-component p-button-success p-mr-1 p-ml-1 p-button-sm p-button p-button-icon-only"
            >
              <input
                id="upload_img"
                style="display: none"
                type="file"
                @change="OpenPdf(slotProps.data, $event)"
              />
              <i class="pi pi-cloud-upload"></i>
            </label>
          </div>
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '850px', minWidth: '350px' }"
      :header="i18n.$t('Document').AddDocument.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.OrganizationID.Show
        }}</label>
        <Dropdown
          v-model="data.OrganisationAddSearch"
          :options="data.selectOrganisationList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <!-- <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.DepartmentID.Show
        }}</label>
        <Dropdown
          v-model="data.DepartmentsAddSearch"
          :options="data.selectDepartmentsList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').EmployerDetailTable.Department.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div> -->
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.DocumentTypeID.Show
        }}</label>
        <Dropdown
          v-model="data.DocumentTypeAddSearch"
          :options="data.selectDocumentTypeList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Document').DocumentTable.DocumentTypeID.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.Title.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_document.title"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.Content.Show
        }}</label>
        <Textarea
          id="name"
          v-model.trim="data.add_document.content"
          required="true"
          rows="15"
          cols="20"
        />
      </div>
      <!-- <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.PublishedAt.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.add_document.published_at"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Document').DocumentTable.PublishedAt.Show"
          :showButtonBar="true"
        />
      </div> -->
      <!-- <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.EndAt.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.add_document.end_at"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Document').DocumentTable.EndAt.Show"
          :showButtonBar="true"
        />
      </div> -->

      <template #footer>
        <Button
          :label="i18n.$t('Document').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Document').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddDocument"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Sidebar
      position="right"
      v-model:visible="data.editDialog"
      :style="{ width: '100vw' }"
      :header="i18n.$t('Document').EditDocument.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.Title.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.document.title"
          required="true"
          :disabled="
            !(
              store.state.user.role == 1 ||
              store.state.user.id == data.document.creator_id
            )
          "
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.CreatorName.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.document.creator_name"
          required="true"
          :disabled="true"
        />
      </div>
      <div class="p-field p-grid">
        <div class="p-col-6">
          <label for="name">{{
            i18n.$t("Document").DocumentTable.OrganizationID.Show
          }}</label>
          <Dropdown
            v-model="data.OrganisationEditSearch"
            :options="data.selectOrganisationList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
            :showClear="true"
            :disabled="
              !(
                store.state.user.role == 1 ||
                store.state.user.id == data.document.creator_id
              )
            "
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-col-6">
          <label for="name">{{
            i18n.$t("Document").DocumentTable.DocumentTypeID.Show
          }}</label>
          <Dropdown
            v-model="data.DocumentTypeEditSearch"
            :options="data.selectDocumentTypeList"
            optionLabel="name"
            :filter="true"
            :placeholder="i18n.$t('Document').DocumentTable.DocumentTypeID.Show"
            :showClear="true"
            :disabled="
              !(
                store.state.user.role == 1 ||
                store.state.user.id == data.document.creator_id
              )
            "
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Document").DocumentTable.Content.Show
        }}</label>
        <Textarea
          id="name"
          v-model.trim="data.document.content"
          required="true"
          rows="15"
          cols="20"
          :disabled="
            !(
              store.state.user.role == 1 ||
              store.state.user.id == data.document.creator_id
            )
          "
        />
      </div>
      <Button
        v-if="
          store.state.user.role == 1 ||
          store.state.user.id == data.document.creator_id
        "
        :label="i18n.$t('Document').Save"
        icon="pi pi-check"
        class="p-button-success"
        @click="saveEditDocument"
      />
    </Sidebar>
    <!--PDF視窗 -->
    <Dialog
      v-model:visible="data.PDFShowDialog"
      :style="{ width: '95vw' }"
      :header="i18n.$t('Payment').Confirm.PDF_Title"
      :modal="true"
    >
      <div class="p-text-center">
        <VuePDF class="width:100%" :pdf="data.pdf.pdf" :page="data.pdf.page" />
      </div>
      <template #footer>
        <Paginator
          @page="onPDFPage($event)"
          :rows="1"
          :totalRecords="data.pdf.pages"
        ></Paginator>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.PDFShowDialog = false"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Document').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.document"
          >{{ i18n.$t("Document").Confirm.Content }}
          <b>{{ data.document.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Document').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Document').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteDocument"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Document').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Document").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Document').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Document').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedDocument"
        />
      </template>
    </Dialog>
  </div>
</template>

<style>
.p-datatable .p-datatable-thead > tr > th {
  border-width: 0 0 0px 0 !important;
}
.p-datatable-thead {
  position: sticky;
  z-index: 100;

  box-shadow: 0px 5px 6px rgba(0, 1, 1, 0.2), 0px 10px 6px rgba(0, 1, 1, 0.16) !important;
}
.p-disabled,
.p-component:disabled {
  opacity: 1 !important;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd) {
  background: #dfebf1 !important;
  font-weight: bold;
  color: black;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #b7d2df !important;
  color: black;
  font-weight: bold;
}
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
.bg-border-warning {
}
.bg-border-normal {
}
</style>
