import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Currency {
  getCurrencyInfo(id) {
    return axios.get(
      `/api/admin/currency/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getCurrencyList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/currency/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getCurrencySelectList(name) {
    return axios.get(
      `/api/admin/currency/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchCurrency(datas) {
    return axios.post(
      `/api/admin/batch/currency`,
      {
        datas: datas,
      },
      config
    );
  }
  addCurrency(name) {
    return axios.post(
      `/api/admin/currency`,
      {
        name: name,
      },
      config
    );
  }
  editCurrency(id, name) {
    return axios.put(
      `/api/admin/currency`,
      {
        id: id,
        name: name,
      },
      config
    );
  }
  deleteCurrency(id) {
    return axios.delete(
      `/api/admin/currency`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
