<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Calendar from "primevue/calendar";
import * as math from "mathjs";
import { dateFormate } from "../../tool/formate";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import SelfPayment from "../../api/salary/self/payment";
import SelfPaymentContent from "../../api/salary/self/payment_content";
import Company from "../../api/salary/self/company";
import Factory from "../../api/salary/self/factory";
import PaymentItem from "../../api/salary/self/payment_item";
import AccountItem from "../../api/salary/self/account_item";
import Department from "../../api/salary/self/department";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";
import { printHTML } from "../../tool/print";
import PrintApplication from "../../components/PrintApplication.vue";
import PrintPetty from "../../components/PrintPetty.vue";
import PrintPurchase from "../../components/PrintPurchase.vue";
import africa_logo from "../../assets/africa_logo.jpg";
import Currency from "../../api/salary/self/currency";
const Application = ref(null);
const Petty = ref(null);
const Purchase = ref(null);
let currencyAPI = new Currency();
let departmentAPI = new Department();
let account_itemAPI = new AccountItem();
let companyAPI = new Company();
let factoryAPI = new Factory();
let payment_itemAPI = new PaymentItem();
let userApi = new User();
let self_paymentAPI = new SelfPayment();
let self_payment_contentAPI = new SelfPaymentContent();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  editDialog: false,
  payment_contents: [],
  selectDepartmentList: [],
  DepartmentSearch: "",
  selectAccountItemList: [],
  AccountItemSearch: "",
  expandedRows: [],
  StatusSearch: "",
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("Payment").Status.NewPaymentAuditStatus,
    },
    {
      id: 2,
      name: i18n.$t("Payment").Status.RejectPaymentAuditStatus,
    },
    {
      id: 3,
      name: i18n.$t("Payment").Status.PassPaymentAuditStatus,
    },
    {
      id: 4,
      name: i18n.$t("Payment").Status.ReleasePaymentAuditStatus,
    },
    {
      id: 5,
      name: i18n.$t("Payment").Status.CanacelPaymentAuditStatus,
    },
  ],
  selectPaymentItemList: [],
  selectPaymentTypeList: [
    {
      id: 1,
      name: i18n.$t("Payment").PaymentType.Purchase,
    },
    {
      id: 2,
      name: i18n.$t("Payment").PaymentType.PettyCash,
    },
    {
      id: 4,
      name: i18n.$t("Payment").PaymentType.Application,
    },
  ],
  selectDeliveryMethodList: [
    {
      id: 1,
      name: i18n.$t("Payment").DeliveryMethod.Air,
    },
    {
      id: 2,
      name: i18n.$t("Payment").DeliveryMethod.Sea,
    },
    {
      id: 3,
      name: i18n.$t("Payment").DeliveryMethod.Land,
    },
  ],
  selectCompanyList: [],
  selectFactoryList: [],
  PaymentItemSearch: "",
  CompanySearch: "",
  FactorySearch: "",
  PaymentTypeSearch: "",
  DeliveryMethodSearch: "",
  paymentLoading: false,
  totalRecords: 0,
  passDialog: false,
  failDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  payments: { data: null },
  payment: null,
  status: 0,
  page: 1,
  page_num: 10,
  selectedPayments: null,
  paymentFilters: {
    id: { value: "", matchMode: "contains" },
    delivery_method: { value: "", matchMode: "contains" },
    payment_method: { value: "", matchMode: "contains" },
    company_id: { value: "", matchMode: "contains" },
    company_account_id: { value: "", matchMode: "contains" },
    factory_id: { value: "", matchMode: "contains" },
    status: { value: 3, matchMode: "contains" },
    payment_type: { value: "", matchMode: "contains" },
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
    apply_employer_id: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPaymentList();
    getCompanySelectList();
    getFactorySelectList();
    getPaymentItemSelectList();
    getCurrencySelectList();
    store.commit("set_logo", africa_logo);
  }
});
function getCurrencySelectList() {
  currencyAPI.getCurrencySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCurrencyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getPaymentItemSelectList() {
  payment_itemAPI.getPaymentItemSelectList().then((res) => {
    if (res.data.error_code == 0) {
      data.selectPaymentItemList = res.data.data.datas;
      //   console.log(data.selectPaymentItemList);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCompanySelectList() {
  companyAPI.getCompanySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCompanyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
async function PrintPayment(payment, print_application) {
  payment.department = [];
  data.payment = payment;
  data.tmp_print_application = print_application;
  await getPaymentContentList(payment.id, true);
  // setTimeout(async () => {
  //   for (let i = 0; i < data.payment_contents.length; i++) {
  //     if (
  //       payment.department.indexOf(data.payment_contents[i].department_name) ==
  //       -1
  //     ) {
  //       await payment.department.push(data.payment_contents[i].department_name);
  //     }
  //   }

  //   await store.commit("set_payment", payment);
  //   await store.commit("set_payment_content", data.payment_contents);
  //   printHTML(print_application);
  // }, 300);
}
// let admin_payment_contentAPI = new AdminPaymentContent();
// let manager_payment_contentAPI = new ManagerPaymentContent();
function getPaymentList() {
  CheckUser();
  self_paymentAPI
    .getPaymentList(
      data.paymentFilters.id.value,
      data.paymentFilters.delivery_method.value,
      data.paymentFilters.payment_method.value,
      data.paymentFilters.company_id.value,
      data.paymentFilters.company_account_id.value,
      data.paymentFilters.factory_id.value,
      data.paymentFilters.status.value,
      data.paymentFilters.payment_type.value,
      data.paymentFilters.created_at_begin.value,
      data.paymentFilters.created_at_end.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.payments.data = res.data.data.datas;
        data.page = res.data.data.page;

        data.paymentLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.paymentLoading = false;
      }
    });
}

// function addPayment() {
//   CheckUser();
//   data.addDialog = true;
// }

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
  data.print_payment_check = true;
}
function confirmDelete(payment) {
  data.payment = payment;
  data.deleteDialog = true;
}
function deletePayment() {
  admin_paymentAPI.deletePayment([data.payment.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Payment").DeleteSuccess,
        life: 3000,
      });

      data.paymentLoading = true;
      getPaymentList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedPayment() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedPayments.length; i++) {
    selectDelete.push(data.selectedPayments[i].id);
  }
  admin_paymentAPI.deletePayment(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Payment").DeleteSuccess,
        life: 3000,
      });

      data.paymentLoading = true;
      data.selectedPayments = [];
      getPaymentList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function passSelectedPayment() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedPayments.length; i++) {
    if (data.selectedPayments[i].payment_type_id == 2) {
      selectDelete.push(data.selectedPayments[i].id);
    }
  }
  self_paymentAPI.editPaymentBatchStatus(selectDelete, 4).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Payment").Success,
        life: 3000,
      });
      data.paymentLoading = true;
      getPaymentList();
      data.deleteSelectedDialog = false;
      data.selectedPayments = [];
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

async function printSelectedPayment(print_application) {
  let selectDelete = [];
  let select_data = [];
  for (let i = 0; i < data.selectedPayments.length; i++) {
    if (data.selectedPayments[i].payment_type_id == 2) {
      await selectDelete.push(data.selectedPayments[i].id);
      await select_data.push(data.selectedPayments[i]);
    }
  }
  let payment = {
    company_name: select_data[0].company_name,
    id: select_data[0].id,
    apply_employer_name: select_data[0].apply_employer_name,
    created_at: select_data[0].created_at,
    total_dollar: select_data[0].total_dollar,
    factory_name: select_data[0].factory_name,
    description: select_data[0].description,
    remark: select_data[0].select_data,
    payment_method: select_data[0].payment_method,
  };
  for (let i = 0; i < select_data.length; i++) {
    if (i != 0)
      payment.total_dollar = math.add(
        math.bignumber(payment.total_dollar),
        math.bignumber(select_data[i].total_dollar)
      );
  }

  if (select_data.length != 0) {
    payment.department = [];
    payment.description = "零用金撥補Petty Cash";
    data.payment = payment;
    data.tmp_print_application = print_application;
    await getPaymentContentListByIDs(selectDelete, true);

    // setTimeout(async () => {
    //   for (let i = 0; i < data.payment_contents.length; i++) {
    //     if (
    //       payment.department.indexOf(
    //         data.payment_contents[i].department_name
    //       ) == -1
    //     ) {
    //       await payment.department.push(
    //         data.payment_contents[i].department_name
    //       );
    //     }
    //   }

    //   await store.commit("set_payment", payment);
    //   await store.commit("set_payment_content", data.payment_contents);
    //   printHTML(print_application);
    //   data.print_payment_check = false;
    // }, 500);
  }
}

function getPaymentContentListByIDs(id, check) {
  self_payment_contentAPI
    .getPaymentContentListByIDs(0, id, 0, "", 1, 999)
    .then((res) => {
      if (res.data.error_code == 0) {
        if (check) {
          data.payment_contents = res.data.data.datas;
        } else {
          for (let i = 0; i < data.payments.data.length; i++) {
            if (data.payments.data[i].id == id) {
              data.payments.data[i].contents = res.data.data.datas;
              data.payment.contents = res.data.data.datas;
              if (data.invoiceDialog == true) {
                for (let j = 0; j < data.payment.contents.length; j++) {
                  data.payment.contents[j].account_item_id = {
                    account_type_id: 0,
                    account_type_name: "",
                    company_id: 0,
                    company_name: "",
                    description: "",
                    id: data.payment.contents[j].account_item_id,
                    name: data.payment.contents[j].account_item_name,
                  };
                  data.payment.contents[j].department_id = {
                    description:
                      data.payment.contents[j].department_description,
                    id: data.payment.contents[j].department_id,
                    name: data.payment.contents[j].department_name,
                  };
                }
              }
            }
          }
        }
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    })
    .then(async () => {
      if (check) {
        for (let i = 0; i < data.payment_contents.length; i++) {
          if (
            data.payment.department.indexOf(
              data.payment_contents[i].department_name
            ) == -1
          ) {
            await data.payment.department.push(
              data.payment_contents[i].department_name
            );
          }
        }
      }
      await store.commit("set_payment", data.payment);
      await store.commit("set_payment_content", data.payment_contents);
    })
    .then(() => {
      if (check) {
        printHTML(data.tmp_print_application);
        data.print_payment_check = false;
      }
    });
}

function onPage(event) {
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.paymentFilters.id.value,
  () => {
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.CompanySearch,
  () => {
    data.paymentFilters.company_id.value =
      data.CompanySearch == null ? "" : data.CompanySearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.paymentFilters.status.value =
      data.StatusSearch == null ? "" : data.StatusSearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.FactorySearch,
  () => {
    data.paymentFilters.factory_id.value =
      data.FactorySearch == null ? "" : data.FactorySearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.PaymentTypeSearch,
  () => {
    data.paymentFilters.payment_type.value =
      data.PaymentTypeSearch == null ? "" : data.PaymentTypeSearch.id;
    data.paymentLoading = true;
    getPaymentList();
  }
);
watch(
  () => data.DeliveryMethodSearch,
  () => {
    data.paymentFilters.delivery_method.value =
      data.DeliveryMethodSearch == null ? "" : data.DeliveryMethodSearch.name;
    data.paymentLoading = true;
    getPaymentList();
  }
);
function onRowExpand(e) {
  //   console.log(e.data.id);
  getPaymentContentList(e.data.id, false);
}
function getPaymentContentList(id, check) {
  self_payment_contentAPI
    .getPaymentContentList(0, id, 0, "", 1, 999)
    .then((res) => {
      if (res.data.error_code == 0) {
        if (check) {
          data.payment_contents = res.data.data.datas;
        } else {
          for (let i = 0; i < data.payments.data.length; i++) {
            if (data.payments.data[i].id == id) {
              data.payments.data[i].contents = res.data.data.datas;
              data.payment.contents = res.data.data.datas;
              if (data.invoiceDialog == true) {
                for (let j = 0; j < data.payment.contents.length; j++) {
                  data.payment.contents[j].account_item_id = {
                    account_type_id: 0,
                    account_type_name: "",
                    company_id: 0,
                    company_name: "",
                    description: "",
                    id: data.payment.contents[j].account_item_id,
                    name: data.payment.contents[j].account_item_name,
                  };
                  data.payment.contents[j].department_id = {
                    description:
                      data.payment.contents[j].department_description,
                    id: data.payment.contents[j].department_id,
                    name: data.payment.contents[j].department_name,
                  };
                }
              }
            }
          }
        }
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    })
    .then(async () => {
      if (check) {
        for (let i = 0; i < data.payment_contents.length; i++) {
          if (
            data.payment.department.indexOf(
              data.payment_contents[i].department_name
            ) == -1
          ) {
            await data.payment.department.push(
              data.payment_contents[i].department_name
            );
          }
        }
      }
      await store.commit("set_payment", data.payment);
      await store.commit("set_payment_content", data.payment_contents);
    })
    .then(() => {
      if (check) {
        printHTML(data.tmp_print_application);
      }
    });
}
function editStatus(payment, status) {
  data.payment = payment;
  data.status = status;
  data.passDialog = true;
}
function SaveEditStatus() {
  self_paymentAPI
    .editPaymentStatus(data.payment.id, data.status)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Payment").Success,
          life: 3000,
        });
        data.paymentLoading = true;
        getPaymentList();
        data.passDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
async function editInvoice(item) {
  data.payment = item;
  await getAccountItemSelectList();
  await getPaymentContentList(item.id, false);
  await getDepartmentSelectList();
  data.invoiceDialog = true;
}

function getDepartmentSelectList() {
  departmentAPI.getDepartmentSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectDepartmentList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getAccountItemSelectList() {
  account_itemAPI
    .getAccountItemSelectList("", data.payment.company_id)
    .then((res) => {
      if (res.data.error_code == 0) {
        data.selectAccountItemList = res.data.data.datas;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function SaveEditInvoice(item) {
  self_payment_contentAPI
    .editPaymentContentInvoice(
      item.id,
      String(item.dollar),
      String(item.total_count),
      item.invoice_number,
      item.account_item_id.id,
      item.spec,
      item.department_id.id,
      String(item.vat)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Payment").Success,
          life: 3000,
        });
        data.paymentLoading = true;
        getPaymentList();
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function editSellment(item) {
  data.payment = item;
  data.editDialog = true;
  for (let i = 0; i < data.selectFactoryList.length; i++) {
    if (data.payment.factory_id == data.selectFactoryList[i].id) {
      data.FactoryEditSearch = data.selectFactoryList[i];
    }
  }
  for (let i = 0; i < data.selectCurrencyList.length; i++) {
    if (data.payment.currency_id == data.selectCurrencyList[i].id) {
      data.CurrencySearch = data.selectCurrencyList[i];
    }
  }
}

function SaveEditSellment() {
  self_paymentAPI
    .editPaymentRemark(
      data.payment.id,
      data.payment.remark,
      data.payment.description,
      data.FactoryEditSearch != null && data.FactoryEditSearch != ""
        ? data.FactoryEditSearch.id
        : data.payment.factory_id,
      data.payment.custom_order_number,
      data.payment.settlement_of_month != null &&
        data.payment.settlement_of_month != ""
        ? new Date(data.payment.settlement_of_month).toISOString()
        : "",
      data.payment.currency_id,
      data.payment.pay_date != null && data.payment.pay_date != ""
        ? new Date(data.payment.pay_date).toISOString()
        : "",
      String(data.payment.taxed_rate)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Payment").Success,
          life: 3000,
        });
        data.paymentLoading = true;
        getPaymentList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
watch(
  () => data.CurrencySearch,
  () => {
    data.payment.currency_id =
      data.CurrencySearch == null ? "" : data.CurrencySearch.id;
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.payments.data"
      :loading="data.paymentLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.paymentFilters"
      v-model:selection="data.selectedPayments"
      :row-hover="true"
      v-model:expandedRows="data.expandedRows"
      @rowExpand="onRowExpand"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Payment").PaymentTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.paymentFilters[
                        i18n.$t('Payment').PaymentTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Payment').PaymentTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.CompanySearch"
                    :options="data.selectCompanyList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.PaymentTypeSearch"
                    :options="data.selectPaymentTypeList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Payment').PaymentTable.PaymentType.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
              <p style="text-align: left" class="p-mb-2">
                <span
                  v-if="
                    store.state.user.id != 192 &&
                    store.state.user.id != 194 &&
                    store.state.user.id != 964 &&
                    store.state.user.id != 965 &&
                    store.state.user.id != 966
                  "
                  class="p-input-icon-left p-mr-1"
                >
                  <Dropdown
                    v-model="data.FactorySearch"
                    :options="data.selectFactoryList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Factory.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <Dropdown
                    v-model="data.DeliveryMethodSearch"
                    :options="data.selectDeliveryMethodList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Payment').PaymentTable.DeliveryMethod.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-mr-1 p-d-none">
                  <Dropdown
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="i18n.$t('Payment').PaymentTable.Status.Show"
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>
          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Payment').PaymentTable.Pass"
              icon="pi pi-clone"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedPayments || !data.selectedPayments.length
              "
            />
          </div>
        </div>
      </template>
      <Column :expander="true" headerStyle="width: 1rem" />
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.ID.Field"
        :header="i18n.$t('Payment').PaymentTable.ID.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.CustomOrderNumber.Field"
        :header="i18n.$t('Payment').PaymentTable.CustomOrderNumber.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.CompanyName.Field"
        :header="i18n.$t('Payment').PaymentTable.CompanyName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.PaymentTypeName.Field"
        :header="i18n.$t('Payment').PaymentTable.PaymentTypeName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.FactoryName.Field"
        :header="i18n.$t('Payment').PaymentTable.FactoryName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.DeliveryMethod.Field"
        :header="i18n.$t('Payment').PaymentTable.DeliveryMethod.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.Remark.Field"
        :header="i18n.$t('Payment').PaymentTable.Remark.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.Description.Field"
        :header="i18n.$t('Payment').PaymentTable.Description.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.PaymentMethod.Field"
        :header="i18n.$t('Payment').PaymentTable.PaymentMethod.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalCount.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalCount.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalDollar.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalDollar.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.TotalTaxed.Field"
        :header="i18n.$t('Payment').PaymentTable.TotalTaxed.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Payment').PaymentTable.CurrencyName.Field"
        :header="i18n.$t('Payment').PaymentTable.CurrencyName.Show"
      ></Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Payment').PaymentTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("Payment").Status.NewPaymentAuditStatus
              : slotProps.data.status == 2
              ? i18n.$t("Payment").Status.RejectPaymentAuditStatus
              : slotProps.data.status == 3
              ? i18n.$t("Payment").Status.PassPaymentAuditStatus
              : slotProps.data.status == 4
              ? i18n.$t("Payment").Status.ReleasePaymentAuditStatus
              : slotProps.data.status == 5
              ? i18n.$t("Payment").Status.CanacelPaymentAuditStatus
              : "UnKnow"
          }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('Payment').PaymentTable.PayDate.Show"
      >
        <template #body="slotProps"
          >{{ dateFormate(slotProps.data.pay_date, "YYYY-MM-DD") }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('Payment').PaymentTable.SettlementOfMonth.Show"
      >
        <template #body="slotProps"
          >{{ dateFormate(slotProps.data.settlement_of_month, "YYYY-MM") }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
        :header="i18n.$t('Payment').PaymentTable.CreatedAt.Show"
      >
        <template #body="slotProps"
          >{{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        bodyStyle="white-space: nowrap; width: 150px; overflow: scroll"
      >
        <template #body="slotProps">
          <Button
            v-if="slotProps.data.status == 3"
            icon="pi pi-check"
            class="p-button-rounded p-button-info p-mr-2 p-button-sm"
            @click="editStatus(slotProps.data, 4)"
          />
          <Button
            v-if="slotProps.data.status == 3"
            icon="pi pi-book"
            class="p-button-rounded p-button-help p-mr-2 p-button-sm"
            @click="editInvoice(slotProps.data)"
          />
          <Button
            icon="pi pi-print"
            class="p-button-rounded p-button-danger p-mr-2 p-button-sm"
            @click="PrintPayment(slotProps.data, Application)"
          />
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-info p-mr-2 p-button-sm"
            @click="editSellment(slotProps.data)"
          />
          <!-- <Button
            v-if="store.state.user.role == 1"
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          /> -->
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="orders-subtable" style="width: 100%">
          <div>
            <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.PaymentItem.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.DepartmentId.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.AccountItemId.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Spec.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.TotalCount.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Dollar.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Vat.Show }}
              </div>
              <div class="p-col-1">
                {{
                  i18n.$t("Payment").PaymentContentTable.CalculationUnit.Show
                }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.InvoiceNumber.Show }}
              </div>
            </div>
          </div>
          <div
            style="
              height: calc(100vh - 470px);
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
            <div
              :key="index"
              v-for="(item, index) in slotProps.data.contents"
              class="p-grid p-mt-1"
              style="border-bottom: 1px solid"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{
                  item.payment_item_chinese_name +
                  "(" +
                  item.payment_item_english_name +
                  ")"
                }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.department_name }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.account_item_name }}
                ({{
                  item.payment_item_taxed == 1
                    ? i18n.$t("PaymentItem").Taxed.IsTaxed
                    : item.payment_item_taxed == 2
                    ? i18n.$t("PaymentItem").Taxed.NotTaxed
                    : i18n.$t("PaymentItem").Taxed.Unknow
                }})
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.spec }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.total_count }}
                <!-- <InputNumber
              v-model="item.total_count"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.dollar }}
                <!-- <InputNumber
              v-model="item.dollar"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.vat }}
                <!-- <InputNumber
              v-model="item.dollar"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="5"
              required="true"
            /> -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.calculation_unit }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{ item.invoice_number }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
    <!-- 修改視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Payment').Edit.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Payment").PaymentTable.CurrencyName.Show
        }}</label>
        <Dropdown
          v-model="data.CurrencySearch"
          :options="data.selectCurrencyList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Payment').PaymentTable.CurrencyName.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Payment").PaymentTable.PayDate.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.payment.pay_date"
          dateFormat="yy/mm/dd"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Payment').PaymentTable.PayDate.Show"
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Payment").PaymentTable.Factory.Show
        }}</label>
        <Dropdown
          v-model="data.FactoryEditSearch"
          :options="data.selectFactoryList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Payment').PaymentTable.Factory.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Payment").PaymentTable.Description.Show
        }}</label>
        <Textarea
          id="description"
          v-model="data.payment.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Payment").PaymentTable.Remark.Show
        }}</label>
        <Textarea
          id="remark"
          v-model="data.payment.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditSellment"
        />
      </template>
    </Dialog>
    <!-- 通過視窗 -->
    <Dialog
      v-model:visible="data.passDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Payment').AuditPayment.Pass"
      :modal="true"
      class="p-fluid"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment"
          >{{ i18n.$t("Payment").Confirm.CotentApplication }}
          <b>{{ data.payment.id }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.passDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditStatus"
        />
      </template>
    </Dialog>
    <!-- 發票視窗 -->
    <Dialog
      v-model:visible="data.invoiceDialog"
      :style="{ width: '80%', minWidth: '350px' }"
      :header="i18n.$t('Payment').AuditPayment.Invoice"
      :modal="true"
      class="p-fluid"
    >
      <div class="confirmation-content">
        <div class="orders-subtable" style="width: 100%">
          <div>
            <div class="p-grid p-mt-1" style="border-bottom: 1px solid">
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.PaymentItem.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.DepartmentId.Show }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.AccountItemId.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Spec.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.TotalCount.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Dollar.Show }}
              </div>
              <div class="p-col-1">
                {{ i18n.$t("Payment").PaymentContentTable.Vat.Show }}
              </div>
              <div class="p-col-1">
                {{
                  i18n.$t("Payment").PaymentContentTable.CalculationUnit.Show
                }}
              </div>
              <div class="p-col-2">
                {{ i18n.$t("Payment").PaymentContentTable.InvoiceNumber.Show }}
              </div>
            </div>
          </div>
          <div
            style="
              height: calc(100vh - 470px);
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
            <div
              :key="index"
              v-for="(item, index) in data.payment.contents"
              class="p-grid p-mt-1"
              style="border-bottom: 1px solid"
            >
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                {{
                  item.payment_item_chinese_name +
                  "(" +
                  item.payment_item_english_name +
                  ")"
                }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                <Dropdown
                  v-model="item.department_id"
                  :options="data.selectDepartmentList"
                  :filter="true"
                  optionLabel="name"
                  :placeholder="
                    i18n.$t('Payment').PaymentContentTable.DepartmentId.Show
                  "
                  style="width: 100%"
                  :showClear="true"
                >
                  <template #value="slotProps">
                    <div
                      class="country-item country-item-value"
                      v-if="slotProps.value"
                    >
                      <div>
                        {{ slotProps.value.name }}
                        <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                      </div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <div>
                        {{ slotProps.option.name }}
                        <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                      </div>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                <Dropdown
                  v-model="item.account_item_id"
                  :options="data.selectAccountItemList"
                  :filter="true"
                  optionLabel="name"
                  :placeholder="
                    i18n.$t('Payment').PaymentContentTable.AccountItemId.Show
                  "
                  style="width: 100%"
                  :showClear="true"
                >
                  <template #value="slotProps">
                    <div
                      class="country-item country-item-value"
                      v-if="slotProps.value"
                    >
                      <div>
                        {{ slotProps.value.name }}
                        <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                      </div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <div>
                        {{ slotProps.option.name }}
                        <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                      </div>
                    </div>
                  </template>
                </Dropdown>
                <!-- {{ item.account_item_name }} -->
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                <InputText v-model="item.spec" required="true" />
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                <!-- {{ item.total_count }} -->
                <InputNumber
                  v-model="item.total_count"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="5"
                  required="true"
                />
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                <!-- {{ item.dollar }} -->
                <InputNumber
                  v-model="item.dollar"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="5"
                  required="true"
                />
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                <!-- {{ item.dollar }} -->
                <InputNumber
                  v-model="item.vat"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="5"
                  required="true"
                />
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-1"
              >
                {{ item.calculation_unit }}
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: auto;
                  justify-content: start;
                "
                class="p-col-2"
              >
                <InputText
                  style="width: 90%"
                  v-model="item.invoice_number"
                  required="true"
                  class="p-mr-1"
                />
                <Button
                  icon="pi pi-check"
                  class="p-button-rounded p-button-info p-button-sm"
                  @click="SaveEditInvoice(item)"
                />
                <!-- {{ item.invoice_number }} -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.invoiceDialog = false"
        />
        <!-- <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="SaveEditStatus"
        /> -->
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Payment').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.payment"
          >{{ i18n.$t("Payment").Confirm.Content }}
          <b>{{ data.payment.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePayment"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Payment').Confirm.PassTitle"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Payment").Confirm.PassContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Payment').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Print"
          icon="pi pi-print"
          class="p-button-text"
          @click="printSelectedPayment(Application)"
        />
        <Button
          :label="i18n.$t('Payment').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          :disabled="data.print_payment_check"
          @click="passSelectedPayment"
        />
      </template>
    </Dialog>
  </div>
  <div class="p-d-none">
    <div ref="Application">
      <PrintApplication />
    </div>
  </div>
  <div class="p-d-none">
    <div ref="Petty">
      <PrintPetty />
    </div>
  </div>
  <div class="p-d-none">
    <div ref="Purchase">
      <PrintPurchase />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
