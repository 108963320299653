<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import CompanyAccount from "../../api/salary/admin/company_account";
import Company from "../../api/salary/admin/company";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let company_accountAPI = new CompanyAccount();
let companyAPI = new Company();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  CompanyAddSearch: "",
  CompanyEditSearch: "",
  selectCompanyList: [],
  CompanySearch: "",
  add_company_account: {
    id: "",
    number: "",
    dollar: 0,
    name: "",
    description: "",
  },
  company_accountLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  company_accounts: { data: null },
  company_account: null,
  page: 1,
  page_num: 10,
  selectedCompanyAccounts: null,
  company_accountFilters: {
    id: { value: "", matchMode: "contains" },
    company_id: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getCompanyAccountList();
    getCompanySelectList();
  }
});
function getCompanySelectList() {
  companyAPI.getCompanySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCompanyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCompanyAccountList() {
  CheckUser();
  company_accountAPI
    .getCompanyAccountList(
      data.company_accountFilters.id.value,
      data.company_accountFilters.company_id.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.company_accounts.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.company_accountLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.company_accountLoading = false;
      }
    });
}

function saveEditCompanyAccount() {
  company_accountAPI
    .editCompanyAccount(
      data.company_account.id,
      data.company_account.company_id,
      data.company_account.number,
      String(data.company_account.dollar)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("CompanyAccount").EditSuccess,
          life: 3000,
        });

        data.company_accountLoading = true;
        getCompanyAccountList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddCompanyAccount() {
  company_accountAPI
    .addCompanyAccount(
      data.add_company_account.company_id,
      data.add_company_account.number,
      String(data.add_company_account.dollar)
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("CompanyAccount").AddSuccess,
          life: 3000,
        });

        data.company_accountLoading = true;
        getCompanyAccountList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addCompanyAccount() {
  data.add_company_account = {
    id: "",
    number: "",
    dollar: 0,
    name: "",
    description: "",
  };
  data.addDialog = true;
  data.CompanyAddSearch = "";
}
function editCompanyAccount(company_account) {
  for (let i = 0; i < data.selectCompanyList.length; i++) {
    if (company_account.company_id == data.selectCompanyList[i].id) {
      data.CompanyEditSearch = data.selectCompanyList[i];
    }
  }
  data.company_account = { ...company_account };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(company_account) {
  data.company_account = company_account;
  data.deleteDialog = true;
}
function deleteCompanyAccount() {
  company_accountAPI
    .deleteCompanyAccount([data.company_account.id])
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("CompanyAccount").DeleteSuccess,
          life: 3000,
        });

        data.company_accountLoading = true;
        getCompanyAccountList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedCompanyAccount() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedCompanyAccounts.length; i++) {
    selectDelete.push(data.selectedCompanyAccounts[i].id);
  }
  company_accountAPI.deleteCompanyAccount(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("CompanyAccount").DeleteSuccess,
        life: 3000,
      });

      data.company_accountLoading = true;
      data.selectedCompanyAccounts = [];
      getCompanyAccountList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.company_accountLoading = true;
    getCompanyAccountList();
  }
);
watch(
  () => data.CompanySearch,
  () => {
    data.company_accountFilters.company_id.value =
      data.CompanySearch == null ? "" : data.CompanySearch.id;
    data.company_accountLoading = true;
    getCompanyAccountList();
  }
);
watch(
  () => data.CompanyAddSearch,
  () => {
    data.add_company_account.company_id =
      data.CompanyAddSearch == null ? "" : data.CompanyAddSearch.id;
  }
);
watch(
  () => data.CompanyEditSearch,
  () => {
    data.company_account.company_id =
      data.CompanyEditSearch == null ? "" : data.CompanyEditSearch.id;
  }
);
watch(
  () => data.company_accountFilters.id.value,
  () => {
    data.company_accountLoading = true;
    getCompanyAccountList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.company_accounts.data"
      :loading="data.company_accountLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.company_accountFilters"
      v-model:selection="data.selectedCompanyAccounts"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("CompanyAccount").CompanyAccountTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.company_accountFilters[
                        i18n.$t('CompanyAccount').CompanyAccountTable.ID.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('CompanyAccount').CompanyAccountTable.ID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.CompanySearch"
                    :options="data.selectCompanyList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('CompanyAccount').CompanyAccountTable.Name.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('CompanyAccount').CompanyAccountTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addCompanyAccount"
            />
            <Button
              :label="i18n.$t('CompanyAccount').CompanyAccountTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedCompanyAccounts ||
                !data.selectedCompanyAccounts.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('CompanyAccount').CompanyAccountTable.ID.Field"
        :header="i18n.$t('CompanyAccount').CompanyAccountTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('CompanyAccount').CompanyAccountTable.Name.Field"
        :header="i18n.$t('CompanyAccount').CompanyAccountTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('CompanyAccount').CompanyAccountTable.Number.Field"
        :header="i18n.$t('CompanyAccount').CompanyAccountTable.Number.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('CompanyAccount').CompanyAccountTable.Dollar.Field"
        :header="i18n.$t('CompanyAccount').CompanyAccountTable.Dollar.Show"
      ></Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editCompanyAccount(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('CompanyAccount').AddCompanyAccount.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CompanyAccount").CompanyAccountTable.Name.Show
        }}</label>
        <Dropdown
          v-model="data.CompanyAddSearch"
          :options="data.selectCompanyList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('CompanyAccount').CompanyAccountTable.Name.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CompanyAccount").CompanyAccountTable.Number.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_company_account.number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CompanyAccount").CompanyAccountTable.Dollar.Show
        }}</label>
        <InputNumber
          id="name"
          mode="decimal"
          :minFractionDigits="2"
          v-model="data.add_company_account.dollar"
          required="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('CompanyAccount').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('CompanyAccount').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddCompanyAccount"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('CompanyAccount').EditCompanyAccount.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CompanyAccount").CompanyAccountTable.Name.Show
        }}</label>
        <Dropdown
          v-model="data.CompanyEditSearch"
          :options="data.selectCompanyList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('CompanyAccount').CompanyAccountTable.Name.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CompanyAccount").CompanyAccountTable.Number.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.company_account.number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("CompanyAccount").CompanyAccountTable.Dollar.Show
        }}</label>
        <InputNumber
          id="name"
          mode="decimal"
          :minFractionDigits="2"
          v-model="data.company_account.dollar"
          required="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('CompanyAccount').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('CompanyAccount').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditCompanyAccount"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('CompanyAccount').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.company_account"
          >{{ i18n.$t("CompanyAccount").Confirm.Content }}
          <b>{{ data.company_account.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('CompanyAccount').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('CompanyAccount').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteCompanyAccount"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('CompanyAccount').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("CompanyAccount").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('CompanyAccount').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('CompanyAccount').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedCompanyAccount"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
