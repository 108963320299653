<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Position from "../../api/salary/admin/position";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let positionAPI = new Position();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  add_position: {
    id: "",
    name: "",
    description: "",
  },
  positionLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  positions: { data: null },
  position: null,
  page: 1,
  page_num: 10,
  selectedPositions: null,
  positionFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getPositionList();
  }
});

function getPositionList() {
  CheckUser();
  positionAPI
    .getPositionList(
      data.positionFilters.id.value,
      data.positionFilters.name.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.positions.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.positionLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.positionLoading = false;
      }
    });
}

function saveEditPosition() {
  positionAPI
    .editPosition(
      data.position.id,
      data.position.name,
      data.position.description
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Position").EditSuccess,
          life: 3000,
        });

        data.positionLoading = true;
        getPositionList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddPosition() {
  positionAPI
    .addPosition(data.add_position.name, data.add_position.description)
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Position").AddSuccess,
          life: 3000,
        });

        data.positionLoading = true;
        getPositionList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addPosition() {
  data.addDialog = true;
}
function editPosition(position) {
  data.position = { ...position };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(position) {
  data.position = position;
  data.deleteDialog = true;
}
function deletePosition() {
  positionAPI.deletePosition([data.position.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Position").DeleteSuccess,
        life: 3000,
      });

      data.positionLoading = true;
      getPositionList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedPosition() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedPositions.length; i++) {
    selectDelete.push(data.selectedPositions[i].id);
  }
  positionAPI.deletePosition(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Position").DeleteSuccess,
        life: 3000,
      });

      data.positionLoading = true;
      data.selectedPositions = [];
      getPositionList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.positionLoading = true;
    getPositionList();
  }
);
watch(
  () => data.positionFilters.name.value,
  () => {
    data.positionLoading = true;
    getPositionList();
  }
);
watch(
  () => data.positionFilters.id.value,
  () => {
    data.positionLoading = true;
    getPositionList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.positions.data"
      :loading="data.positionLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.positionFilters"
      v-model:selection="data.selectedpositions"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Position").PositionTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.positionFilters[
                        i18n.$t('Position').PositionTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Position').PositionTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.positionFilters[
                        i18n.$t('Position').PositionTable.Name.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Position').PositionTable.Name.Show"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Position').PositionTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addPosition"
            />
            <Button
              :label="i18n.$t('Position').PositionTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedPositions || !data.selectedPositions.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Position').PositionTable.ID.Field"
        :header="i18n.$t('Position').PositionTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Position').PositionTable.Name.Field"
        :header="i18n.$t('Position').PositionTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Position').PositionTable.Description.Field"
        :header="i18n.$t('Position').PositionTable.Description.Show"
      ></Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editPosition(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Position').AddPosition.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Position").AddPosition.Name }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_position.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Position").AddPosition.Description
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_position.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Position').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Position').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddPosition"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Position').EditPosition.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Position").EditPosition.Name }}</label>
        <InputText
          id="name"
          v-model.trim="data.position.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Position").EditPosition.Description
        }}</label>
        <Textarea
          id="description"
          v-model="data.position.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Position').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Position').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditPosition"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Position').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.position"
          >{{ i18n.$t("Position").Confirm.Content }}
          <b>{{ data.position.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Position').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Position').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deletePosition"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Position').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Position").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Position').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Position').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedPosition"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
