<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Payment from "../../api/salary/admin/payment";
import ManagerPayment from "../../api/salary/manager/payment";
import Company from "../../api/salary/self/company";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import Department from "../../api/salary/self/department";
import Factory from "../../api/salary/self/factory";
import PaymentItem from "../../api/salary/self/payment_item";

let companyAPI = new Company();
let userApi = new User();
let paymentAPI = new Payment();
let manager_paymentAPI = new ManagerPayment();

let factoryAPI = new Factory();
let departmentAPI = new Department();
let payment_itemAPI = new PaymentItem();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectDepartmentList: [],
  DepartmentSearch: "",
  selectFactoryList: [],
  FactorySearch: "",
  selectPaymentItemList: [],
  PaymentItemSearch: "",
  chart: false,
  contestData: {
    BarChart: {},
    BarChartContent: {},
    PieChart: {},
    PieChartContent: {},
  },
  selectCompanyList: [],
  CompanySearch: "",
  paymentLoading: false,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  payments: { data: null },
  paymentFilters: {
    company_id: { value: "", matchMode: "contains" },
    department_id: { value: "", matchMode: "contains" },
    factory_id: { value: "", matchMode: "contains" },
    payment_item_id: { value: "", matchMode: "contains" },
    settlement_of_month_begin: { value: "", matchMode: "contains" },
    settlement_of_month_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  if (store.state.user == null) CheckUser();
});
const mounted = onMounted(async () => {
  data.paymentLoading = true;
  if (store.state.user != null) {
    getPaymentReport();
    getCompanySelectList();
    getFactorySelectList();
    getPaymentItemSelectList();
    getDepartmentSelectList();
  }
});

function getDepartmentSelectList() {
  departmentAPI.getDepartmentSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectDepartmentList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getPaymentItemSelectList() {
  payment_itemAPI.getPaymentItemSelectList().then((res) => {
    if (res.data.error_code == 0) {
      data.selectPaymentItemList = res.data.data.datas;
      //   console.log(data.selectPaymentItemList);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getFactorySelectList() {
  factoryAPI.getFactorySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectFactoryList = res.data.data.datas;
      if (store.state.user.role_addition_binding == 103) {
        data.selectFactoryList = data.selectFactoryList.filter(
          (word) => word.id != 12
        );
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getCompanySelectList() {
  companyAPI.getCompanySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCompanyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function addIfNotExists(array, newString) {
  if (!array.includes(newString)) {
    array.push(newString);
  }
}

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getPaymentReport() {
  if (store.state.user == null) CheckUser();
  data.chart = false;
  switch (store.state.user.role) {
    case 1:
      paymentAPI
        .getPaymentItemDetail(
          data.paymentFilters.company_id.value,
          data.paymentFilters.department_id.value,
          data.paymentFilters.factory_id.value,
          data.paymentFilters.payment_item_id.value,
          data.paymentFilters.settlement_of_month_begin.value,
          data.paymentFilters.settlement_of_month_end.value
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            //   account_types

            data.payments.data = res.data.data.datas;
            data.paymentLoading = false;
            data.chart = true;
            let bar_label = [];
            let bar_dollar_total = [];
            let bar_dollar_count = [];

            for (let i = 0; i < res.data.data.datas.length; i++) {
              addIfNotExists(
                bar_label,
                res.data.data.datas[i].settlement_of_month.split("T")[0]
              );
              if (
                bar_label[bar_label.length - 1] ==
                res.data.data.datas[i].settlement_of_month.split("T")[0]
              ) {
                let index = bar_dollar_total.findIndex(
                  (item) =>
                    item.label.split("|")[0] ===
                    String(res.data.data.datas[i].payment_item_id)
                );
                if (index != -1) {
                  bar_dollar_total[index].data.push(
                    res.data.data.datas[i].total_dollar
                  );
                  bar_dollar_count[index].data.push(
                    res.data.data.datas[i].total_count
                  );
                } else {
                  let tmp_name =
                    store.state.lang == "cn"
                      ? res.data.data.datas[i].payment_item_chinese_name
                      : res.data.data.datas[i].payment_item_english_name;
                  bar_dollar_total.push({
                    label:
                      res.data.data.datas[i].payment_item_id + "|" + tmp_name,
                    backgroundColor: ["#1abc9c"],
                    data: [res.data.data.datas[i].total_dollar],
                  });
                  bar_dollar_count.push({
                    label:
                      res.data.data.datas[i].payment_item_id + "|" + tmp_name,
                    backgroundColor: ["#F50002"],
                    data: [res.data.data.datas[i].total_count],
                  });
                }
              }
              //   bar_label.push(res.data.data.datas[i].account_type_name);
              //   bar_dollar_total.push(res.data.data.datas[i].total_dollar);
            }

            data.contestData.BarChartContent = {
              id: "bar",
              type: "bar",
              options: {
                min: 0,
                max: 100,
                responsive: true,
                plugins: {
                  legend: {
                    labels: {
                      color: "balck",
                    },
                    position: "top",
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: "balck",
                    },
                  },
                  y: {
                    min: 0,

                    ticks: {
                      color: "balck",
                    },
                  },
                },
              },
              data: {
                labels: bar_label,
                datasets: bar_dollar_total,
              },
            };
            data.contestData.BarChart = {
              id: "bar",
              type: "bar",
              options: {
                min: 0,
                max: 100,
                responsive: true,
                plugins: {
                  legend: {
                    labels: {
                      color: "balck",
                    },
                    position: "top",
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: "balck",
                    },
                  },
                  y: {
                    min: 0,

                    ticks: {
                      color: "balck",
                    },
                  },
                },
              },
              data: {
                labels: bar_label,
                datasets: bar_dollar_count,
              },
            };
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            data.paymentLoading = false;
          }
        });

      break;
    case 2:
      manager_paymentAPI
        .getPaymentItemDetail(
          data.paymentFilters.company_id.value,
          data.paymentFilters.department_id.value,
          data.paymentFilters.factory_id.value,
          data.paymentFilters.payment_item_id.value,
          data.paymentFilters.settlement_of_month_begin.value,
          data.paymentFilters.settlement_of_month_end.value
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            //   account_types

            data.payments.data = res.data.data.datas;
            data.paymentLoading = false;
            data.chart = true;
            let bar_label = [];
            let bar_dollar_total = [];
            let bar_dollar_count = [];

            for (let i = 0; i < res.data.data.datas.length; i++) {
              addIfNotExists(
                bar_label,
                res.data.data.datas[i].settlement_of_month.split("T")[0]
              );
              if (
                bar_label[bar_label.length - 1] ==
                res.data.data.datas[i].settlement_of_month.split("T")[0]
              ) {
                let index = bar_dollar_total.findIndex(
                  (item) =>
                    item.label.split("|")[0] ===
                    String(res.data.data.datas[i].payment_item_id)
                );
                if (index != -1) {
                  bar_dollar_total[index].data.push(
                    res.data.data.datas[i].total_dollar
                  );
                  bar_dollar_count[index].data.push(
                    res.data.data.datas[i].total_count
                  );
                } else {
                  let tmp_name =
                    store.state.lang == "cn"
                      ? res.data.data.datas[i].payment_item_chinese_name
                      : res.data.data.datas[i].payment_item_english_name;
                  bar_dollar_total.push({
                    label:
                      res.data.data.datas[i].payment_item_id + "|" + tmp_name,
                    backgroundColor: ["#1abc9c"],
                    data: [res.data.data.datas[i].total_dollar],
                  });
                  bar_dollar_count.push({
                    label:
                      res.data.data.datas[i].payment_item_id + "|" + tmp_name,
                    backgroundColor: ["#F50002"],
                    data: [res.data.data.datas[i].total_count],
                  });
                }
              }
              //   bar_label.push(res.data.data.datas[i].account_type_name);
              //   bar_dollar_total.push(res.data.data.datas[i].total_dollar);
            }

            data.contestData.BarChartContent = {
              id: "bar",
              type: "bar",
              options: {
                min: 0,
                max: 100,
                responsive: true,
                plugins: {
                  legend: {
                    labels: {
                      color: "balck",
                    },
                    position: "top",
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: "balck",
                    },
                  },
                  y: {
                    min: 0,

                    ticks: {
                      color: "balck",
                    },
                  },
                },
              },
              data: {
                labels: bar_label,
                datasets: bar_dollar_total,
              },
            };
            data.contestData.BarChart = {
              id: "bar",
              type: "bar",
              options: {
                min: 0,
                max: 100,
                responsive: true,
                plugins: {
                  legend: {
                    labels: {
                      color: "balck",
                    },
                    position: "top",
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: "balck",
                    },
                  },
                  y: {
                    min: 0,

                    ticks: {
                      color: "balck",
                    },
                  },
                },
              },
              data: {
                labels: bar_label,
                datasets: bar_dollar_count,
              },
            };
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            data.paymentLoading = false;
          }
        });

      break;
  }
}
watch(
  () => data.CompanySearch,
  () => {
    data.paymentFilters.company_id.value =
      data.CompanySearch == null ? "" : data.CompanySearch.id;
    data.paymentLoading = true;
    getPaymentReport();
  }
);

watch(
  () => data.FactorySearch,
  () => {
    data.paymentFilters.factory_id.value =
      data.FactorySearch == null ? "" : data.FactorySearch.id;
    data.paymentLoading = true;
    getPaymentReport();
  }
);
watch(
  () => data.PaymentItemSearch,
  () => {
    data.paymentFilters.payment_item_id.value =
      data.PaymentItemSearch == null ? "" : data.PaymentItemSearch.id;
    data.paymentLoading = true;
    getPaymentReport();
  }
);
watch(
  () => data.DepartmentSearch,
  () => {
    data.paymentFilters.department_id.value =
      data.DepartmentSearch == null ? "" : data.DepartmentSearch.id;
    data.paymentLoading = true;
    getPaymentReport();
  }
);

watch(
  () => data.settlement_of_month_date,
  () => {
    data.paymentLoading = true;
    if (data.settlement_of_month_date != null) {
      let date = new Date(data.settlement_of_month_date[0].toISOString());
      const GMT_8 = date.getHours() - (8 + date.getTimezoneOffset() / 60);
      date.setHours(GMT_8);
      data.paymentFilters.settlement_of_month_begin.value =
        data.settlement_of_month_date[0] != null ? date.toISOString() : "";

      if (
        data.settlement_of_month_date[1] != null &&
        data.settlement_of_month_date[1] != undefined
      ) {
        let tomorrow = new Date(data.settlement_of_month_date[1].toISOString());
        tomorrow.setDate(data.settlement_of_month_date[1].getDate() + 1);
        const GMT_8_tomorrow =
          tomorrow.getHours() - (8 + tomorrow.getTimezoneOffset() / 60);
        tomorrow.setHours(GMT_8_tomorrow);
        data.paymentFilters.settlement_of_month_end.value =
          tomorrow.toISOString();
      } else {
        data.paymentFilters.settlement_of_month_end.value = "";
      }
    } else {
      data.paymentFilters.settlement_of_month_begin.value = "";
      data.paymentFilters.settlement_of_month_end.value = "";
    }

    getPaymentReport();
  }
);
function materialSum() {
  let count = 0;
  let dollar = 0;
  let total = 0;
  if (data.payments.data != null) {
    for (let i = 0; i < data.payments.data.length; i++) {
      count += Number(data.payments.data[i].total_count);
      total += Number(data.payments.data[i].total_dollar);
    }
    dollar = total / count;
  }

  return {
    count: count.toFixed(2),
    dollar: dollar.toFixed(2),
    total: total.toFixed(2),
  };
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <Accordion
      style="margin-top: 100px"
      class="accordion-custom p-col-12"
      :activeIndex="0"
    >
      <AccordionTab>
        <template #header>
          <i class="pi pi-search p-mr-1"></i>
          <span>{{ i18n.$t("Payment").PaymentTable.Search }}</span>
        </template>
        <p style="text-align: left">
          <span class="p-input-icon-left p-ml-2">
            <Dropdown
              v-model="data.CompanySearch"
              :options="data.selectCompanyList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </span>
          <span class="p-input-icon-left p-mr-1">
            <Dropdown
              v-model="data.FactorySearch"
              :options="data.selectFactoryList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Payment').PaymentTable.Factory.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </span>
          <span class="p-input-icon-left p-mr-1">
            <Dropdown
              v-model="data.PaymentItemSearch"
              :options="data.selectPaymentItemList"
              :filter="true"
              :optionLabel="
                store.state.lang == 'cn' ? 'chinese_name' : 'english_name'
              "
              :placeholder="
                i18n.$t('Payment').PaymentContentTable.PaymentItem.Show
              "
              style="width: 100%"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div v-if="store.state.lang == 'cn'">
                    {{ slotProps.value.chinese_name }}
                    <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                  </div>
                  <div v-else>
                    {{ slotProps.value.english_name }}
                    <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                  </div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div v-if="store.state.lang == 'cn'">
                    {{ slotProps.option.chinese_name }}
                    <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                  </div>
                  <div v-else>
                    {{ slotProps.option.english_name }}
                    <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                  </div>
                </div>
              </template>
            </Dropdown>
          </span>
          <span class="p-input-icon-left p-mr-1">
            <Dropdown
              v-model="data.DepartmentSearch"
              :options="data.selectDepartmentList"
              :filter="true"
              optionLabel="name"
              :placeholder="
                i18n.$t('Payment').PaymentContentTable.DepartmentId.Show
              "
              style="width: 100%"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>
                    {{ slotProps.value.name }}
                    <!-- {{ slotProps.value.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                  </div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>
                    {{ slotProps.option.name }}
                    <!-- {{ slotProps.option.chinese_name }}({{
                    slotProps.value.english_name
                  }}) -->
                  </div>
                </div>
              </template>
            </Dropdown>
          </span>
        </p>
        <div class="p-col-12" style="text-align: left">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <Calendar
              id="range"
              v-model="data.settlement_of_month_date"
              selectionMode="range"
              view="month"
              dateFormat="mm/yy"
              :manualInput="false"
              :showIcon="true"
              :selectOtherMonths="true"
              :placeholder="
                i18n.$t('Payment').PaymentTable.SettlementOfMonth.Show
              "
              :showButtonBar="true"
            />
          </span>
        </div>
      </AccordionTab>
    </Accordion>
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.payments.data"
    >
      <Column
        class="p-col-1"
        :field="i18n.$t('PaymentDetail').PaymentDetailTable.PaymentItemID.Field"
        :header="i18n.$t('PaymentDetail').PaymentDetailTable.PaymentItemID.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="
          i18n.$t('PaymentDetail').PaymentDetailTable.PaymentItemChineseName
            .Field
        "
        :header="
          i18n.$t('PaymentDetail').PaymentDetailTable.PaymentItemChineseName
            .Show
        "
      ></Column>
      <Column
        class="p-col-1"
        :field="
          i18n.$t('PaymentDetail').PaymentDetailTable.PaymentItemEnglishName
            .Field
        "
        :header="
          i18n.$t('PaymentDetail').PaymentDetailTable.PaymentItemEnglishName
            .Show
        "
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('PaymentDetail').PaymentDetailTable.Dollar.Field"
        :header="i18n.$t('PaymentDetail').PaymentDetailTable.Dollar.Show"
      >
        <template #body="slotProps"
          >{{
            formatNumberWithCommas(
              (
                Number(slotProps.data.total_dollar) /
                Number(slotProps.data.total_count)
              ).toFixed(2)
            )
          }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('PaymentDetail').PaymentDetailTable.TotalCount.Field"
        :header="i18n.$t('PaymentDetail').PaymentDetailTable.TotalCount.Show"
      ></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('PaymentDetail').PaymentDetailTable.TotalDollar.Field"
        :header="i18n.$t('PaymentDetail').PaymentDetailTable.TotalDollar.Show"
      >
        {{ formatNumberWithCommas(Number(slotProps.data.total_dollar)) }}
      </Column>
      <Column
        class="p-col-1"
        :field="
          i18n.$t('PaymentDetail').PaymentDetailTable.SettlementOfMonth.Field
        "
        :header="
          i18n.$t('PaymentDetail').PaymentDetailTable.SettlementOfMonth.Show
        "
      >
        <template #body="slotProps"
          >{{ slotProps.data.settlement_of_month.split("T")[0] }}
        </template>
      </Column>
      <ColumnGroup type="footer">
        <Row>
          <Column
            footer="QTY In Total:"
            :colspan="0"
            footerStyle="text-align:right"
          />
          <Column :footer="materialSum().count" />
          <Column footer="Rand in Total:" footerStyle="text-align:right" />
          <Column :footer="formatNumberWithCommas(materialSum().total)" />
        </Row>
      </ColumnGroup>
    </DataTable>
    <div class="p-grid">
      <!-- <div v-if="data.chart" class="p-col-6">
        <vue3-chart-js v-bind="{ ...data.contestData.LineChart }" />
      </div> -->
      <div v-if="data.chart" class="p-col-6">
        <h3>QTY In Total</h3>
        <vue3-chart-js v-bind="{ ...data.contestData.BarChart }" />
      </div>
      <div v-if="data.chart" class="p-col-6">
        <h3>Rand in Total</h3>
        <vue3-chart-js v-bind="{ ...data.contestData.BarChartContent }" />
      </div>
    </div>
    <!-- data.payments.data -->
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
