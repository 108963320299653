<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import { useStore } from "vuex";
import { dateFormate } from "../tool/formate";

const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  date: new Date(),
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
function extractText(str) {
  const match = str.match(/\(([^)]+)\)/);
  return match ? match[1] : str;
}
</script>

<template>
  <div
    :key="index"
    v-for="(water, index) in store.state.water"
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
      height: 100vh;
    "
  >
    <div style="width: 45%; text-align: left">
      <img style="height: 80px; width: 80px" :src="store.state.logo" />
    </div>
    <!-- <div style="width: 100%; text-align: center"> -->
    <div style="width: 55%; text-align: left">
      <h2>Payslip</h2>
      <h5>
        <!-- Zoonodle Inc<br />
        21023 Person Point Road<br />
        Gateway Avenue -->
        Texray Group
      </h5>
    </div>
    <div
      style="
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 24%; text-align: left">System ID</div>
      <div style="width: 24%; text-align: left">
        {{ water.recipient_employer_id }}
      </div>
      <div style="width: 24%; text-align: left">Employee name</div>
      <div style="width: 24%; text-align: left">
        {{ water.recipient_employer_english_name }}
      </div>
    </div>
    <div
      style="
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 24%; text-align: left">Pay Period</div>
      <div style="width: 24%; text-align: left">
        {{
          dateFormate(water.pay_period, "YYYY-MM") ==
            dateFormate(water.pay_period_end, "YYYY-MM") ||
          water.pay_period_end == undefined
            ? dateFormate(water.pay_period, "YYYY-MM")
            : dateFormate(water.pay_period, "YYYY-MM") +
              "~" +
              dateFormate(water.pay_period_end, "YYYY-MM")
        }}
      </div>
      <div style="width: 24%; text-align: left">Designation</div>
      <div style="width: 24%; text-align: left">
        <span
          :key="work_permit_index"
          v-for="(work_permit, work_permit_index) in water.work_permits"
        >
          {{ work_permit.name }}
        </span>
      </div>
    </div>
    <div
      style="
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 24%; text-align: left">ID NO.</div>
      <div style="width: 24%; text-align: left">
        {{ water.recipient_employer_identity_card_number }}
      </div>
      <div style="width: 24%; text-align: left">Department</div>
      <div style="width: 24%; text-align: left">
        {{
          store.state.lang == "en"
            ? extractText(water.organisation_name)
            : water.organisation_name
        }}
      </div>
    </div>
    <br />
    <div
      style="
        border-bottom: 1px solid black;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 50%; text-align: left">
        <h3>Earning</h3>
      </div>
      <div style="width: 50%; text-align: right"><h3>Amount</h3></div>
    </div>

    <div
      :key="salary_positive_item_index"
      v-for="(
        salary_positive_item, salary_positive_item_index
      ) in water.salary_positive"
      style="
        border-bottom: 1px solid black;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 50%; text-align: left">
        {{
          store.state.lang == "en"
            ? extractText(salary_positive_item.salary_item_name)
            : salary_positive_item.salary_item_name
        }}
      </div>
      <div style="width: 50%; text-align: right">
        {{ salary_positive_item.dollar }}
      </div>
    </div>

    <div style="width: 100%; text-align: right">
      <h4>
        Total Earning:{{ Math.round(water.total_positive * 100) / 100 - 0 }}
      </h4>
    </div>
    <br />
    <br />

    <div
      style="
        border-bottom: 1px solid black;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 50%; text-align: left">
        <h3>Deductions</h3>
      </div>
      <div style="width: 50%; text-align: right"><h3>Amount</h3></div>
    </div>

    <div
      :key="salary_negative_item_index"
      v-for="(
        salary_negative_item, salary_negative_item_index
      ) in water.salary_negative"
      style="
        border-bottom: 1px solid black;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 50%; text-align: left">
        {{
          store.state.lang == "en"
            ? extractText(salary_negative_item.salary_item_name)
            : salary_negative_item.salary_item_name
        }}
      </div>
      <div style="width: 50%; text-align: right">
        {{ salary_negative_item.dollar }}
      </div>
    </div>

    <div style="width: 100%; text-align: right">
      <h4>
        Total Deductions:{{ water.total_negative }}
        <br />
        Net Pay:{{
          Math.round((water.total_positive - water.total_negative) * 100) / 100
        }}
      </h4>
    </div>
    <br />
    <br />

    <div
      style="
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 50%; text-align: center">
        <!-- <h3>Employer Signature</h3> -->
      </div>
      <div style="width: 50%; text-align: center">
        <!-- <h3>Employee Signature</h3> -->
      </div>
    </div>
    <br />
    <br />
    <div
      style="
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
      "
    >
      <div style="width: 50%; text-align: center">
        <!-- <h3>____________________</h3> -->
      </div>
      <div style="width: 50%; text-align: center">
        <!-- <h3>____________________</h3> -->
      </div>
    </div>
    <div style="width: 100%; text-align: center">
      <h4>This is system generated payslip</h4>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
