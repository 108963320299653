import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class SalaryRaise {
  getSalaryRaiseInfo(id) {
    return axios.get(
      `/api/admin/salary-raise/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getSalaryRaiseList(
    organisation_id,
    apply_date_begin,
    apply_date_end,
    apply_employer_id,
    apply_employer_name,
    status,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/salary-raise/list`,
      {
        params: {
          organisation_id: organisation_id,
          apply_date_begin: apply_date_begin,
          apply_date_end: apply_date_end,
          apply_employer_id: apply_employer_id,
          apply_employer_name: apply_employer_name,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchSalaryRaise(datas) {
    return axios.post(
      `/api/admin/batch/salary-raise`,
      {
        datas: datas,
      },
      config
    );
  }
  addSalaryRaise(
    before_salary,
    new_salary,
    reason,
    effective_date,
    apply_employer_id
  ) {
    return axios.post(
      `/api/admin/salary-raise`,
      {
        before_salary: before_salary,
        new_salary: new_salary,
        reason: reason,
        effective_date: effective_date,
        apply_employer_id: apply_employer_id,
      },
      config
    );
  }
  editSalaryRaise(
    id,
    before_salary,
    new_salary,
    status,
    apply_date,
    reason,
    effective_date,
    organisation_id,
    audit_employer_id,
    apply_employer_id
  ) {
    return axios.put(
      `/api/admin/salary-raise`,
      {
        id: id,
        before_salary: before_salary,
        new_salary: new_salary,
        status: status,
        apply_date: apply_date,
        reason: reason,
        effective_date: effective_date,
        organisation_id: organisation_id,
        audit_employer_id: audit_employer_id,
        apply_employer_id: apply_employer_id,
      },
      config
    );
  }
  editSalaryStatus(id, status, reason, new_salary) {
    return axios.put(
      `/api/admin/salary-raise`,
      {
        id: id,
        status: status,
        reason: reason,
        new_salary: new_salary,
      },
      config
    );
  }
  deleteSalaryRaise(id) {
    return axios.delete(
      `/api/admin/salary-raise`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
