<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import * as XLSX from "xlsx/xlsx.mjs";
import Sidebar from "primevue/sidebar";
import EmployerView from "../../components/Employer.vue";
import Chip from "primevue/chip";
import router from "../../router";
import Toast from "primevue/toast";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputNumber";
import Button from "primevue/button";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import Employer from "../../api/salary/self/employer";
import Bouns from "../../api/salary/self/bouns";
import AdminBouns from "../../api/salary/admin/bouns";
import ManagerBouns from "../../api/salary/manager/bouns";
import Currency from "../../api/salary/self/currency";
import { dateFormate } from "../../tool/formate";
import PrintApplication from "../../components/PrintApplication.vue";
import { printHTML } from "../../tool/print";
import africa_logo from "../../assets/africa_logo.jpg";

const i18n = useI18n();
const toast = useToast();
const store = useStore();
let userApi = new User();
let employerApi = new Employer();
let bounsApi = new Bouns();
let admin_bounsApi = new AdminBouns();
let manager_bounsApi = new ManagerBouns();

let currencyAPI = new Currency();

const printDom = ref(null);
const data = reactive({
  minDate: getLastMonth(),
  maxDate: new Date(),
  selectCurrencyList: [],
  CurrencySearch: "",
  date_search: "",
  printDialog: false,
  send: false,
  bounss: { data: null },
  bouns: null,
  bounsLoading: false,
  page: 1,
  page_num: 10,
  totalRecords: 0,
  employer: {
    organisation_name: "",
    name: "",
    english_name: "",
    position_name: "",
  },
  add_bouns: {
    dollar: 0,
    reason: "",
    currency_id: 0,
    apply_date: "",
  },
  bounsFilters: {
    apply_date_begin: { value: "", matchMode: "contains" },
    apply_date_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  getEmployerInfo();
  getCurrencySelectList();
  getBounsList();
  store.commit("set_logo", africa_logo);
  store.commit("set_employer_search", "apply_bouns");
  store.commit("set_bouns_employer", []);
});
function getLastMonth() {
  let nowDate = new Date();
  let year = nowDate.getFullYear();
  let month = nowDate.getMonth();
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  let lastMonth = new Date(year, month, 0);
  return lastMonth;
}
function getCurrencySelectList() {
  currencyAPI.getCurrencySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCurrencyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

async function printApplyBouns(bouns, dom) {
  await store.commit("set_employer", data.employer);
  //   await store.commit("set_bouns", bouns);
  await store.commit("set_payment_content", []);
  await store.commit("set_payment", {
    company_name: bouns.organisation_name,
    id: bouns.id,
    apply_employer_name: bouns.apply_employer_name,
    created_at: bouns.created_at,
    total_dollar: bouns.dollar,
    pay_date: bouns.created_at,
    description: bouns.reason,
    remark: "",
  });
  //   await store.commit("set_payment_content", data.payment_contents);
  // data.send = false;
  printHTML(dom);
}

function saveApplyBouns() {
  data.send = true;
  let datas = [];
  //   String(data.add_bouns.dollar),
  // data.add_bouns.currency_id,
  // data.add_bouns.reason
  for (let i = 0; i < store.state.bouns_employer.length; i++) {
    console.log(store.state.user);
    datas.push({
      apply_employer_id: store.state.bouns_employer[i].id,
      dollar: String(data.add_bouns.dollar),
      currency_id: data.add_bouns.currency_id,
      reason: data.add_bouns.reason,
      apply_date: new Date().toISOString(),
    });
  }
  if (store.state.user.role == 1) {
    admin_bounsApi.batchBouns(datas).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ApplyBouns").AddSuccess,
          life: 3000,
        });
        data.bounsLoading = true;
        data.send = false;
        getBounsList();
      } else {
        data.send = false;
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  } else if (store.state.user.role == 2) {
    manager_bounsApi.batchBouns(datas).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ApplyBouns").AddSuccess,
          life: 3000,
        });
        data.bounsLoading = true;
        data.send = false;
        getBounsList();
      } else {
        data.send = false;
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  } else {
    bounsApi
      .addBouns(
        String(data.add_bouns.dollar),
        data.add_bouns.currency_id,
        data.add_bouns.reason
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("ApplyBouns").AddSuccess,
            life: 3000,
          });
          data.bounsLoading = true;
          data.send = false;
          getBounsList();
        } else {
          data.send = false;
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
  }
}

function getEmployerInfo() {
  employerApi.getEmployerInfo().then((res) => {
    if (res.data.error_code == 0) {
      // console.log("test");
      // console.log(res.data.data);
      data.employer = res.data.data;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getBounsList() {
  if (store.state.user.role == 1) {
    admin_bounsApi
      .getEasyBounsList(
        data.bounsFilters.apply_date_begin.value,
        data.bounsFilters.apply_date_end.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          // console.log("test");
          // console.log(res.data.data);
          data.totalRecords = res.data.data.total_count;
          data.bounss.data = res.data.data.datas;
          data.page = res.data.data.page;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          if (res.data.error_code == 8106) {
            data.totalRecords = 0;
            data.bounss.data = [];
            // data.page = 0;
          }
        }
        data.bounsLoading = false;
      });
  } else if (store.state.user.role == 2) {
    manager_bounsApi
      .getEasyBounsList(
        data.bounsFilters.apply_date_begin.value,
        data.bounsFilters.apply_date_end.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          // console.log("test");
          // console.log(res.data.data);
          data.totalRecords = res.data.data.total_count;
          data.bounss.data = res.data.data.datas;
          data.page = res.data.data.page;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          if (res.data.error_code == 8106) {
            data.totalRecords = 0;
            data.bounss.data = [];
            // data.page = 0;
          }
        }
        data.bounsLoading = false;
      });
  } else {
    bounsApi
      .getBounsList(
        data.bounsFilters.apply_date_begin.value,
        data.bounsFilters.apply_date_end.value,
        data.page,
        data.page_num
      )
      .then((res) => {
        if (res.data.error_code == 0) {
          // console.log("test");
          // console.log(res.data.data);
          data.totalRecords = res.data.data.total_count;
          data.bounss.data = res.data.data.datas;
          data.page = res.data.data.page;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
          if (res.data.error_code == 8106) {
            data.totalRecords = 0;
            data.bounss.data = [];
            // data.page = 0;
          }
        }
        data.bounsLoading = false;
      });
  }
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.bounsLoading = true;
    getBounsList();
  }
);
watch(
  () => data.CurrencySearch,
  () => {
    data.add_bouns.currency_id =
      data.CurrencySearch == null ? "" : data.CurrencySearch.id;
  }
);
watch(
  () => data.add_date,
  () => {
    if (data.add_date != null) {
      let dateTime = new Date();
      dateTime.setFullYear(data.add_date.getFullYear());
      dateTime.setMonth(data.add_date.getMonth());
      dateTime.setDate(data.add_date.getDate());
      data.add_bouns.apply_date = dateTime.toISOString();
    } else {
      data.add_bouns.apply_date = "";
    }
  }
);
watch(
  () => data.date_search,
  async () => {
    data.bounsLoading = true;
    if (data.date_search != null) {
      const GMT_8 =
        data.date_search.getHours() -
        (8 + data.date_search.getTimezoneOffset() / 60);

      let date = new Date(data.date_search.toISOString());
      await date.setHours(GMT_8);
      data.bounsFilters.apply_date_begin.value = date.toISOString();
      data.bounsFilters.apply_date_end.value = date.toISOString();
    } else {
      data.bounsFilters.apply_date_begin.value = "";
      data.bounsFilters.apply_date_end.value = "";
    }
    getBounsList();
  }
);
function ChooseEmployer() {
  data.chooseDialog = true;
  store.commit("set_employer_search", "apply_bouns");
}
function importApplyBouns(e) {
  console.log(e.target.files[0]);
  //上传文件后读取excel文件内容
  let file = e.target.files[0];
  const types = ["xlsx", "xls"];
  const arr = file.name.split(".");
  //判断文件是否为excel文件
  if (!types.find((item) => item === arr[arr.length - 1])) {
    alert("choose (xlsx or xls)");
    return;
  }
  let reader = new FileReader();
  //启动函数
  reader.readAsBinaryString(file);
  reader.onload = (e) => {
    console.log(e);
    //workbook存放excel的所有基本信息
    let workbook = XLSX.read(e.target.result, {
      type: "binary",
      cellDates: true,
    });
    //定义sheetList中存放excel表格的sheet表，就是最下方的tab
    let sheetList = workbook.SheetNames;
    //读取文件内容，（第一个sheet里的内容）
    // range：设置从第几行开始读取内容
    let json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetList[0]], {
      range: 1,
    });
    let importExcel = [];
    for (let i = 0; i < json.length; i++) {
      importExcel.push({
        apply_employer_id:
          json[i].apply_employer_id != undefined
            ? json[i].apply_employer_id
            : "",
        dollar: json[i].dollar != undefined ? String(json[i].dollar) : "",
        currency_id:
          json[i].currency_id != undefined ? json[i].currency_id : "",
        reason: json[i].reason != undefined ? json[i].reason : "",
        apply_date: new Date().toISOString(),
      });
    }
    admin_bounsApi.batchBouns(importExcel).then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ApplyBouns").AddSuccess,
          life: 3000,
        });
        data.bounsLoading = true;

        getBounsList();
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
  };
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-grid">
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyBouns").Employer }}
        </div>
        <div v-if="store.state.user.role == 3" class="p-col-12">
          {{ i18n.$t("Employer").EmployerTable.Organisation.Show }}:{{
            data.employer.organisation_name
          }}
          {{ i18n.$t("Employer").EmployerTable.Name.Show }}:{{
            data.employer.name
          }}
          {{ i18n.$t("Employer").EmployerTable.EnglishName.Show }}:{{
            data.employer.english_name
          }}
          {{ i18n.$t("Employer").EmployerTable.PositionName.Show }}:
          <span
            v-if="
              data.employer.positions != null && data.employer.positions != []
            "
          >
            <Chip
              :key="position_idex"
              class="p-mr-1"
              v-for="(position, position_idex) in data.employer.positions"
              :label="position.name"
            />
          </span>
        </div>
        <div v-else class="p-col-12">
          <Button
            :label="i18n.$t('ApplySalary').SelectEmployer"
            class="p-mr-2 p-button-sm"
            @click="ChooseEmployer"
          />
          <span style="line-height: 35px">
            <Chip
              :key="index"
              :label="employer.id + ':' + employer.name"
              v-for="(employer, index) in store.state.bouns_employer"
            />
          </span>
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyBouns").ApplyBounsTable.USDollar.Show }}
        </div>
        <div class="p-col-12">
          <InputNumber
            id="name"
            v-model="data.add_bouns.dollar"
            mode="currency"
            currency="USD"
            locale="en-US"
            :minFractionDigits="2"
            :maxFractionDigits="5"
            required="true"
          />
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyBouns").ApplyBounsTable.ExchangeRate.Show }}
        </div>
        <div class="p-col-12">
          <Dropdown
            class="p-ml-1"
            v-model="data.CurrencySearch"
            :options="data.selectCurrencyList"
            optionLabel="name"
            :filter="true"
            :placeholder="
              i18n.$t('SpotExchangeRate').SpotExchangeRateTable.CurrencyName
                .Show
            "
            :showClear="true"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyBouns").Reason }}
        </div>
        <div class="p-col-12">
          <Textarea
            id="description"
            v-model="data.add_bouns.reason"
            required="true"
            rows="4"
            cols="43"
          />
        </div>
        <div class="p-col-12">
          <Button
            :disabled="data.send"
            :label="i18n.$t('ApplyBouns').Send"
            icon="pi pi-send"
            class="p-button-info p-mr-2"
            @click="saveApplyBouns"
          />
          <label
            v-if="store.state.user.role == 1"
            class="p-button-danger p-button p-component"
          >
            <input
              id="upload_img"
              style="display: none"
              type="file"
              @change="importApplyBouns"
            />
            <i class="pi pi-file p-mr-1"></i>
            {{ i18n.$t("ApplyBouns").SendExcel }}
          </label>
          <!-- <Button
            :label="i18n.$t('ApplyBouns').Print"
            icon="pi pi-print"
            class="p-button-success p-mr-2"
            @click="printApplyBouns(printDom)"
          /> -->
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyBouns").History }}
        </div>
        <div class="p-col-12">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.bounss.data"
            :loading="data.bounsLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 335px)"
            style="min-width: 375px; margin-top: -5px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.bounsFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('ApplyBouns').ApplyBounsTable.ApplyDate.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div>
            </template>
            <Column
              class="p-col-1"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.ApplyDate.Show"
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.apply_date, "YYYY-MM") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyBouns').ApplyBounsTable.USDollar.Field"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.USDollar.Show"
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyBouns').ApplyBounsTable.ExchangeRate.Field"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.ExchangeRate.Show"
            ></Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyBouns').ApplyBounsTable.ExchangeDollar.Show
              "
            >
              <template #body="slotProps">
                {{ slotProps.data.dollar * slotProps.data.exchange_rate }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyBouns').ApplyBounsTable.CurrencyName.Field"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.CurrencyName.Show"
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyBouns').ApplyBounsTable.Reason.Field"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.Reason.Show"
            ></Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.Status.Show"
            >
              <template #body="slotProps">
                {{
                  slotProps.data.status == 1
                    ? i18n.$t("ApplyBouns").Reject
                    : slotProps.data.status == 2
                    ? i18n.$t("ApplyBouns").Pass
                    : slotProps.data.status == 3
                    ? i18n.$t("ApplyBouns").Doing
                    : i18n.$t("ApplyBouns").UnKnow
                }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyBouns').ApplyBounsTable.AuditEmployerName.Show
              "
              :field="
                i18n.$t('ApplyBouns').ApplyBounsTable.AuditEmployerName.Field
              "
            >
            </Column>
            <Column class="p-col-2">
              <template #body="slotProps">
                <Button
                  :label="i18n.$t('ApplyBouns').Print"
                  icon="pi pi-print"
                  class="p-button-success p-mr-2"
                  @click="printApplyBouns(slotProps.data, printDom)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="data.printDialog">
    <div ref="printDom">
      <PrintApplication />
    </div>
  </Dialog>
  <Sidebar
    v-model:visible="data.chooseDialog"
    style="width: 70%; min-width: 375px"
    position="right"
  >
    <div style="margin-top: -80px">
      <EmployerView />
    </div>
  </Sidebar>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
