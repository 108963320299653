import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Bouns {
  getBounsInfo(id) {
    return axios.get(
      `/api/self/bonus/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getBounsList(apply_date_begin, apply_date_end, page, page_num) {
    return axios.get(
      `/api/self/bonus/list`,
      {
        params: {
          apply_date_begin: apply_date_begin,
          apply_date_end: apply_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchBouns(datas) {
    return axios.post(
      `/api/self/bonus/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addBouns(dollar, currency_id, reason) {
    return axios.post(
      `/api/self/bonus/`,
      {
        dollar: dollar,
        currency_id: currency_id,
        reason: reason,
        apply_date: new Date(),
      },
      config
    );
  }
}
