import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Company {
  getCompanyInfo(id) {
    return axios.get(
      `/api/admin/company/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getCompanyList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/company/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getCompanySelectList(name) {
    return axios.get(
      `/api/admin/company/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchCompany(datas) {
    return axios.post(
      `/api/admin/batch/company`,
      {
        datas: datas,
      },
      config
    );
  }
  addCompany(name, description) {
    return axios.post(
      `/api/admin/company`,
      {
        name: name,
        description: description,
      },
      config
    );
  }
  editCompany(id, name, description) {
    return axios.put(
      `/api/admin/company`,
      {
        id: id,
        name: name,
        description: description,
      },
      config
    );
  }
  deleteCompany(id) {
    return axios.delete(
      `/api/admin/company`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
