<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Chip from "primevue/chip";
import router from "../../router";
import Toast from "primevue/toast";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputNumber";
import InputText from "primevue/inputText";
import Button from "primevue/button";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import Employer from "../../api/salary/self/employer";
import GiveUpHoliday from "../../api/salary/self/give_up_holiday";
import { dateFormate } from "../../tool/formate";
import { printHTML } from "../../tool/print";
import PrintGiveUpHoliday from "../../components/PrintGiveUpHoliday.vue";
import africa_logo from "../../assets/africa_logo.jpg";
const PrintGiveUp = ref(null);

const i18n = useI18n();
const toast = useToast();
const store = useStore();
let userApi = new User();
let employerApi = new Employer();
let give_up_holidayApi = new GiveUpHoliday();

const printDom = ref(null);
const data = reactive({
  add_give_up_holiday: {
    years: "",
    place: "",
  },
  date_search: "",
  send: false,
  give_up_holidays: { data: null },
  give_up_holiday: null,
  give_up_holidayLoading: false,
  page: 1,
  page_num: 10,
  totalRecords: 0,
  employer: {
    organisation_name: "",
    name: "",
    english_name: "",
    position_name: "",
  },
  give_up_holidayFilters: {
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  getEmployerInfo();
  getGiveUpHolidayList();
});

function saveApplyGiveUpHoliday() {
  data.send = true;
  give_up_holidayApi
    .addGiveUpHoliday(
      data.add_give_up_holiday.reason,
      i18n.$t("ApplyGiveUpHoliday").Description_1 +
        data.add_give_up_holiday.years +
        i18n.$t("ApplyGiveUpHoliday").Description_2 +
        data.add_give_up_holiday.place +
        i18n.$t("ApplyGiveUpHoliday").Description_3
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("ApplyGiveUpHoliday").AddSuccess,
          life: 3000,
        });
        data.give_up_holidayLoading = true;
        data.send = false;
        getGiveUpHolidayList();
      } else {
        data.send = false;
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function getEmployerInfo() {
  employerApi.getEmployerInfo().then((res) => {
    if (res.data.error_code == 0) {
      // console.log("test");
      // console.log(res.data.data);
      data.employer = res.data.data;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

async function Print(payment, print_give_up_holiday) {
  // payment.department = [];
  // getPaymentContentList(payment.id, true);
  // setTimeout(async () => {
  //   for (let i = 0; i < data.payment_contents.length; i++) {
  //     if (
  //       payment.department.indexOf(data.payment_contents[i].department_name) ==
  //       -1
  //     ) {
  //       payment.department.push(data.payment_contents[i].department_name);
  //     }
  //   }
  //   await store.commit("set_payment", payment);
  //   await store.commit("set_payment_content", data.payment_contents);
  await store.commit("set_payment", {
    company_name: payment.organisation_name,
    id: payment.id,
    apply_employer_name: payment.apply_employer_name,
    created_at: payment.created_at,
    total_dollar: payment.salary_dollar,
    factory_name: [payment.apply_employer_name],
    description: payment.description,
    remark: payment.reason,
    payment_method: "",
  });
  printHTML(print_give_up_holiday);
  // }, 300);
}

function getGiveUpHolidayList() {
  give_up_holidayApi
    .getGiveUpHolidayList(
      data.give_up_holidayFilters.created_at_begin.value,
      data.give_up_holidayFilters.created_at_end.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        // console.log("test");
        console.log(res.data.data);
        data.totalRecords = res.data.data.total_count;
        data.give_up_holidays.data = res.data.data.datas;
        data.page = res.data.data.page;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
        if (res.data.error_code == 8106) {
          data.totalRecords = 0;
          data.give_up_holidays.data = [];
          // data.page = 0;
        }
      }
      data.give_up_holidayLoading = false;
    });
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.give_up_holidayLoading = true;
    getGiveUpHolidayList();
  }
);
watch(
  () => data.date_search,
  async () => {
    data.give_up_holidayLoading = true;
    if (data.date_search != null) {
      const GMT_8 =
        data.date_search.getHours() -
        (8 + data.date_search.getTimezoneOffset() / 60);

      let date = new Date(data.date_search.toISOString());
      await date.setHours(GMT_8);
      data.give_up_holidayFilters.created_at_begin.value = date.toISOString();
      data.give_up_holidayFilters.created_at_end.value = date.toISOString();
    } else {
      data.give_up_holidayFilters.created_at_begin.value = "";
      data.give_up_holidayFilters.created_at_end.value = "";
    }
    getGiveUpHolidayList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-grid">
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyGiveUpHoliday").Employer }}
        </div>
        <div class="p-col-12">
          {{ i18n.$t("Employer").EmployerTable.Organisation.Show }}:{{
            data.employer.organisation_name
          }}
          {{ i18n.$t("Employer").EmployerTable.Name.Show }}:{{
            data.employer.name
          }}
          {{ i18n.$t("Employer").EmployerTable.EnglishName.Show }}:{{
            data.employer.english_name
          }}
          {{ i18n.$t("Employer").EmployerTable.PositionName.Show }}:
          <span
            v-if="
              data.employer.positions != null && data.employer.positions != []
            "
          >
            <Chip
              :key="position_idex"
              class="p-mr-1"
              v-for="(position, position_idex) in data.employer.positions"
              :label="position.name"
            />
          </span>
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyGiveUpHoliday").Reason }}
        </div>
        <div class="p-col-12">
          {{ i18n.$t("ApplyGiveUpHoliday").Description_1 }}
          <InputNumber
            v-model="data.add_give_up_holiday.years"
            inputId="integeronly"
            required="true"
          />
          {{ i18n.$t("ApplyGiveUpHoliday").Description_2 }}
          <InputText
            id="name"
            v-model.trim="data.add_give_up_holiday.place"
            required="true"
          />
          {{ i18n.$t("ApplyGiveUpHoliday").Description_3 }}
          <br />
          <br />
          <Textarea
            id="description"
            :placeholder="i18n.$t('ApplyGiveUpHoliday').Reason"
            v-model="data.add_give_up_holiday.reason"
            required="true"
            rows="4"
            cols="43"
          />
        </div>
        <div class="p-col-12">
          <Button
            :disabled="data.send"
            :label="i18n.$t('ApplyGiveUpHoliday').Send"
            icon="pi pi-send"
            class="p-button-info p-mr-2"
            @click="saveApplyGiveUpHoliday"
          />
          <!-- <Button
            :label="i18n.$t('ApplyBorrow').Print"
            icon="pi pi-print"
            class="p-button-success p-mr-2"
            @click="printApplyBorrow(printDom)"
          /> -->
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyGiveUpHoliday").History }}
        </div>
        <div class="p-col-12">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.give_up_holidays.data"
            :loading="data.give_up_holidayLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 335px)"
            style="min-width: 375px; margin-top: -5px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.give_up_holidayFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                        .CreatedAt.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div>
            </template>
            <Column
              class="p-col-1"
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable.CreatedAt
                  .Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-1"
              :field="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                  .Description.Field
              "
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                  .Description.Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable.Reason
                  .Field
              "
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable.Reason
                  .Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable.Status
                  .Show
              "
            >
              <template #body="slotProps">
                {{
                  slotProps.data.status == 1
                    ? i18n.$t("ApplyGiveUpHoliday").Reject
                    : slotProps.data.status == 2
                    ? i18n.$t("ApplyGiveUpHoliday").Pass
                    : slotProps.data.status == 3
                    ? i18n.$t("ApplyGiveUpHoliday").Doing
                    : i18n.$t("ApplyGiveUpHoliday").UnKnow
                }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                  .AuditEmployerName.Show
              "
              :field="
                i18n.$t('ApplyGiveUpHoliday').ApplyGiveUpHolidayTable
                  .AuditEmployerName.Field
              "
            >
            </Column>
            <Column class="p-col-1">
              <template #body="slotProps">
                <Button
                  icon="pi pi-print"
                  class="p-button-rounded p-button-danger p-mr-2 p-button-sm"
                  @click="Print(slotProps.data, PrintGiveUp)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <div class="p-d-none">
    <div ref="PrintGiveUp">
      <PrintGiveUpHoliday />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
