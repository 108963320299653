import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class GiveUpHoliday {
  getGiveUpHolidayInfo(id) {
    return axios.get(
      `/api/manager/give-up-holiday/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getGiveUpHolidayList(
    created_at_begin,
    created_at_end,
    apply_employer_id,
    apply_employer_name,
    status,
    organisation_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/give-up-holiday/list`,
      {
        params: {
          apply_employer_id: apply_employer_id,
          apply_employer_name: apply_employer_name,
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          status: status,
          organisation_id: organisation_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  editGiveUpHoliday(
    id,
    salary_item_id,
    reason,
    description,
    status,
    audit_employer_id,
    apply_employer_id,
    organisation_id
  ) {
    return axios.put(
      `/api/manager/give-up-holiday/`,
      {
        id: id,
        salary_item_id: salary_item_id,
        reason: reason,
        description: description,
        status: status,
        audit_employer_id: audit_employer_id,
        apply_employer_id: apply_employer_id,
        organisation_id: organisation_id,
      },
      config
    );
  }
  editGiveUpHolidayStatus(id, status, reason, description) {
    return axios.put(
      `/api/manager/give-up-holiday/`,
      {
        id: id,
        status: status,
        reason: reason,
        description: description,
      },
      config
    );
  }
  editGiveUpHolidayStatusSuccess(
    id,
    status,
    reason,
    description,
    salary_item_id,
    salary_currency_id,
    salary_dollar,
    salary_settlement_of_month,
    salary_pay_date,
    salary_description
  ) {
    return axios.put(
      `/api/manager/give-up-holiday/`,
      {
        id: id,
        status: status,
        reason: reason,
        description: description,
        salary_item_id: salary_item_id,
        salary_currency_id: salary_currency_id,
        salary_dollar: salary_dollar,
        salary_settlement_of_month: salary_settlement_of_month,
        salary_pay_date: salary_pay_date,
        salary_description: salary_description,
      },
      config
    );
  }
}
