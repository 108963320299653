import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Factory {
  getFactoryInfo(id) {
    return axios.get(
      `/api/admin/factory/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getFactoryList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/factory/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getFactorySelectList(name) {
    return axios.get(
      `/api/admin/factory/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchFactory(datas) {
    return axios.post(
      `/api/admin/factory/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addFactory(
    name,
    description,
    vat_number,
    bank,
    account_number,
    branch_code,
    remark
  ) {
    return axios.post(
      `/api/admin/factory`,
      {
        name: name,
        description: description,
        vat_number: vat_number,
        bank: bank,
        account_number: account_number,
        branch_code: branch_code,
        remark: remark,
      },
      config
    );
  }
  editFactory(
    id,
    name,
    description,
    vat_number,
    bank,
    account_number,
    branch_code,
    remark
  ) {
    return axios.put(
      `/api/admin/factory`,
      {
        id: id,
        name: name,
        description: description,
        vat_number: vat_number,
        bank: bank,
        account_number: account_number,
        branch_code: branch_code,
        remark: remark,
      },
      config
    );
  }
  deleteFactory(id) {
    return axios.delete(
      `/api/admin/factory`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
