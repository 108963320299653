import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class HolidayItem {
  getHolidayItemInfo(id) {
    return axios.get(
      `/api/admin/holiday-item/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getHolidayItemList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/holiday-item/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getHolidayItemSelectList(name) {
    return axios.get(
      `/api/admin/holiday-item/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchHolidayItem(datas) {
    return axios.post(
      `/api/admin/batch/holiday-item`,
      {
        datas: datas,
      },
      config
    );
  }
  addHolidayItem(name, description, lose_percent) {
    return axios.post(
      `/api/admin/holiday-item`,
      {
        name: name,
        description: description,
        lose_percent: lose_percent,
      },
      config
    );
  }
  editHolidayItem(id, name, description, lose_percent) {
    return axios.put(
      `/api/admin/holiday-item`,
      {
        id: id,
        name: name,
        description: description,
        lose_percent: lose_percent,
      },
      config
    );
  }
  deleteHolidayItem(id) {
    return axios.delete(
      `/api/admin/holiday-item`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
