import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Holiday {
  getHolidayInfo(id) {
    return axios.get(
      `/api/self/holiday/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getHolidayList(created_at_begin, created_at_end, page, page_num) {
    return axios.get(
      `/api/self/holiday/list`,
      {
        params: {
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchHoliday(datas) {
    return axios.post(
      `/api/self/holiday/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addHoliday(
    apply_days,
    apply_start_date,
    apply_end_date,
    holiday_item_id,
    reason
  ) {
    return axios.post(
      `/api/self/holiday`,
      {
        apply_days: apply_days,
        apply_start_date: apply_start_date,
        apply_end_date: apply_end_date,
        holiday_item_id: holiday_item_id,
        reason: reason,
      },
      config
    );
  }
}
