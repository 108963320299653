import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class GiveUpHoliday {
  getGiveUpHolidayInfo(id) {
    return axios.get(
      `/api/self/give-up-holiday/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getGiveUpHolidayList(created_at_begin, created_at_end, page, page_num) {
    return axios.get(
      `/api/self/give-up-holiday/list`,
      {
        params: {
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchGiveUpHoliday(datas) {
    return axios.post(
      `/api/self/give-up-holiday/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addGiveUpHoliday(reason, description) {
    return axios.post(
      `/api/self/give-up-holiday/`,
      {
        reason: reason,
        description: description,
      },
      config
    );
  }
}
