import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class SpotExchangeRate {
  getSpotExchangeRateInfo(id) {
    return axios.get(
      `/api/admin/spot-exchange-rate/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getSpotExchangeRateList(
    id,
    currency_id,
    rate_of_month_begin,
    rate_of_month_end,
    status,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/spot-exchange-rate/list`,
      {
        params: {
          id: id,
          currency_id: currency_id,
          rate_of_month_begin: rate_of_month_begin,
          rate_of_month_end: rate_of_month_end,
          status: status,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getSpotExchangeRateSelectList() {
    return axios.get(
      `/api/admin/spot-exchange-rate/select-list`,
      {
        params: {},
      },
      config
    );
  }
  batchSpotExchangeRate(datas) {
    return axios.post(
      `/api/admin/batch/spot-exchange-rate`,
      {
        datas: datas,
      },
      config
    );
  }
  addSpotExchangeRate(currency_id, exchange_rate, rate_of_month) {
    return axios.post(
      `/api/admin/spot-exchange-rate`,
      {
        currency_id: currency_id,
        exchange_rate: exchange_rate,
        rate_of_month: rate_of_month,
      },
      config
    );
  }
  editSpotExchangeRate(id, currency_id, exchange_rate, rate_of_month, status) {
    return axios.put(
      `/api/admin/spot-exchange-rate`,
      {
        id: id,
        currency_id: currency_id,
        exchange_rate: exchange_rate,
        rate_of_month: rate_of_month,
        status: status,
      },
      config
    );
  }
  deleteSpotExchangeRate(id) {
    return axios.delete(
      `/api/admin/spot-exchange-rate`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
