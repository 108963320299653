import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class EmployerDetail {
  getEmployerDetailImageExpireList(
    organisation_id,
    expire_type,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/employer-detail/image-expire/list`,
      {
        params: {
          organisation_id: organisation_id,
          expire_type: expire_type,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getEmployerDetailInfo(employer_id) {
    return axios.get(
      `/api/manager/employer-detail/`,
      {
        params: {
          employer_id: employer_id,
        },
      },
      config
    );
  }
}
