<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Organisation from "../../api/salary/admin/organisation";
import Toast from "primevue/toast";
import Button from "primevue/button";
import EmployerDetail from "../../api/salary/admin/employer_detail";
import ManagerEmployerDetail from "../../api/salary/manager/employer_detail";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let organisationAPI = new Organisation();
let userApi = new User();
let employerAPI = new EmployerDetail();
let manager_employerAPI = new ManagerEmployerDetail();

const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectExpireTypeList: [
    {
      id: 1,
      name: i18n.$t("Employer").EmployerTable.ExpireTypeSelect.WorkCard,
    },
    {
      id: 2,
      name: i18n.$t("Employer").EmployerTable.ExpireTypeSelect.Passport,
    },
    {
      id: 3,
      name: i18n.$t("Employer").EmployerTable.ExpireTypeSelect.Contract,
    },
  ],
  employerLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  employers: { data: null },
  employer: null,
  page: 1,
  page_num: 10,
  selectedEmployers: null,
  employerFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
    expire_type: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getEmployerList();
    if (store.state.user.role == 1) getOrganisationSelectList();
  }
});

function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getEmployerList() {
  CheckUser();
  switch (store.state.user.role) {
    case 1:
      employerAPI
        .getEmployerDetailImageExpireList(
          data.employerFilters.organisation_id.value,
          data.employerFilters.expire_type.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.employers.data = res.data.data.datas;
            data.page = res.data.data.page;
            // data.page_num = res.data.data.page_num;
            // console.log(res);
            data.employerLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.employerLoading = false;
          }
        });

      break;
    case 2:
      manager_employerAPI
        .getEmployerDetailImageExpireList(
          data.employerFilters.organisation_id.value,
          data.employerFilters.expire_type.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.employers.data = res.data.data.datas;
            data.page = res.data.data.page;
            // data.page_num = res.data.data.page_num;
            // console.log(res);
            data.employerLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.employerLoading = false;
          }
        });

      break;
  }
}

function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.employerLoading = true;
    getEmployerList();
  }
);

watch(
  () => data.OrganisationSearch,
  () => {
    data.employerFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    data.employerLoading = true;
    getEmployerList();
  }
);

watch(
  () => data.ExpireTypeSearch,
  () => {
    data.employerFilters.expire_type.value =
      data.ExpireTypeSearch == null ? "" : data.ExpireTypeSearch.id;
    data.employerLoading = true;
    getEmployerList();
  }
);

// watch(
//   () => data.organisationFilters.name.value,
//   () => {
//     data.organisationLoading = true;
//     getOrganisationList();
//   }
// );
// watch(
//   () => data.organisationFilters.id.value,
//   () => {
//     data.organisationLoading = true;
//     getOrganisationList();
//   }
// );
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.employers.data"
      :loading="data.employerLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 175px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.employerFilters"
      v-model:selection="data.selectedEmployers"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search"></i>
                <span>{{
                  i18n.$t("Organisation").OrganisationTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span
                  class="p-input-icon-left p-ml-1"
                  v-if="store.state.user.role == 1"
                >
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.OrganisationSearch"
                    :options="data.selectOrganisationList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerTable.Organisation.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.ExpireTypeSearch"
                    :options="data.selectExpireTypeList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerTable.ExpireType.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </template>
      <!-- <Column class="p-col-1" selectionMode="multiple"></Column> -->
      <Column
        class="p-col-1"
        :field="i18n.$t('Employer').EmployerTable.EmployerID.Field"
        :header="i18n.$t('Employer').EmployerTable.EmployerID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerTable.Name.Field"
        :header="i18n.$t('Employer').EmployerTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerTable.EnglishName.Field"
        :header="i18n.$t('Employer').EmployerTable.EnglishName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerDetailTable.PassportExpire.Field"
        :header="i18n.$t('Employer').EmployerDetailTable.PassportExpire.Show"
      >
        <template #body="slotProps">
          <Button
            disabled
            icon="pi"
            :class="
              slotProps.data.passport_status == 'red'
                ? 'p-button-danger'
                : slotProps.data.passport_status == 'yellow'
                ? 'p-button-warning'
                : 'p-button-success'
            "
          />
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerDetailTable.WorkCardExpiry.Field"
        :header="i18n.$t('Employer').EmployerDetailTable.WorkCardExpiry.Show"
      >
        <template #body="slotProps">
          <Button
            disabled
            icon="pi"
            :class="
              slotProps.data.work_card_status == 'red'
                ? 'p-button-danger'
                : slotProps.data.work_card_status == 'yellow'
                ? 'p-button-warning'
                : 'p-button-success'
            "
          />
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Employer').EmployerDetailTable.ContractExpire.Field"
        :header="i18n.$t('Employer').EmployerDetailTable.ContractExpire.Show"
      >
        <template #body="slotProps">
          <Button
            disabled
            icon="pi"
            :class="
              slotProps.data.contract_status == 'red'
                ? 'p-button-danger'
                : slotProps.data.contract_status == 'yellow'
                ? 'p-button-warning'
                : 'p-button-success'
            "
          />
        </template>
      </Column>
      <!-- <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editOrganisation(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column> -->
    </DataTable>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
