import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Payment {
  getPaymentInfo(id) {
    return axios.get(
      `/api/self/payment/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPaymentSellList(
    id,
    delivery_method,
    payment_method,
    company_id,
    company_account_id,
    factory_id,
    status,
    payment_type_id,
    settlement_of_month_begin,
    settlement_of_month_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/self/payment/receipt/list`,
      {
        params: {
          id: id,
          delivery_method: delivery_method,
          payment_method: payment_method,
          company_id: company_id,
          company_account_id: company_account_id,
          factory_id: factory_id,
          status: status,
          payment_type_id: payment_type_id,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPaymentList(
    id,
    delivery_method,
    payment_method,
    company_id,
    company_account_id,
    factory_id,
    status,
    payment_type_id,
    settlement_of_month_begin,
    settlement_of_month_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/self/payment/list`,
      {
        params: {
          id: id,
          delivery_method: delivery_method,
          payment_method: payment_method,
          company_id: company_id,
          company_account_id: company_account_id,
          factory_id: factory_id,
          status: status,
          payment_type_id: payment_type_id,
          settlement_of_month_begin: settlement_of_month_begin,
          settlement_of_month_end: settlement_of_month_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchPayment(datas) {
    return axios.post(
      `/api/self/payment/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addPayment(
    company_id,
    delivery_method,
    payment_method,
    factory_id,
    payment_type_id,
    status,
    pdf_file,
    remark,
    description,
    custom_order_number,
    settlement_of_month,
    pay_date,
    currency_id
  ) {
    return axios.post(
      `/api/self/payment/`,
      {
        company_id: company_id,
        delivery_method: delivery_method,
        payment_method: payment_method,
        factory_id: factory_id,
        payment_type_id: payment_type_id,
        status: status,
        pdf_file: pdf_file,
        remark: remark,
        description: description,
        custom_order_number: custom_order_number,
        settlement_of_month: settlement_of_month,
        pay_date: pay_date,
        currency_id: currency_id,
        taxed_rate: "0.15",
      },
      config
    );
  }
  editPaymentStatus(id, status) {
    return axios.put(
      `/api/self/payment/`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  editPaymentBatchStatus(ids, status) {
    return axios.put(
      `/api/self/payment/batch/status`,
      {
        ids: ids,
        status: status,
      },
      config
    );
  }
  EditdPaymentPDF(id, pdf_file) {
    return axios.put(
      `/api/self/payment/`,
      {
        id: id,
        pdf_file: pdf_file,
      },
      config
    );
  }
  editPaymentRemark(
    id,
    remark,
    description,
    factory_id,
    custom_order_number,
    settlement_of_month,
    currency_id,
    pay_date,
    taxed_rate
  ) {
    return axios.put(
      `/api/self/payment/`,
      {
        id: id,
        remark: remark,
        description: description,
        factory_id: factory_id,
        custom_order_number: custom_order_number,
        settlement_of_month: settlement_of_month,
        pay_date: pay_date,
        currency_id: currency_id,
        taxed_rate: taxed_rate,
      },
      config
    );
  }
  editPaymentRemarkFactory(id, remark, description, factory_id, pay_date) {
    return axios.put(
      `/api/self/payment/`,
      {
        id: id,
        remark: remark,
        description: description,
        factory_id: factory_id,
        pay_date: pay_date,
      },
      config
    );
  }
  copyPayment(id, custom_order_number) {
    return axios.post(
      `/api/self/payment/copy`,
      {
        id: id,
        custom_order_number: custom_order_number,
      },
      config
    );
  }
}
