<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Factory from "../../api/salary/admin/factory";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let factoryAPI = new Factory();
const store = useStore();
const dt_factory = ref();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  excel_factory: { data: null },
  add_factory: {
    id: "",
    name: "",
    description: "",
    vat_number: "",
    bank: "",
    account_number: "",
    branch_code: "",
    remark: "",
  },
  factoryLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  factorys: { data: null },
  factory: null,
  page: 1,
  page_num: 10,
  selectedFactorys: null,
  factoryFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getFactoryList();
  }
});

function getFactoryList() {
  CheckUser();
  factoryAPI
    .getFactoryList(
      data.factoryFilters.id.value,
      data.factoryFilters.name.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.factorys.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.factoryLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.factoryLoading = false;
      }
    });
}

function saveEditFactory() {
  factoryAPI
    .editFactory(
      data.factory.id,
      data.factory.name,
      data.factory.description,
      data.factory.vat_number,
      data.factory.bank,
      data.factory.account_number,
      data.factory.branch_code,
      data.factory.remark
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Factory").EditSuccess,
          life: 3000,
        });

        data.factoryLoading = true;
        getFactoryList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddFactory() {
  factoryAPI
    .addFactory(
      data.add_factory.name,
      data.add_factory.description,
      data.add_factory.vat_number,
      data.add_factory.bank,
      data.add_factory.account_number,
      data.add_factory.branch_code,
      data.add_factory.remark
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("Factory").AddSuccess,
          life: 3000,
        });

        data.factoryLoading = true;
        getFactoryList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addFactory() {
  data.add_factory = {
    id: "",
    name: "",
    description: "",
    vat_number: "",
    bank: "",
    account_number: "",
    branch_code: "",
    remark: "",
  };
  data.addDialog = true;
}
function editFactory(factory) {
  data.factory = { ...factory };
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(factory) {
  data.factory = factory;
  data.deleteDialog = true;
}
function deleteFactory() {
  factoryAPI.deleteFactory([data.factory.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Factory").DeleteSuccess,
        life: 3000,
      });

      data.factoryLoading = true;
      getFactoryList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedFactory() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedFactorys.length; i++) {
    selectDelete.push(data.selectedFactorys[i].id);
  }
  factoryAPI.deleteFactory(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("Factory").DeleteSuccess,
        life: 3000,
      });

      data.factoryLoading = true;
      data.selectedFactorys = [];
      getFactoryList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.factoryLoading = true;
    getFactoryList();
  }
);
watch(
  () => data.factoryFilters.name.value,
  () => {
    data.factoryLoading = true;
    getFactoryList();
  }
);
watch(
  () => data.factoryFilters.id.value,
  () => {
    data.factoryLoading = true;
    getFactoryList();
  }
);
function ExportExcel() {
  factoryAPI
    .getFactoryList(
      data.factoryFilters.id.value,
      data.factoryFilters.name.value,
      1,
      999
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        // data.selectSalaryItemList = res.data.data.datas;
        let tmp = res.data.data.datas;
        // for (let i = 0; i < tmp.length; i++) {
        //   tmp[i].positive_or_negative =
        //     tmp[i].positive_or_negative == 1
        //       ? i18n.$t("SalaryItem").PositiveOrNegative.Negative
        //       : tmp[i].positive_or_negative == 2
        //       ? i18n.$t("SalaryItem").PositiveOrNegative.Positive
        //       : i18n.$t("SalaryItem").PositiveOrNegative.UnKnow;

        //   tmp[i].taxed =
        //     tmp[i].taxed == 1
        //       ? i18n.$t("SalaryItem").Taxed.Taxed
        //       : tmp[i].taxed == 2
        //       ? i18n.$t("SalaryItem").Taxed.NoTaxed
        //       : i18n.$t("SalaryItem").Taxed.UnKnow;
        // }
        data.excel_factory.data = tmp;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    })
    .then(() => {
      dt_factory.value.exportCSV();
    });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.factorys.data"
      :loading="data.factoryLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.factoryFilters"
      v-model:selection="data.selectedFactorys"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Factory").FactoryTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.factoryFilters[
                        i18n.$t('Factory').FactoryTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Factory').FactoryTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.factoryFilters[
                        i18n.$t('Factory').FactoryTable.Name.Field
                      ].value
                    "
                    :placeholder="i18n.$t('Factory').FactoryTable.Name.Show"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Employer').ExportExcelEmployer"
              icon="pi pi-book"
              class="p-button-secondary p-mr-2"
              @click="ExportExcel"
            />
            <Button
              :label="i18n.$t('Factory').FactoryTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addFactory"
            />
            <Button
              :label="i18n.$t('Factory').FactoryTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedFactorys || !data.selectedFactorys.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('Factory').FactoryTable.ID.Field"
        :header="i18n.$t('Factory').FactoryTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Factory').FactoryTable.Name.Field"
        :header="i18n.$t('Factory').FactoryTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Factory').FactoryTable.VatNumber.Field"
        :header="i18n.$t('Factory').FactoryTable.VatNumber.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Factory').FactoryTable.Bank.Field"
        :header="i18n.$t('Factory').FactoryTable.Bank.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Factory').FactoryTable.BranchCode.Field"
        :header="i18n.$t('Factory').FactoryTable.BranchCode.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Factory').FactoryTable.AccountNumber.Field"
        :header="i18n.$t('Factory').FactoryTable.AccountNumber.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Factory').FactoryTable.Description.Field"
        :header="i18n.$t('Factory').FactoryTable.Description.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Factory').FactoryTable.Remark.Field"
        :header="i18n.$t('Factory').FactoryTable.Remark.Show"
      ></Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editFactory(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('Factory').AddFactory.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Factory").AddFactory.Name }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_factory.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="vat_number">{{
          i18n.$t("Factory").FactoryTable.VatNumber.Show
        }}</label>
        <InputText
          id="vat_number"
          v-model.trim="data.add_factory.vat_number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="bank">{{
          i18n.$t("Factory").FactoryTable.Bank.Show
        }}</label>
        <InputText
          id="bank"
          v-model.trim="data.add_factory.bank"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="branch_code">{{
          i18n.$t("Factory").FactoryTable.BranchCode.Show
        }}</label>
        <InputText
          id="branch_code"
          v-model.trim="data.add_factory.branch_code"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="account_number">{{
          i18n.$t("Factory").FactoryTable.AccountNumber.Show
        }}</label>
        <InputText
          id="account_number"
          v-model.trim="data.add_factory.account_number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Factory").FactoryTable.Remark.Show
        }}</label>
        <Textarea
          id="remark"
          v-model="data.add_factory.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Factory").AddFactory.Description
        }}</label>
        <Textarea
          id="description"
          v-model="data.add_factory.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Factory').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('Factory').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddFactory"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Factory').EditFactory.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{ i18n.$t("Factory").EditFactory.Name }}</label>
        <InputText id="name" v-model.trim="data.factory.name" required="true" />
      </div>
      <div class="p-field">
        <label for="vat_number">{{
          i18n.$t("Factory").FactoryTable.VatNumber.Show
        }}</label>
        <InputText
          id="vat_number"
          v-model.trim="data.factory.vat_number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="bank">{{
          i18n.$t("Factory").FactoryTable.Bank.Show
        }}</label>
        <InputText id="bank" v-model.trim="data.factory.bank" required="true" />
      </div>
      <div class="p-field">
        <label for="branch_code">{{
          i18n.$t("Factory").FactoryTable.BranchCode.Show
        }}</label>
        <InputText
          id="branch_code"
          v-model.trim="data.factory.branch_code"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="account_number">{{
          i18n.$t("Factory").FactoryTable.AccountNumber.Show
        }}</label>
        <InputText
          id="account_number"
          v-model.trim="data.factory.account_number"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Factory").FactoryTable.Remark.Show
        }}</label>
        <Textarea
          id="remark"
          v-model="data.factory.remark"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Factory").EditFactory.Description
        }}</label>
        <Textarea
          id="description"
          v-model="data.factory.description"
          required="true"
          rows="3"
          cols="20"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Factory').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('Factory').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditFactory"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Factory').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.factory"
          >{{ i18n.$t("Factory").Confirm.Content }}
          <b>{{ data.factory.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Factory').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('Factory').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteFactory"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('Factory').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("Factory").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('Factory').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('Factory').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedFactory"
        />
      </template>
    </Dialog>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel_factory.data"
    ref="dt_factory"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :field="i18n.$t('Factory').FactoryTable.ID.Field"
      :header="i18n.$t('Factory').FactoryTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Factory').FactoryTable.Name.Field"
      :header="i18n.$t('Factory').FactoryTable.Name.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Factory').FactoryTable.VatNumber.Field"
      :header="i18n.$t('Factory').FactoryTable.VatNumber.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Factory').FactoryTable.Bank.Field"
      :header="i18n.$t('Factory').FactoryTable.Bank.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Factory').FactoryTable.BranchCode.Field"
      :header="i18n.$t('Factory').FactoryTable.BranchCode.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Factory').FactoryTable.AccountNumber.Field"
      :header="i18n.$t('Factory').FactoryTable.AccountNumber.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Factory').FactoryTable.Description.Field"
      :header="i18n.$t('Factory').FactoryTable.Description.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('Factory').FactoryTable.Remark.Field"
      :header="i18n.$t('Factory').FactoryTable.Remark.Show"
    ></Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
