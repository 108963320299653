import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class CompanyAccount {
  getCompanyAccountInfo(id) {
    return axios.get(
      `/api/admin/company-account/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getCompanyAccountList(id, company_id, page, page_num) {
    return axios.get(
      `/api/admin/company-account/list`,
      {
        params: {
          id: id,
          company_id: company_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getCompanyAccountSelectList(company_id) {
    return axios.get(
      `/api/admin/company-account/select-list`,
      {
        params: {
          company_id: company_id,
        },
      },
      config
    );
  }
  batchCompanyAccount(datas) {
    return axios.post(
      `/api/admin/batch/company-account`,
      {
        datas: datas,
      },
      config
    );
  }
  addCompanyAccount(company_id, number, dollar) {
    return axios.post(
      `/api/admin/company-account`,
      {
        company_id: company_id,
        number: number,
        dollar: dollar,
      },
      config
    );
  }
  editCompanyAccount(id, company_id, number, dollar) {
    return axios.put(
      `/api/admin/company-account`,
      {
        id: id,
        company_id: company_id,
        number: number,
        dollar: dollar,
      },
      config
    );
  }
  deleteCompanyAccount(id) {
    return axios.delete(
      `/api/admin/company-account`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
