<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  ref,
  computed,
  watch,
} from "vue";
import router from "../../router";
import Toast from "primevue/toast";
import InputNumber from "primevue/inputNumber";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputText";
import Button from "primevue/button";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import Employer from "../../api/salary/self/employer";
import { dateFormate } from "../../tool/formate";
import Organisation from "../../api/salary/admin/organisation";
import ManagerHoliday from "../../api/salary/manager/holiday";
import AdminHoliday from "../../api/salary/admin/holiday";
import Dialog from "primevue/dialog";
import ManagerHolidayItem from "../../api/salary/manager/holiday_item";
import AdminHolidayItem from "../../api/salary/admin/holiday_item";
import PrintHoliday from "../../components/PrintHoliday.vue";
import { printHTML } from "../../tool/print";

import ManagerEmployer from "../../api/salary/manager/employer";
import AdminEmployer from "../../api/salary/admin/employer";
import ManagerEmployerDetail from "../../api/salary/manager/employer_detail";
import AdminEmployerDetail from "../../api/salary/admin/employer_detail";
import africa_logo from "../../assets/africa_logo.jpg";
const printDom = ref(null);
const i18n = useI18n();
const toast = useToast();
const store = useStore();
let userApi = new User();
let employerApi = new Employer();
let organisationAPI = new Organisation();
let manager_holidayAPI = new ManagerHoliday();
let admin_holidayAPI = new AdminHoliday();
let manager_holiday_itemAPI = new ManagerHolidayItem();
let admin_holiday_itemAPI = new AdminHolidayItem();

let manager_employerAPI = new ManagerEmployer();
let admin_employerAPI = new AdminEmployer();
let manager_employer_detailAPI = new ManagerEmployerDetail();
let admin_employer_detailAPI = new AdminEmployerDetail();

const dt = ref();
const data = reactive({
  HolidayItemSearch: "",
  selectHolidayItemList: [],
  OrganisationSearch: "",
  selectOrganisationList: [],
  StatusSearch: "",
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("ApplyBorrow").Reject,
    },
    {
      id: 2,
      name: i18n.$t("ApplyBorrow").Pass,
    },
    {
      id: 3,
      name: i18n.$t("ApplyBorrow").Doing,
    },
  ],
  holidays: { data: null },
  holiday: null,
  holidayLoading: false,
  page: 1,
  page_num: 10,
  totalRecords: 0,
  apply_days: 0,
  apply_date: [],
  date_search: "",
  employer: {
    organisation_name: "",
    name: "",
    english_name: "",
    position_name: "",
  },
  add_holiday: {
    reason: "",
  },
  audit_holidayFilters: {
    apply_employer_id: { value: "", matchMode: "contains" },
    apply_employer_name: { value: "", matchMode: "contains" },
    holiday_item_id: { value: "", matchMode: "contains" },
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
  },
  excel: { data: null },
});

const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  getEmployerInfo();
  getHolidayList();
  getHolidayItemSelectList();
  store.commit("set_logo", africa_logo);
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
  }
});
async function printSaveApplyHoliday(tmp_holiday, dom) {
  let holiday = {
    created_at: tmp_holiday.created_at,
    apply_days: 0,
    apply_start_date: "",
    apply_end_date: "",
    reason: "",
    apply_type: [],
  };
  let tmp_holidays = [tmp_holiday];
  for (let i = 0; i < tmp_holidays.length; i++) {
    holiday.apply_days = holiday.apply_days + tmp_holidays[i].apply_days;
    if (i == 0) {
      holiday.apply_start_date = tmp_holidays[i].apply_start_date;
      holiday.reason = tmp_holidays[i].reason;
    }
    if (i == tmp_holidays.length - 1) {
      holiday.apply_end_date = tmp_holidays[i].apply_end_date;
    }
    for (let j = 0; j < data.selectHolidayItemList.length; j++) {
      if (data.selectHolidayItemList[j].id == tmp_holidays[i].holiday_item_id) {
        holiday.apply_type.push(
          data.selectHolidayItemList[j].name +
            tmp_holidays[i].apply_days +
            " 天/Days"
        );
      }
    }
  }

  data.printDialog = true;

  // await store.commit("set_employer", data.employer);
  // await store.commit("set_employer_detail", data.employer_detail);
  await store.commit("set_holiday", holiday);
  switch (store.state.user.role) {
    case 1:
      admin_employerAPI
        .getEmployerInfo(tmp_holiday.apply_employer_id)
        .then(async (res) => {
          if (res.data.error_code == 0) {
            await store.commit("set_employer", res.data.data);
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          admin_employer_detailAPI
            .getEmployerDetailInfo(tmp_holiday.apply_employer_id)
            .then(async (res) => {
              if (res.data.error_code == 0) {
                await store.commit("set_employer_detail", res.data.data);
              } else {
                toast.add({
                  severity: "warn",
                  summary: "Warn Message",
                  detail: res.data.error_text,
                  life: 3000,
                });
              }
            })
            .then(() => {
              printHTML(dom);
            });
        });
      break;
    case 2:
      manager_employerAPI
        .getEmployerInfo(tmp_holiday.apply_employer_id)
        .then(async (res) => {
          if (res.data.error_code == 0) {
            await store.commit("set_employer", res.data.data);
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          manager_employer_detailAPI
            .getEmployerDetailInfo(tmp_holiday.apply_employer_id)
            .then(async (res) => {
              if (res.data.error_code == 0) {
                await store.commit("set_employer_detail", res.data.data);
              } else {
                toast.add({
                  severity: "warn",
                  summary: "Warn Message",
                  detail: res.data.error_text,
                  life: 3000,
                });
              }
            })
            .then(() => {
              printHTML(dom);
            });
        });

      break;
  }
  // printHTML(dom);
}
function Excel() {
  switch (store.state.user.role) {
    case 1:
      admin_holidayAPI
        .getHolidayList(
          data.audit_holidayFilters.created_at_begin.value,
          data.audit_holidayFilters.created_at_end.value,
          data.audit_holidayFilters.holiday_item_id.value,
          data.audit_holidayFilters.status.value,
          data.audit_holidayFilters.organisation_id.value,
          data.audit_holidayFilters.apply_employer_id.value,
          data.audit_holidayFilters.apply_employer_name.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
              tmp[i].apply_start_date = dateFormate(
                tmp[i].apply_start_date,
                "YYYY-MM-DD"
              );
              tmp[i].apply_end_date = dateFormate(
                tmp[i].apply_end_date,
                "YYYY-MM-DD"
              );
              tmp[i].status =
                tmp[i].status == 1
                  ? i18n.$t("ApplyBorrow").Reject
                  : tmp[i].status == 2
                  ? i18n.$t("ApplyBorrow").Pass
                  : tmp[i].status == 3
                  ? i18n.$t("ApplyBorrow").Doing
                  : i18n.$t("ApplyBorrow").UnKnow;
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });
      break;
    case 2:
      manager_holidayAPI
        .getHolidayList(
          data.audit_holidayFilters.created_at_begin.value,
          data.audit_holidayFilters.created_at_end.value,
          data.audit_holidayFilters.holiday_item_id.value,
          data.audit_holidayFilters.status.value,
          data.audit_holidayFilters.organisation_id.value,
          data.audit_holidayFilters.apply_employer_id.value,
          data.audit_holidayFilters.apply_employer_name.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].created_at = dateFormate(tmp[i].created_at, "YYYY-MM-DD");
              tmp[i].apply_start_date = dateFormate(
                tmp[i].apply_start_date,
                "YYYY-MM-DD"
              );
              tmp[i].apply_end_date = dateFormate(
                tmp[i].apply_end_date,
                "YYYY-MM-DD"
              );
              tmp[i].status =
                tmp[i].status == 1
                  ? i18n.$t("ApplyBorrow").Reject
                  : tmp[i].status == 2
                  ? i18n.$t("ApplyBorrow").Pass
                  : tmp[i].status == 3
                  ? i18n.$t("ApplyBorrow").Doing
                  : i18n.$t("ApplyBorrow").UnKnow;
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });
      break;
  }
}
function SetHoliday(id, status) {
  switch (store.state.user.role) {
    case 1:
      admin_holidayAPI.editHolidayStatus(id, status).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("AuditBorrow").Success,
            life: 3000,
          });
          data.holidayLoading = true;
          getHolidayList();
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_holidayAPI.editHolidayStatus(id, status).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("AuditBorrow").Success,
            life: 3000,
          });
          data.holidayLoading = true;
          getHolidayList();
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
function getHolidayList() {
  switch (store.state.user.role) {
    case 1:
      admin_holidayAPI
        .getHolidayList(
          data.audit_holidayFilters.created_at_begin.value,
          data.audit_holidayFilters.created_at_end.value,
          data.audit_holidayFilters.holiday_item_id.value,
          data.audit_holidayFilters.status.value,
          data.audit_holidayFilters.organisation_id.value,
          data.audit_holidayFilters.apply_employer_id.value,
          data.audit_holidayFilters.apply_employer_name.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.holidays.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.holidays.data = [];
              // data.page = 0;
            }
          }
          data.holidayLoading = false;
        });
      break;
    case 2:
      manager_holidayAPI
        .getHolidayList(
          data.audit_holidayFilters.created_at_begin.value,
          data.audit_holidayFilters.created_at_end.value,
          data.audit_holidayFilters.holiday_item_id.value,
          data.audit_holidayFilters.status.value,
          data.audit_holidayFilters.organisation_id.value,
          data.audit_holidayFilters.apply_employer_id.value,
          data.audit_holidayFilters.apply_employer_name.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.holidays.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.holidays.data = [];
              // data.page = 0;
            }
          }
          data.holidayLoading = false;
        });
      break;
  }
}

function getHolidayItemSelectList() {
  switch (store.state.user.role) {
    case 1:
      admin_holiday_itemAPI.getHolidayItemSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectHolidayItemList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_holiday_itemAPI.getHolidayItemSelectList("").then((res) => {
        if (res.data.error_code == 0) {
          data.selectHolidayItemList = res.data.data.datas;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}

function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getEmployerInfo() {
  employerApi.getEmployerInfo().then((res) => {
    if (res.data.error_code == 0) {
      data.employer = res.data.data;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.holidayLoading = true;
    getHolidayList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.holidayLoading = true;
    if (data.StatusSearch != null) {
      data.audit_holidayFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.audit_holidayFilters.status.value = "";
    }
    getHolidayList();
  }
);
watch(
  () => data.HolidayItemSearch,
  () => {
    data.holidayLoading = true;
    if (data.HolidayItemSearch != null) {
      data.audit_holidayFilters.holiday_item_id.value =
        data.HolidayItemSearch == null ? "" : data.HolidayItemSearch.id;
    } else {
      data.audit_holidayFilters.holiday_item_id.value = "";
    }
    getHolidayList();
  }
);
watch(
  () => data.audit_holidayFilters.apply_employer_id.value,
  () => {
    data.holidayLoading = true;
    getHolidayList();
  }
);
watch(
  () => data.audit_holidayFilters.apply_employer_name.value,
  () => {
    data.holidayLoading = true;
    getHolidayList();
  }
);
watch(
  () => data.date_search,
  () => {
    data.holidayLoading = true;
    if (data.date_search != null) {
      data.audit_holidayFilters.created_at_begin.value = new Date(
        data.date_search.getFullYear(),
        data.date_search.getMonth(),
        1
      ).toISOString();
      data.audit_holidayFilters.created_at_end.value = new Date(
        data.date_search.getFullYear(),
        data.date_search.getMonth() + 1,
        1
      ).toISOString();
    } else {
      data.audit_holidayFilters.created_at_begin.value = "";
      data.audit_holidayFilters.created_at_end.value = "";
    }
    getHolidayList();
  }
);
watch(
  () => data.OrganisationSearch,
  () => {
    data.holidayLoading = true;
    data.audit_holidayFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getHolidayList();
  }
);

function editApplyHoliday(tmp) {
  data.holiday = tmp;
  data.apply_date[0] = new Date(data.holiday.apply_start_date);
  data.apply_date[1] = new Date(data.holiday.apply_end_date);
  data.editDialog = true;
}

watch(
  () => data.apply_date,
  () => {
    if (data.apply_date != null) {
      if (data.apply_date[0] != null && data.apply_date[1] != null) {
        var difference = Math.abs(data.apply_date[1] - data.apply_date[0]);
        let days = difference / (1000 * 3600 * 24) + 1;
        data.holiday.apply_days = days;
        data.holiday.apply_start_date = data.apply_date[0].toISOString();
        data.holiday.apply_end_date = data.apply_date[1].toISOString();
        console.log(data.holiday);
      }
    } else {
      data.holiday.apply_days = 0;
    }
  }
);

function SaveEditApplyHoliday() {
  admin_holidayAPI
    .editHolidayDays(
      data.holiday.id,
      data.holiday.apply_days,
      data.holiday.apply_start_date,
      data.holiday.apply_end_date
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("AuditBorrow").Success,
          life: 3000,
        });
        data.editDialog = false;
        data.holidayLoading = true;
        getHolidayList();
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-grid">
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("AuditHoliday").Employer }}
        </div>
        <div class="p-col-12">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.holidays.data"
            :loading="data.holidayLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 255px)"
            style="min-width: 375px; margin-top: -5px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.holidayFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    class="p-mr-1"
                    v-model="data.date_search"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.audit_holidayFilters[
                        i18n.$t('AuditBorrow').AuditBorrowTable.ApplyEmployerID
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AuditBorrow').AuditBorrowTable.ApplyEmployerID
                        .Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.audit_holidayFilters[
                        i18n.$t('AuditBorrow').AuditBorrowTable
                          .ApplyEmployerName.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AuditBorrow').AuditBorrowTable.ApplyEmployerName
                        .Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('AuditBorrow').AuditBorrowTable.Status.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span
                  class="p-input-icon-left p-ml-1"
                  v-if="store.state.user.role == 1"
                >
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.OrganisationSearch"
                    :options="data.selectOrganisationList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerTable.Organisation.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.HolidayItemSearch"
                    :options="data.selectHolidayItemList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('AuditHoliday').AuditHolidayTable.HolidayItemName
                        .Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </div>
              <div class="p-w-100 p-text-right">
                <Button
                  icon="pi pi-book"
                  class="p-button-info"
                  label="Excel"
                  @click="Excel"
                />
              </div>
            </template>
            <Column
              class="p-col-1"
              :header="i18n.$t('AuditHoliday').AuditHolidayTable.CreatedAt.Show"
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-1"
              :field="
                i18n.$t('AuditHoliday').AuditHolidayTable.ApplyEmployerName
                  .Field
              "
              :header="
                i18n.$t('AuditHoliday').AuditHolidayTable.ApplyEmployerName.Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('AuditHoliday').AuditHolidayTable.ApplyStartDate.Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.apply_start_date, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('AuditHoliday').AuditHolidayTable.ApplyEndDate.Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.apply_end_date, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('AuditHoliday').AuditHolidayTable.HolidayItemName.Field
              "
              :header="
                i18n.$t('AuditHoliday').AuditHolidayTable.HolidayItemName.Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyDays.Field"
              :header="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyDays.Show"
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('AuditHoliday').AuditHolidayTable.Reason.Field"
              :header="i18n.$t('AuditHoliday').AuditHolidayTable.Reason.Show"
            ></Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyHoliday').ApplyHolidayTable.AuditEmployerName.Show
              "
              :field="
                i18n.$t('ApplyHoliday').ApplyHolidayTable.AuditEmployerName
                  .Field
              "
            >
            </Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('AuditHoliday').AuditHolidayTable.Status.Show"
            >
              <template #body="slotProps">
                <span v-if="slotProps.data.status != 3">
                  {{
                    slotProps.data.status == 1
                      ? i18n.$t("ApplyBorrow").Reject
                      : slotProps.data.status == 2
                      ? i18n.$t("ApplyBorrow").Pass
                      : slotProps.data.status == 3
                      ? i18n.$t("ApplyBorrow").Doing
                      : i18n.$t("ApplyBorrow").UnKnow
                  }}
                </span>
                <div v-else>
                  <Button
                    icon="pi pi-check"
                    class="p-button-rounded p-button-success p-mr-2"
                    @click="SetHoliday(slotProps.data.id, 2)"
                  />
                  <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click="SetHoliday(slotProps.data.id, 1)"
                  />
                </div>
              </template>
            </Column>
            <Column class="p-col-2">
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-info p-mr-1 p-button-sm"
                  @click="editApplyHoliday(slotProps.data)"
                />
                <Button
                  icon="pi pi-print"
                  class="p-button-rounded p-button-success p-button-sm"
                  @click="printSaveApplyHoliday(slotProps.data, printDom)"
                />
                <Button
                  v-if="slotProps.data.status != 3"
                  icon="pi pi-replay"
                  class="p-button-rounded p-button-danger p-button-sm p-ml-1"
                  @click="SetHoliday(slotProps.data.id, 3)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <!-- 詳細視窗 -->
  <Dialog
    v-model:visible="data.editDialog"
    :style="{ width: '450px' }"
    :header="i18n.$t('ApplyHoliday').EditApplyHoliday.Title"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-field">
      <label for="name">{{
        i18n.$t("ApplyHoliday").ApplyHolidayTable.ApplyDays.Show
      }}</label>
      <InputNumber
        class="p-inputtext-sm"
        inputId="minmax-buttons"
        v-model="data.holiday.apply_days"
        mode="decimal"
        :disabled="true"
        :min="0"
        :max="100"
      />
    </div>
    <div class="p-field">
      <label for="name">{{ i18n.$t("ApplyHoliday").ApplyDate }}</label>
      <Calendar
        id="range"
        v-model="data.apply_date"
        selectionMode="range"
        :manualInput="false"
        :showIcon="true"
        :placeholder="i18n.$t('ApplyHoliday').ApplyDate"
        :showButtonBar="true"
      />
    </div>
    <!-- <div class="p-field">
      <label for="name">{{
        i18n.$t("ApplyHoliday").ApplyHolidayTable.ApplyEndDate.Show
      }}</label>
    </div> -->

    <template #footer>
      <Button
        :label="i18n.$t('ApplyHoliday').Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.editDialog = false"
      />
      <Button
        :label="i18n.$t('ApplyHoliday').Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="SaveEditApplyHoliday"
      />
    </template>
  </Dialog>
  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :header="i18n.$t('AuditHoliday').AuditHolidayTable.CreatedAt.Show"
      :field="i18n.$t('AuditHoliday').AuditHolidayTable.CreatedAt.Field"
    >
    </Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyEmployerName.Field"
      :header="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyEmployerName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyStartDate.Show"
      :field="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyStartDate.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyEndDate.Show"
      :field="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyEndDate.Field"
    >
    </Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('AuditHoliday').AuditHolidayTable.HolidayItemName.Field"
      :header="i18n.$t('AuditHoliday').AuditHolidayTable.HolidayItemName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyDays.Field"
      :header="i18n.$t('AuditHoliday').AuditHolidayTable.ApplyDays.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('AuditHoliday').AuditHolidayTable.Reason.Field"
      :header="i18n.$t('AuditHoliday').AuditHolidayTable.Reason.Show"
    ></Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('AuditHoliday').AuditHolidayTable.Status.Show"
      :field="i18n.$t('AuditHoliday').AuditHolidayTable.Status.Field"
    >
    </Column>
  </DataTable>
  <div class="p-d-none">
    <div ref="printDom">
      <PrintHoliday />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
