<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onUpdated,
  toRefs,
  computed,
  watch,
} from "vue";
import { dateFormate } from "../tool/formate";
import { useStore } from "vuex";
const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  department: [],
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div
      style="
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div style="width: 25%; text-align: left">
        <img style="height: 80px; width: 80px" :src="store.state.logo" />
      </div>
      <div style="width: 75%; text-align: center; margin-left: -10%">
        <h2>
          {{
            store.state.payment == null ? "" : store.state.payment.company_name
          }}
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;請購單 / PURCHASE REQUEST
          FORM
        </h2>
      </div>
    </div>
    <div
      style="
        border-bottom: 1px solid black;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div style="width: 49%; text-align: left">
        &nbsp; 申請日期(Date): &nbsp;
        {{
          store.state.payment == null
            ? ""
            : dateFormate(store.state.payment.created_at, "YYYY-MM-DD")
        }}
      </div>
      <div style="width: 49%; text-align: left">
        &nbsp;請購單號(No): &nbsp;
        {{ store.state.payment == null ? "" : store.state.payment.id }}
      </div>
    </div>
    <div style="border: 1px solid black; width: 100%">
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: center;
        "
      >
        <h4 style="width: 100%">
          <div v-if="store.state.payment != null">
            <span
              :key="index"
              v-for="(name, index) in store.state.payment.department"
            >
              {{ name }}
            </span>
          </div>
        </h4>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 12%; text-align: center"
        >
          &nbsp; 項次 &nbsp;<br />&nbsp; Item &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; 品名 &nbsp;<br />&nbsp; Description &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 17%; text-align: center"
        >
          &nbsp; 規格 &nbsp;<br />&nbsp; Specification &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 12%; text-align: center"
        >
          &nbsp; 數量 &nbsp;<br />&nbsp; Qty &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 12%; text-align: center"
        >
          &nbsp; 單位 &nbsp;<br />&nbsp; Unit &nbsp;
        </div>
        <div style="width: 18%; text-align: center">
          &nbsp; 備註 &nbsp;<br />&nbsp; Remarks &nbsp;
        </div>
      </div>
      <div
        :key="index"
        v-for="(content, index) in store.state.payment_content"
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 12%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ index + 1 }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.payment_item_chinese_name }}({{
            content.payment_item_english_name
          }})
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 17%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.spec }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 12%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.total_count }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 12%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.calculation_unit }}
        </div>
        <div
          style="
            width: 18%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.remark }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 49%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp;請購原因/Purpose of Request &nbsp;
        </div>
        <div
          style="
            width: 49%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          &nbsp; 備註/Remarks &nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 49%;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
          "
        >
          &nbsp;{{
            store.state.payment != null ? store.state.payment.description : ""
          }}&nbsp;
        </div>
        <div
          style="
            width: 49%;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
          "
        >
          &nbsp;{{
            store.state.payment != null ? store.state.payment.remark : ""
          }}&nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            width: 77.5%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-bottom: 1px solid black;
              width: 100%;
              height: 80px;
              text-align: center;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              font-size: 12px;
            "
          >
            <div style="width: 28%; text-align: center">
              產區總經理<br />Eswatini Managing Director<br />
            </div>
            <div style="width: 24%; text-align: center">
              事業部主管<br />Chief of Department<br />
            </div>
            <div style="width: 24%; text-align: center">
              單位主管<br />Manager<br />
              {{
                store.state.payment != null
                  ? store.state.payment.audit_employer_name
                  : ""
              }}
            </div>
            <div style="width: 22%; text-align: center">
              申請人<br />Requested by<br />
              {{
                store.state.payment != null
                  ? store.state.payment.apply_employer_name
                  : ""
              }}
            </div>
          </div>
          <div
            style="
              width: 100%;
              height: 80px;
              text-align: left;
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              font-size: 12px;
            "
          >
            <div style="width: 33%; text-align: center">
              管理部主管<br />Chief of Admin Department<br />
            </div>
            <div style="width: 33%; text-align: center">
              單位主管<br />Manager by<br />
            </div>
            <div style="width: 33%; text-align: center">
              承辦人<br />Processed<br />
            </div>
          </div>
        </div>
        <div
          style="
            border-left: 1px solid black;
            width: 22%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-bottom: 1px solid black;
              width: 100%;
              height: 80px;
              text-align: center;
              align-items: center;
            "
          >
            <span>
              申請單位<br />
              Application
            </span>
          </div>
          <div
            style="
              width: 100%;
              height: 80px;
              text-align: center;
              align-items: center;
            "
          >
            <span>
              承辦單位<br />
              Undertake
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
