<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import Employer from "../../components/Employer.vue";
import africa_logo from "../../assets/africa_logo.jpg";
const toast = useToast();
const store = useStore();
const data = reactive({});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  store.commit("set_employer_search", "salary_manager");
  store.commit("set_logo", africa_logo);
});
</script>

<template>
  <Employer />
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
