<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  ref,
  watch,
} from "vue";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import SalaryItem from "../../api/salary/admin/salary_item";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";

let userApi = new User();
let salary_itemAPI = new SalaryItem();
const store = useStore();
const dt_salary_item = ref();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  excel_salary_item: { data: null },
  selectPositiveOrNegativeList: [
    {
      id: 1,
      name: i18n.$t("SalaryItem").PositiveOrNegative.Negative,
    },
    {
      id: 2,
      name: i18n.$t("SalaryItem").PositiveOrNegative.Positive,
    },
  ],
  PositiveOrNegativeAddSearch: "",
  PositiveOrNegativeEditSearch: "",
  selectTaxedList: [
    {
      id: 1,
      name: i18n.$t("SalaryItem").Taxed.Taxed,
    },
    {
      id: 2,
      name: i18n.$t("SalaryItem").Taxed.NoTaxed,
    },
  ],
  TaxedAddSearch: "",
  TaxedEditSearch: "",
  add_salary_item: {
    id: "",
    name: "",
    category: "",
    taxed: "",
    positive_or_negative: "",
  },
  salary_itemLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  salary_items: { data: null },
  salary_item: {
    id: "",
    name: "",
    category: "",
    taxed: "",
    positive_or_negative: "",
  },
  page: 1,
  page_num: 10,
  selectedSalaryItems: null,
  salary_itemFilters: {
    id: { value: "", matchMode: "contains" },
    name: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getSalaryItemList();
  }
});

function getSalaryItemList() {
  CheckUser();
  salary_itemAPI
    .getSalaryItemList(
      data.salary_itemFilters.id.value,
      data.salary_itemFilters.name.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.salary_items.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.salary_itemLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.salary_itemLoading = false;
      }
    });
}

function saveEditSalaryItem() {
  salary_itemAPI
    .editSalaryItem(
      data.salary_item.id,
      data.salary_item.name,
      1,
      data.salary_item.taxed,
      data.salary_item.positive_or_negative
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("SalaryItem").EditSuccess,
          life: 3000,
        });

        data.salary_itemLoading = true;
        getSalaryItemList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddSalaryItem() {
  salary_itemAPI
    .addSalaryItem(
      data.add_salary_item.name,
      1,
      data.add_salary_item.taxed,
      data.add_salary_item.positive_or_negative
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("SalaryItem").AddSuccess,
          life: 3000,
        });

        data.salary_itemLoading = true;
        getSalaryItemList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function addSalaryItem() {
  data.addDialog = true;
  data.TaxedAddSearch = "";
  data.PositiveOrNegativeAddSearch = "";
}
function editSalaryItem(salary_item) {
  data.salary_item = { ...salary_item };
  data.TaxedEditSearch = "";
  data.PositiveOrNegativeEditSearch = "";
  for (let i = 0; i < data.selectPositiveOrNegativeList.length; i++) {
    if (
      data.salary_item.positive_or_negative ==
      data.selectPositiveOrNegativeList[i].id
    ) {
      data.PositiveOrNegativeEditSearch = data.selectPositiveOrNegativeList[i];
    }
  }
  for (let i = 0; i < data.selectTaxedList.length; i++) {
    if (data.salary_item.taxed == data.selectTaxedList[i].id) {
      data.TaxedEditSearch = data.selectTaxedList[i];
    }
  }
  data.editDialog = true;
}
function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(salary_item) {
  data.salary_item = salary_item;
  data.deleteDialog = true;
}
function deleteSalaryItem() {
  salary_itemAPI.deleteSalaryItem([data.salary_item.id]).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("SalaryItem").DeleteSuccess,
        life: 3000,
      });

      data.salary_itemLoading = true;
      getSalaryItemList();
      data.deleteDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function deleteSelectedSalaryItem() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedSalaryItems.length; i++) {
    selectDelete.push(data.selectedSalaryItems[i].id);
  }
  salary_itemAPI.deleteSalaryItem(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("SalaryItem").DeleteSuccess,
        life: 3000,
      });

      data.salary_itemLoading = true;
      data.selectedSalaryItems = [];
      getSalaryItemList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.salary_itemLoading = true;
    getSalaryItemList();
  }
);
watch(
  () => data.salary_itemFilters.name.value,
  () => {
    data.salary_itemLoading = true;
    getSalaryItemList();
  }
);
watch(
  () => data.salary_itemFilters.id.value,
  () => {
    data.salary_itemLoading = true;
    getSalaryItemList();
  }
);
watch(
  () => data.TaxedAddSearch,
  () => {
    data.add_salary_item.taxed =
      data.TaxedAddSearch == null ? "" : data.TaxedAddSearch.id;
  }
);
watch(
  () => data.TaxedEditSearch,
  () => {
    data.salary_item.taxed =
      data.TaxedEditSearch == null ? "" : data.TaxedEditSearch.id;
  }
);
watch(
  () => data.PositiveOrNegativeAddSearch,
  () => {
    data.add_salary_item.positive_or_negative =
      data.PositiveOrNegativeAddSearch == null
        ? ""
        : data.PositiveOrNegativeAddSearch.id;
  }
);
watch(
  () => data.PositiveOrNegativeEditSearch,
  () => {
    data.salary_item.positive_or_negative =
      data.PositiveOrNegativeEditSearch == null
        ? ""
        : data.PositiveOrNegativeEditSearch.id;
  }
);
function ExportExcel() {
  salary_itemAPI
    .getSalaryItemList(
      data.salary_itemFilters.id.value,
      data.salary_itemFilters.name.value,
      1,
      999
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        // data.selectSalaryItemList = res.data.data.datas;
        let tmp = res.data.data.datas;
        for (let i = 0; i < tmp.length; i++) {
          tmp[i].positive_or_negative =
            tmp[i].positive_or_negative == 1
              ? i18n.$t("SalaryItem").PositiveOrNegative.Negative
              : tmp[i].positive_or_negative == 2
              ? i18n.$t("SalaryItem").PositiveOrNegative.Positive
              : i18n.$t("SalaryItem").PositiveOrNegative.UnKnow;

          tmp[i].taxed =
            tmp[i].taxed == 1
              ? i18n.$t("SalaryItem").Taxed.Taxed
              : tmp[i].taxed == 2
              ? i18n.$t("SalaryItem").Taxed.NoTaxed
              : i18n.$t("SalaryItem").Taxed.UnKnow;
        }
        data.excel_salary_item.data = tmp;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    })
    .then(() => {
      dt_salary_item.value.exportCSV();
    });
}
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.salary_items.data"
      :loading="data.salary_itemLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.salary_itemFilters"
      v-model:selection="data.selectedSalaryItems"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("SalaryItem").SalaryItemTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.salary_itemFilters[
                        i18n.$t('SalaryItem').SalaryItemTable.ID.Field
                      ].value
                    "
                    :placeholder="i18n.$t('SalaryItem').SalaryItemTable.ID.Show"
                  />
                </span>
                <span class="p-input-icon-left p-ml-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.salary_itemFilters[
                        i18n.$t('SalaryItem').SalaryItemTable.Name.Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('SalaryItem').SalaryItemTable.Name.Show
                    "
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('Employer').ExportExcelEmployer"
              icon="pi pi-book"
              class="p-button-secondary p-mr-2"
              @click="ExportExcel"
            />
            <Button
              :label="i18n.$t('SalaryItem').SalaryItemTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addSalaryItem"
            />
            <Button
              :label="i18n.$t('SalaryItem').SalaryItemTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedSalaryItems || !data.selectedSalaryItems.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('SalaryItem').SalaryItemTable.ID.Field"
        :header="i18n.$t('SalaryItem').SalaryItemTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('SalaryItem').SalaryItemTable.Name.Field"
        :header="i18n.$t('SalaryItem').SalaryItemTable.Name.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('SalaryItem').SalaryItemTable.Taxed.Field"
        :header="i18n.$t('SalaryItem').SalaryItemTable.Taxed.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.taxed == 1
              ? i18n.$t("SalaryItem").Taxed.Taxed
              : slotProps.data.taxed == 2
              ? i18n.$t("SalaryItem").Taxed.NoTaxed
              : i18n.$t("SalaryItem").Taxed.UnKnow
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('SalaryItem').SalaryItemTable.PositiveOrNegative.Field"
        :header="i18n.$t('SalaryItem').SalaryItemTable.PositiveOrNegative.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.positive_or_negative == 1
              ? i18n.$t("SalaryItem").PositiveOrNegative.Negative
              : slotProps.data.positive_or_negative == 2
              ? i18n.$t("SalaryItem").PositiveOrNegative.Positive
              : i18n.$t("SalaryItem").PositiveOrNegative.UnKnow
          }}
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editSalaryItem(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('SalaryItem').AddSalaryItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SalaryItem").SalaryItemTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.add_salary_item.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SalaryItem").SalaryItemTable.Taxed.Show
        }}</label>
        <Dropdown
          v-model="data.TaxedAddSearch"
          :options="data.selectTaxedList"
          optionLabel="name"
          :placeholder="i18n.$t('SalaryItem').SalaryItemTable.Taxed.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SalaryItem").SalaryItemTable.PositiveOrNegative.Show
        }}</label>
        <Dropdown
          v-model="data.PositiveOrNegativeAddSearch"
          :options="data.selectPositiveOrNegativeList"
          optionLabel="name"
          :placeholder="
            i18n.$t('SalaryItem').SalaryItemTable.PositiveOrNegative.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SalaryItem').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('SalaryItem').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddSalaryItem"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SalaryItem').EditSalaryItem.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SalaryItem").SalaryItemTable.Name.Show
        }}</label>
        <InputText
          id="name"
          v-model.trim="data.salary_item.name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SalaryItem").SalaryItemTable.Taxed.Show
        }}</label>
        <Dropdown
          v-model="data.TaxedEditSearch"
          :options="data.selectTaxedList"
          optionLabel="name"
          :placeholder="i18n.$t('SalaryItem').SalaryItemTable.Taxed.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SalaryItem").SalaryItemTable.PositiveOrNegative.Show
        }}</label>
        <Dropdown
          v-model="data.PositiveOrNegativeEditSearch"
          :options="data.selectPositiveOrNegativeList"
          optionLabel="name"
          :placeholder="
            i18n.$t('SalaryItem').SalaryItemTable.PositiveOrNegative.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SalaryItem').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('SalaryItem').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditSalaryItem"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SalaryItem').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.salary_item"
          >{{ i18n.$t("SalaryItem").Confirm.Content }}
          <b>{{ data.salary_item.name }}</b
          >?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SalaryItem').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('SalaryItem').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSalaryItem"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SalaryItem').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("SalaryItem").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SalaryItem').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('SalaryItem').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedSalaryItem"
        />
      </template>
    </Dialog>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel_salary_item.data"
    ref="dt_salary_item"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :field="i18n.$t('SalaryItem').SalaryItemTable.ID.Field"
      :header="i18n.$t('SalaryItem').SalaryItemTable.ID.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('SalaryItem').SalaryItemTable.Name.Field"
      :header="i18n.$t('SalaryItem').SalaryItemTable.Name.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('SalaryItem').SalaryItemTable.Taxed.Field"
      :header="i18n.$t('SalaryItem').SalaryItemTable.Taxed.Show"
    >
    </Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('SalaryItem').SalaryItemTable.PositiveOrNegative.Field"
      :header="i18n.$t('SalaryItem').SalaryItemTable.PositiveOrNegative.Show"
    >
    </Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
