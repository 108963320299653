import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Bouns {
  getBounsInfo(id) {
    return axios.get(
      `/api/admin/bonus/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getEasyBounsList(apply_date_begin, apply_date_end, page, page_num) {
    return axios.get(
      `/api/admin/bonus/list`,
      {
        params: {
          apply_date_begin: apply_date_begin,
          apply_date_end: apply_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getBounsList(
    apply_employer_id,
    apply_employer_name,
    status,
    apply_date_begin,
    apply_date_end,
    organisation_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/bonus/list`,
      {
        params: {
          apply_employer_id: apply_employer_id,
          apply_employer_name: apply_employer_name,
          apply_date_begin: apply_date_begin,
          apply_date_end: apply_date_end,
          status: status,
          organisation_id: organisation_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchBouns(datas) {
    return axios.post(
      `/api/admin/bonus/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addBouns(
    dollar,
    currency_id,
    reason,
    status,
    audit_employer_id,
    apply_employer_id,
    organisation_id
  ) {
    return axios.post(
      `/api/admin/bonus/`,
      {
        dollar: dollar,
        currency_id: currency_id,
        reason: reason,
        status: status,
        audit_employer_id: audit_employer_id,
        apply_employer_id: apply_employer_id,
        organisation_id: organisation_id,
      },
      config
    );
  }
  editBouns(
    id,
    dollar,
    currency_id,
    reason,
    status,
    audit_employer_id,
    apply_employer_id,
    organisation_id
  ) {
    return axios.put(
      `/api/admin/bonus/`,
      {
        id: id,
        dollar: dollar,
        currency_id: currency_id,
        reason: reason,
        status: status,
        audit_employer_id: audit_employer_id,
        apply_employer_id: apply_employer_id,
        organisation_id: organisation_id,
      },
      config
    );
  }
  editBounsStatus(id, status, reason, dollar) {
    return axios.put(
      `/api/admin/bonus/`,
      {
        id: id,
        status: status,
        reason: reason,
        dollar: dollar,
      },
      config
    );
  }
  settlementBouns(salary_item_id, pay_date, settlement_of_month) {
    return axios.post(
      `/api/admin/bonus/settlement/`,
      {
        salary_item_id: salary_item_id,
        pay_date: pay_date,
        settlement_of_month: settlement_of_month,
      },
      config
    );
  }
  deleteBouns(id) {
    return axios.delete(
      `/api/admin/bonus/`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
