import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Borrow {
  getBorrowInfo(id) {
    return axios.get(
      `/api/self/borrow/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getBorrowList(apply_date_begin, apply_date_end, page, page_num) {
    return axios.get(
      `/api/self/borrow/list`,
      {
        params: {
          apply_date_begin: apply_date_begin,
          apply_date_end: apply_date_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchBorrow(datas) {
    return axios.post(
      `/api/self/batch/borrow`,
      {
        datas: datas,
      },
      config
    );
  }
  addBorrow(dollar, currency_id, apply_date, reason) {
    return axios.post(
      `/api/self/borrow`,
      {
        dollar: dollar,
        currency_id: currency_id,
        apply_date: apply_date,
        reason: reason,
      },
      config
    );
  }
}
