<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onUpdated,
  toRefs,
  computed,
  watch,
} from "vue";
import { dateFormate } from "../tool/formate";
import { useStore } from "vuex";
const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div
      style="
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div style="width: 25%; text-align: left">
        <img style="height: 80px; width: 80px" :src="store.state.logo" />
      </div>
      <div style="width: 75%; text-align: left">
        <h2>
          南緯集團史瓦帝尼產地區員工休假申請單
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Employees Leave Application
        </h2>
      </div>
    </div>
    <div style="border: 1px solid black; width: 100%">
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          &nbsp; 單位名稱 &nbsp;<br />&nbsp; Department &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 25%; text-align: center"
        >
          &nbsp;
          {{
            store.state.employer != null
              ? store.state.employer.organisation_name
              : ""
          }}
        </div>
        <div
          style="border-right: 1px solid black; width: 25%; text-align: center"
        >
          &nbsp; 申請日期 &nbsp;<br />&nbsp; Application Date &nbsp;
        </div>
        <div style="width: 27%; text-align: center">
          &nbsp;
          {{ dateFormate(store.state.holiday.created_at, "YYYY-MM-DD") }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          &nbsp; 姓名 &nbsp;<br />&nbsp; Name/Surname &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 25%; text-align: center"
        >
          &nbsp;{{
            store.state.employer != null
              ? store.state.employer.name +
                "/" +
                store.state.employer.english_name
              : ""
          }}
        </div>
        <div
          style="border-right: 1px solid black; width: 25%; text-align: center"
        >
          &nbsp; 職稱/職等 &nbsp;<br />&nbsp; Title &nbsp;
        </div>
        <div style="width: 27%; text-align: center">
          &nbsp;
          <span v-if="store.state.employer != null">
            <span
              v-if="
                store.state.employer.positions != null &&
                store.state.employer.positions != []
              "
            >
              <span
                :key="index"
                v-for="(item, index) in store.state.employer.positions"
              >
                {{ index == 0 ? item.name : "," + item.name }}
              </span>
            </span>
          </span>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          height: 50px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 22%;
            height: 50px;
            text-align: center;
          "
        >
          &nbsp; 到職日 &nbsp;<br />&nbsp; Date of Employ &nbsp;
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 25%;
            height: 50px;
            text-align: center;
          "
        >
          &nbsp;{{
            store.state.employer_detail != null
              ? dateFormate(
                  store.state.employer_detail.start_working,
                  "YYYY-MM-DD"
                )
              : ""
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 25%;
            height: 50px;
            text-align: center;
          "
        >
          &nbsp; 前次休假日期 &nbsp;<br />&nbsp; Date of previous leave &nbsp;
        </div>
        <div style="width: 27%; height: 40px; text-align: center">&nbsp;</div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 3%; text-align: center"
        >
          休假申請
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 19%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-right: 1px solid black;
              border-bottom: 1px solid black;
              width: 100%;
              height: 65px;
              text-align: center;
            "
          >
            日期<br />
            Period of leave
          </div>
          <div
            style="
              border-right: 1px solid black;
              width: 100%;
              height: 90px;
              text-align: center;
            "
          >
            假別<br />
            Type of leave
          </div>
        </div>
        <div
          style="
            width: 77.5%;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <div
            style="
              border-bottom: 1px solid black;
              width: 100%;
              height: 65px;
              text-align: center;
            "
          >
            <p>
              {{
                dateFormate(
                  store.state.holiday.apply_start_date,
                  "YYYY-MM-DD"
                ) +
                "~" +
                dateFormate(store.state.holiday.apply_end_date, "YYYY-MM-DD")
              }}
              <br />
              {{ "共" + store.state.holiday.apply_days + "天" }}
            </p>
          </div>
          <div
            style="
              width: 100%;
              height: 90px;
              text-align: left;
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
            "
          >
            <div
              style="width: 50%; height: 20px"
              :key="index"
              v-for="(item, index) in store.state.holiday.apply_type"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          &nbsp; 申請說明 &nbsp;<br />&nbsp; Reason of leave &nbsp;
        </div>
        <div style="width: 76%; text-align: center">
          &nbsp;
          {{ store.state.holiday.reason }}<br />
          <div style="text-align: left; width: 100%">
            備註 Note:<br />
            飛機票目的地Destination:_________員工簽名Employee signature:________
          </div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          &nbsp; 代理人簽章 &nbsp;<br />&nbsp; Deputy Signature &nbsp;
        </div>
        <div style="width: 76%; text-align: center">&nbsp;</div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: center;
        "
      >
        <h4 style="width: 100%">各級主管簽核</h4>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          &nbsp; 投管副董事長 &nbsp;<br />&nbsp; Investment Vice Chairman &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 25%; text-align: center"
        >
          &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 25%; text-align: center"
        >
          &nbsp; 產區總經理 &nbsp;<br />&nbsp; Eswatini Manager Director &nbsp;
        </div>
        <div style="width: 27%; text-align: center">&nbsp;</div>
      </div>
      <div
        style="
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            width: 22%;
            text-align: center;
          "
        >
          &nbsp; 事業部主管 &nbsp;<br />&nbsp; Chief of Department &nbsp;
        </div>
        <div
          style="
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            width: 15%;
            text-align: center;
          "
        >
          &nbsp;
        </div>
        <div
          style="
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            width: 14%;
            text-align: center;
          "
        >
          &nbsp; 單位主管 &nbsp;<br />&nbsp; Manager &nbsp;
        </div>
        <div
          style="
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            width: 15%;
            text-align: center;
          "
        >
          &nbsp;
        </div>
        <div
          style="
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            width: 17%;
            text-align: center;
          "
        >
          &nbsp; 管理部主管 &nbsp;<br />&nbsp; Chief of Admin Department&nbsp;
        </div>
        <div
          style="border-bottom: 1px solid black; width: 16%; text-align: center"
        >
          &nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          &nbsp; 會簽單位 &nbsp;<br />&nbsp; Associated Signature &nbsp;
        </div>
        <div style="width: 76%; text-align: center">&nbsp;</div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: center;
        "
      >
        <h4 style="width: 100%">票務資訊</h4>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          <p>&nbsp; 票務處理 &nbsp;<br />&nbsp; Air Ticket Info &nbsp;</p>
        </div>
        <div style="width: 76%; text-align: left">
          <p>
            &nbsp;口 需要代訂回台，大陸及其他地區機票 Air ticket booking
            required
            <br />
            &nbsp;口 不需要代訂回台，大陸及其他地區機票 No need for booking air
            ticket
          </p>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          &nbsp; 家鄉聯絡資訊 &nbsp;<br />&nbsp; Hometown contact Info &nbsp;
        </div>
        <div
          style="
            width: 76%;
            text-align: left;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          "
        >
          <br />

          <span style="width: 50%">&nbsp;手機Cel:</span>
          <span style="width: 50%">&nbsp;電話Tel:</span>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 22%; text-align: center"
        >
          &nbsp; 備註事項 &nbsp;<br />&nbsp; Note &nbsp;
        </div>
        <div style="width: 76%; text-align: center">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
