import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class PaymentItem {
  getPaymentItemInfo(id) {
    return axios.get(
      `/api/self/payment-item/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getPaymentItemList(
    id,
    chinese_name,
    english_name,
    status,
    taxed,
    page,
    page_num
  ) {
    return axios.get(
      `/api/self/payment-item/list`,
      {
        params: {
          id: id,
          chinese_name: chinese_name,
          english_name: english_name,
          status: status,
          taxed: taxed,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getPaymentItemSelectList() {
    return axios.get(
      `/api/self/payment-item/select-list`,
      {
        params: {},
      },
      config
    );
  }
}
