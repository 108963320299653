import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Holiday {
  getHolidayInfo(id) {
    return axios.get(
      `/api/admin/holiday/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getHolidayList(
    created_at_begin,
    created_at_end,
    holiday_item_id,
    status,
    organisation_id,
    apply_employer_id,
    apply_employer_name,
    page,
    page_num
  ) {
    return axios.get(
      `/api/admin/holiday/list`,
      {
        params: {
          created_at_begin: created_at_begin,
          created_at_end: created_at_end,
          holiday_item_id: holiday_item_id,
          status: status,
          organisation_id: organisation_id,
          apply_employer_id: apply_employer_id,
          apply_employer_name: apply_employer_name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  batchHoliday(datas) {
    return axios.post(
      `/api/admin/batch/holiday`,
      {
        datas: datas,
      },
      config
    );
  }
  addHoliday(
    apply_days,
    apply_start_date,
    apply_end_date,
    holiday_item_id,
    status,
    reason,
    audit_employer_id,
    apply_employer_id,
    organisation_id
  ) {
    return axios.post(
      `/api/admin/holiday`,
      {
        apply_days: apply_days,
        apply_start_date: apply_start_date,
        apply_end_date: apply_end_date,
        reason: reason,
        holiday_item_id: holiday_item_id,
        status: status,
        reason: reason,
        audit_employer_id: audit_employer_id,
        apply_employer_id: apply_employer_id,
        organisation_id: organisation_id,
      },
      config
    );
  }
  editHolidayDays(id, apply_days, apply_start_date, apply_end_date) {
    return axios.put(
      `/api/admin/holiday`,
      {
        id: id,
        apply_days: apply_days,
        apply_start_date: apply_start_date,
        apply_end_date: apply_end_date,
      },
      config
    );
  }
  editHoliday(
    id,
    apply_days,
    apply_start_date,
    apply_end_date,
    holiday_item_id,
    status,
    reason,
    audit_employer_id,
    apply_employer_id,
    organisation_id
  ) {
    return axios.put(
      `/api/admin/holiday`,
      {
        id: id,
        apply_days: apply_days,
        apply_start_date: apply_start_date,
        apply_end_date: apply_end_date,
        reason: reason,
        holiday_item_id: holiday_item_id,
        status: status,
        reason: reason,
        audit_employer_id: audit_employer_id,
        apply_employer_id: apply_employer_id,
        organisation_id: organisation_id,
      },
      config
    );
  }
  editHolidayStatus(id, status) {
    return axios.put(
      `/api/admin/holiday`,
      {
        id: id,
        status: status,
      },
      config
    );
  }
  deleteHoliday(id) {
    return axios.delete(
      `/api/admin/holiday`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
