<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Payment from "../../api/salary/admin/payment";
import Company from "../../api/salary/self/company";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import firebase from "firebase";
import router from "../../router";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
let companyAPI = new Company();
let userApi = new User();
let paymentAPI = new Payment();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  chart: false,
  contestData: {
    BarChart: {},
    BarChartContent: {},
    PieChart: {},
    PieChartContent: {},
  },
  selectCompanyList: [],
  CompanySearch: "",
  paymentLoading: false,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  payments: { data: null },
  paymentFilters: {
    company_id: { value: "", matchMode: "contains" },
    status: { value: 5, matchMode: "contains" },
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
    settlement_of_month_begin: { value: "", matchMode: "contains" },
    settlement_of_month_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  if (store.state.user == null) CheckUser();
});
const mounted = onMounted(async () => {
  data.paymentLoading = true;
  if (store.state.user != null) {
    getPaymentReport();
    getCompanySelectList();
  }
});

function getCompanySelectList() {
  companyAPI.getCompanySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCompanyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getPaymentReport() {
  if (store.state.user == null) CheckUser();
  data.chart = false;
  paymentAPI
    .getPaymentReport(
      data.paymentFilters.company_id.value,
      data.paymentFilters.status.value,
      data.paymentFilters.created_at_begin.value,
      data.paymentFilters.created_at_end.value,
      data.paymentFilters.settlement_of_month_begin.value,
      data.paymentFilters.settlement_of_month_end.value
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        //   account_types
        data.payments.data = res.data.data.account_types;
        data.paymentLoading = false;
        data.chart = true;
        let bar_label = [];
        let bar_dollar = [];
        let bar_dollar_total = [];
        let bar_back = [];
        let bar_content_label = [];
        let bar_content_dollar = [];
        let bar_content_dollar_total = [];
        let bar_content_back = [];

        for (let i = 0; i < res.data.data.account_types.length; i++) {
          bar_label.push(res.data.data.account_types[i].account_type_name);
          bar_dollar.push(res.data.data.account_types[i].TotalDollar);
          bar_dollar_total.push(res.data.data.total_dollar);

          bar_back.push(
            "rgba(" +
              Math.floor(Math.random() * 256) +
              "," +
              Math.floor(Math.random() * 256) +
              "," +
              Math.floor(Math.random() * 256) +
              ", 1)"
          );
          for (
            let j = 0;
            j < res.data.data.account_types[i].account_items.length;
            j++
          ) {
            bar_content_label.push(
              res.data.data.account_types[i].account_items[j].account_item_name
            );
            bar_content_dollar.push(
              res.data.data.account_types[i].account_items[j].total_dollar
            );
            bar_content_dollar_total.push(
              res.data.data.account_types[i].TotalDollar
            );
            bar_content_back.push(
              "rgba(" +
                Math.floor(Math.random() * 256) +
                "," +
                Math.floor(Math.random() * 256) +
                "," +
                Math.floor(Math.random() * 256) +
                ", 1)"
            );
          }
        }

        data.contestData.BarChart = {
          id: "bar",
          type: "bar",
          options: {
            min: 0,
            max: 100,
            responsive: true,
            plugins: {
              legend: {
                labels: {
                  color: "balck",
                },
                position: "top",
              },
            },
            scales: {
              x: {
                ticks: {
                  color: "balck",
                },
              },
              y: {
                min: 0,

                ticks: {
                  color: "balck",
                },
              },
            },
          },
          data: {
            labels: bar_label,
            datasets: [
              {
                label: "類別",
                backgroundColor: ["#F50002"],
                data: bar_dollar,
              },
              {
                label: "總金額",
                backgroundColor: ["#1abc9c"],
                data: bar_dollar_total,
              },
            ],
          },
        };
        data.contestData.BarChartContent = {
          id: "bar",
          type: "bar",
          options: {
            min: 0,
            max: 100,
            responsive: true,
            plugins: {
              legend: {
                labels: {
                  color: "balck",
                },
                position: "top",
              },
            },
            scales: {
              x: {
                ticks: {
                  color: "balck",
                },
              },
              y: {
                min: 0,

                ticks: {
                  color: "balck",
                },
              },
            },
          },
          data: {
            labels: bar_content_label,
            datasets: [
              {
                label: "類別",
                backgroundColor: ["#F50002"],
                data: bar_content_dollar,
              },
              {
                label: "總金額",
                backgroundColor: ["#1abc9c"],
                data: bar_content_dollar_total,
              },
            ],
          },
        };
        data.contestData.PieChart = {
          id: "pie",
          type: "pie",
          options: {
            min: 0,
            max: 100,
            responsive: true,
            plugins: {
              legend: {
                labels: {
                  color: "balck",
                },
                position: "bottom",
              },
            },
          },
          data: {
            labels: bar_label,
            datasets: [
              {
                backgroundColor: bar_back,
                data: bar_dollar,
              },
            ],
          },
        };
        data.contestData.PieChartContent = {
          id: "pie",
          type: "pie",
          options: {
            min: 0,
            max: 100,
            responsive: true,
            plugins: {
              legend: {
                labels: {
                  color: "balck",
                },
                position: "bottom",
              },
            },
          },
          data: {
            labels: bar_content_label,
            datasets: [
              {
                backgroundColor: bar_content_back,
                data: bar_content_dollar,
              },
            ],
          },
        };
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
        data.paymentLoading = false;
      }
    });
}
// watch(
//   () => data.paymentFilters.id.value,
//   () => {
//     data.paymentLoading = true;
//     getPaymentReport();
//   }
// );
watch(
  () => data.CompanySearch,
  () => {
    data.paymentFilters.company_id.value =
      data.CompanySearch == null ? "" : data.CompanySearch.id;
    data.paymentLoading = true;
    getPaymentReport();
  }
);
watch(
  () => data.settlement_of_month_date,
  () => {
    data.paymentLoading = true;
    if (data.settlement_of_month_date != null) {
      let date = new Date(data.settlement_of_month_date[0].toISOString());
      const GMT_8 = date.getHours() - (8 + date.getTimezoneOffset() / 60);
      date.setHours(GMT_8);
      data.paymentFilters.settlement_of_month_begin.value =
        data.settlement_of_month_date[0] != null ? date.toISOString() : "";

      if (
        data.settlement_of_month_date[1] != null &&
        data.settlement_of_month_date[1] != undefined
      ) {
        let tomorrow = new Date(data.settlement_of_month_date[1].toISOString());
        tomorrow.setDate(data.settlement_of_month_date[1].getDate() + 1);
        const GMT_8_tomorrow =
          tomorrow.getHours() - (8 + tomorrow.getTimezoneOffset() / 60);
        tomorrow.setHours(GMT_8_tomorrow);
        data.paymentFilters.settlement_of_month_end.value =
          tomorrow.toISOString();
      } else {
        data.paymentFilters.settlement_of_month_end.value = "";
      }
    } else {
      data.paymentFilters.settlement_of_month_begin.value = "";
      data.paymentFilters.settlement_of_month_end.value = "";
    }

    getPaymentReport();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <Accordion
      style="margin-top: 100px"
      class="accordion-custom p-col-12"
      :activeIndex="0"
    >
      <AccordionTab>
        <template #header>
          <i class="pi pi-search p-mr-1"></i>
          <span>{{ i18n.$t("Payment").PaymentTable.Search }}</span>
        </template>
        <p style="text-align: left">
          <span class="p-input-icon-left p-ml-2">
            <Dropdown
              v-model="data.CompanySearch"
              :options="data.selectCompanyList"
              optionLabel="name"
              :filter="true"
              :placeholder="i18n.$t('Payment').PaymentTable.Company.Show"
              :showClear="true"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <div>{{ slotProps.value.name }}</div>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <div class="country-item">
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </span>
        </p>
        <div class="p-col-12" style="text-align: left">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <Calendar
              id="range"
              v-model="data.settlement_of_month_date"
              selectionMode="range"
              :manualInput="false"
              :showIcon="true"
              :placeholder="
                i18n.$t('Payment').PaymentTable.SettlementOfMonth.Show
              "
              :showButtonBar="true"
            />
          </span>
        </div>
      </AccordionTab>
    </Accordion>
    <div class="p-grid">
      <!-- <div v-if="data.chart" class="p-col-6">
        <vue3-chart-js v-bind="{ ...data.contestData.LineChart }" />
      </div> -->
      <div v-if="data.chart" class="p-col-6">
        <vue3-chart-js v-bind="{ ...data.contestData.BarChart }" />
      </div>
      <div v-if="data.chart" class="p-col-6">
        <vue3-chart-js v-bind="{ ...data.contestData.BarChartContent }" />
      </div>
      <div v-if="data.chart" class="p-col-6">
        <vue3-chart-js v-bind="{ ...data.contestData.PieChart }" />
      </div>
      <div v-if="data.chart" class="p-col-6">
        <vue3-chart-js v-bind="{ ...data.contestData.PieChartContent }" />
      </div>
    </div>
    <!-- data.payments.data -->
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
