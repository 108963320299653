<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onUpdated,
  toRefs,
  computed,
  watch,
} from "vue";
import { thousands } from "../tool/thousands";
import * as math from "mathjs";
import { dateFormate } from "../tool/formate";
import { useStore } from "vuex";
const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  department: [],
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div
      style="
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div style="width: 25%; text-align: left">
        <img style="height: 80px; width: 80px" :src="store.state.logo" />
      </div>
      <div style="width: 75%; text-align: center; margin-left: -10%">
        <h2>
          {{
            store.state.payment == null ? "" : store.state.payment.company_name
          }}
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;請款單 / APPLICATION FORM
        </h2>
      </div>
      <div style="width: 100%; text-align: right; padding-right: 10px">
        系統單號：
        {{ store.state.payment == null ? "" : store.state.payment.id }}
      </div>
    </div>

    <div style="border: 1px solid black; width: 100%">
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          申請人(NAME)：
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment == null
              ? ""
              : store.state.payment.apply_employer_name
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          申請日期<br />(DATE APPLIED)：
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment == null
              ? ""
              : dateFormate(store.state.payment.created_at, "YYYY-MM-DD")
          }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          申請金額(AMOUNT)：
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment != null
              ? thousands(
                  math.floor(
                    math.bignumber(store.state.payment.total_dollar),
                    2
                  )
                )
              : "0.00"
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          需求日期<br />(DATE ACQUIRED)：
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment == null
              ? ""
              : dateFormate(store.state.payment.pay_date, "YYYY-MM-DD")
          }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          支付對象(PAYEE)：
        </div>
        <div
          style="
            width: 74%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          <div v-if="store.state.payment != null">
            <span
              :key="index"
              v-for="(name, index) in store.state.payment.factory_name"
            >
              {{ name }}
            </span>
          </div>
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          申請項目(Item)：
        </div>
        <div
          style="
            width: 74%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment != null
              ? store.state.payment.description +
                "/" +
                store.state.payment.remark
              : ""
          }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          支付方式(Type)：
        </div>
        <div
          style="
            width: 74%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment != null
              ? store.state.payment.payment_method == "現金" ||
                store.state.payment.payment_method == "Cash"
                ? "現金(Cash)"
                : store.state.payment.payment_method == "轉帳" ||
                  store.state.payment.payment_method == "Transfer"
                ? "轉帳(Transfer)"
                : store.state.payment.payment_method == "刷卡" ||
                  store.state.payment.payment_method == "Card"
                ? "刷卡(Card)"
                : ""
              : ""
          }}/
          {{
            store.state.payment != null ? store.state.payment.currency_name : ""
          }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          敘述(Description)：
        </div>
        <div
          style="
            width: 74%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        ></div>
      </div>

      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
          "
        >
          &nbsp; 申請品項 &nbsp;
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
          "
        >
          &nbsp; 規格 &nbsp;
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
          "
        >
          &nbsp; 部門 &nbsp;
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
          "
        >
          &nbsp; Amount &nbsp;
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
          "
        >
          &nbsp; VAT &nbsp;
        </div>
        <div style="width: 16.5%; text-align: center">&nbsp; Total &nbsp;</div>
      </div>
      <div
        :key="index"
        v-for="(content, index) in store.state.payment_content"
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.payment_item_chinese_name }}({{
            content.payment_item_english_name
          }})
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.spec }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.department_name }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ thousands(math.floor(math.bignumber(content.total_dollar), 2)) }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 16.5%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ thousands(math.floor(math.bignumber(content.vat), 2)) }}
        </div>
        <div
          style="
            width: 16.5%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            // content.payment_item_taxed == 1
            //   ? thousands(
            //       math.floor(
            //         math.add(
            //           math.bignumber(content.total_dollar),
            //           math.bignumber(content.vat)
            //         ),
            //         2
            //       )
            //     )
            //   : thousands(math.floor(math.bignumber(content.total_dollar), 2))
            thousands(
              math.floor(
                math.add(
                  math.bignumber(content.total_dollar),
                  math.bignumber(content.vat)
                ),
                2
              )
            )
          }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div style="width: 99%; text-align: left">
          &nbsp; 批示(APPROVAL)： &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 33%; text-align: center"
        >
          &nbsp; 產區總經理 &nbsp;<br />
          (Eswatini Managing Director)
        </div>
        <div
          style="border-right: 1px solid black; width: 33%; text-align: center"
        >
          &nbsp; 財務主管 &nbsp;<br />
          (Financial Director)
        </div>
        <div style="width: 32%; text-align: center">
          &nbsp; 管理部主管 &nbsp;<br />
          (Chief of Admin Department)
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 33%; text-align: center"
        >
          &nbsp; &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 33%; text-align: center"
        >
          &nbsp; &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div style="width: 32%; text-align: center">
          &nbsp; &nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; 事業部主管 &nbsp;<br />
          (Chief of Department)
        </div>
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; 廠長/經理 &nbsp;<br />
          (Manager)
        </div>
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; 部門主管 &nbsp;<br />
          (Section Manager)
        </div>
        <div style="width: 24%; text-align: center">
          &nbsp; 經辦人/簽收人 &nbsp;<br />
          (Payee)
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          {{
            store.state.payment != null
              ? store.state.payment.audit_employer_name
              : ""
          }}
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div style="width: 24%; text-align: center">
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
