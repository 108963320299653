<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
} from "vue";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Textarea from "primevue/textarea";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import SpotExchangeRate from "../../api/salary/admin/spot_exchange_rate";
import Currency from "../../api/salary/admin/currency";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import Calendar from "primevue/calendar";
import firebase from "firebase";
import router from "../../router";
import { dateFormate } from "../../tool/formate";

let userApi = new User();
let spot_exchange_rateAPI = new SpotExchangeRate();
let currencyAPI = new Currency();
const store = useStore();

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}

const i18n = useI18n();
const toast = useToast();
const data = reactive({
  selectCurrencyList: [],
  CurrencySearch: "",
  date_search: "",
  add_spot_exchange_rate: {
    currency_id: 0,
    exchange_rate: 0,
    rate_of_month: "",
  },
  spot_exchange_rateLoading: false,
  totalRecords: 0,
  addDialog: false,
  deleteDialog: false,
  deleteSelectedDialog: false,
  editDialog: false,
  completeDialog: false,
  spot_exchange_rates: { data: null },
  spot_exchange_rate: null,
  page: 1,
  page_num: 10,
  selectedSpotExchangeRates: null,
  spot_exchange_rateFilters: {
    id: { value: "", matchMode: "contains" },
    currency_id: { value: "", matchMode: "contains" },
    rate_of_month_begin: { value: "", matchMode: "contains" },
    rate_of_month_end: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {
  CheckUser();
});
const mounted = onMounted(async () => {
  data.loading = true;
  if (store.state.user != null) {
    getSpotExchangeRateList();
    getCurrencySelectList();
  }
});
function getCurrencySelectList() {
  currencyAPI.getCurrencySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCurrencyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getSpotExchangeRateList() {
  spot_exchange_rateAPI
    .getSpotExchangeRateList(
      data.spot_exchange_rateFilters.id.value,
      data.spot_exchange_rateFilters.currency_id.value,
      data.spot_exchange_rateFilters.rate_of_month_begin.value,
      data.spot_exchange_rateFilters.rate_of_month_end.value,
      data.spot_exchange_rateFilters.status.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.spot_exchange_rates.data = res.data.data.datas;
        data.page = res.data.data.page;
        // data.page_num = res.data.data.page_num;
        // console.log(res);
        data.spot_exchange_rateLoading = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });

        data.totalRecords = 0;
        data.spot_exchange_rateLoading = false;
      }
    });
}

function saveEditSpotExchangeRate() {
  var dateTime = new Date(data.spot_exchange_rate.rate_of_month);
  dateTime.setDate(dateTime.getDate() + 1);
  // console.log(dateTime.toISOString());
  spot_exchange_rateAPI
    .editSpotExchangeRate(
      data.spot_exchange_rate.id,
      data.spot_exchange_rate.currency_id,
      String(data.spot_exchange_rate.exchange_rate),
      dateTime.toISOString(),
      data.spot_exchange_rate.status
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("SpotExchangeRate").EditSuccess,
          life: 3000,
        });

        data.spot_exchange_rateLoading = true;
        getSpotExchangeRateList();
        data.editDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveAddSpotExchangeRate() {
  var dateTime = new Date(data.add_spot_exchange_rate.rate_of_month);
  dateTime.setDate(dateTime.getDate() + 1);
  spot_exchange_rateAPI
    .addSpotExchangeRate(
      data.add_spot_exchange_rate.currency_id,
      String(data.add_spot_exchange_rate.exchange_rate),
      dateTime.toISOString()
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("SpotExchangeRate").AddSuccess,
          life: 3000,
        });

        data.spot_exchange_rateLoading = true;
        getSpotExchangeRateList();
        data.addDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}

function saveCompleteSpotExchangeRate() {
  var dateTime = new Date(data.spot_exchange_rate.rate_of_month);
  dateTime.setDate(dateTime.getDate());
  // console.log(dateTime.toISOString());
  spot_exchange_rateAPI
    .editSpotExchangeRate(
      data.spot_exchange_rate.id,
      data.spot_exchange_rate.currency_id,
      String(data.spot_exchange_rate.exchange_rate),
      dateTime.toISOString(),
      2
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("SpotExchangeRate").EditSuccess,
          life: 3000,
        });

        data.spot_exchange_rateLoading = true;
        getSpotExchangeRateList();
        data.completeDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function addSpotExchangeRate() {
  data.addDialog = true;
}
function editSpotExchangeRate(spot_exchange_rate) {
  for (let i = 0; i < data.selectCurrencyList.length; i++) {
    if (spot_exchange_rate.currency_id == data.selectCurrencyList[i].id) {
      data.EditCurrency = data.selectCurrencyList[i];
    }
  }
  data.spot_exchange_rate = { ...spot_exchange_rate };
  data.editDialog = true;
}
function completeSpotExchangeRate(spot_exchange_rate) {
  data.spot_exchange_rate = { ...spot_exchange_rate };
  data.completeDialog = true;
}

function confirmDeleteSelected() {
  data.deleteSelectedDialog = true;
}
function confirmDelete(spot_exchange_rate) {
  data.spot_exchange_rate = spot_exchange_rate;
  data.deleteDialog = true;
}
function deleteSpotExchangeRate() {
  spot_exchange_rateAPI
    .deleteSpotExchangeRate([data.spot_exchange_rate.id])
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: i18n.$t("SpotExchangeRate").DeleteSuccess,
          life: 3000,
        });

        data.spot_exchange_rateLoading = true;
        getSpotExchangeRateList();
        data.deleteDialog = false;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function deleteSelectedSpotExchangeRate() {
  let selectDelete = [];
  for (let i = 0; i < data.selectedSpotExchangeRates.length; i++) {
    selectDelete.push(data.selectedSpotExchangeRates[i].id);
  }
  spot_exchange_rateAPI.deleteSpotExchangeRate(selectDelete).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("SpotExchangeRate").DeleteSuccess,
        life: 3000,
      });

      data.spot_exchange_rateLoading = true;
      data.selectedSpotExchangeRates = [];
      getSpotExchangeRateList();
      data.deleteSelectedDialog = false;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.spot_exchange_rateLoading = true;
    getSpotExchangeRateList();
  }
);
watch(
  () => data.CurrencySearch,
  () => {
    data.spot_exchange_rateFilters.currency_id.value =
      data.CurrencySearch == null ? "" : data.CurrencySearch.id;
    data.spot_exchange_rateLoading = true;
    getSpotExchangeRateList();
  }
);
watch(
  () => data.AddCurrency,
  () => {
    data.add_spot_exchange_rate.currency_id =
      data.AddCurrency == null ? "" : data.AddCurrency.id;
  }
);
watch(
  () => data.EditCurrency,
  () => {
    data.spot_exchange_rate.currency_id =
      data.EditCurrency == null ? "" : data.EditCurrency.id;
  }
);
watch(
  () => data.spot_exchange_rateFilters.id.value,
  () => {
    data.spot_exchange_rateLoading = true;
    getSpotExchangeRateList();
  }
);
watch(
  () => data.date_search,
  () => {
    data.loading = true;
    if (data.date_search != null) {
      data.spot_exchange_rateFilters.rate_of_month_begin.value =
        data.date_search != null ? data.date_search.toISOString() : "";
      data.spot_exchange_rateFilters.rate_of_month_end.value =
        data.date_search != null ? data.date_search.toISOString() : "";
    } else {
      data.spot_exchange_rateFilters.rate_of_month_begin.value = "";
      data.spot_exchange_rateFilters.rate_of_month_end.value = "";
    }
    getSpotExchangeRateList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.spot_exchange_rates.data"
      :loading="data.spot_exchange_rateLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; margin-top: 80px"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.spot_exchange_rateFilters"
      v-model:selection="data.selectedSpotExchangeRates"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{
                  i18n.$t("SpotExchangeRate").SpotExchangeRateTable.Search
                }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.CurrencySearch"
                    :options="data.selectCurrencyList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .CurrencyName.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </p>
            </AccordionTab>
          </Accordion>

          <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              :label="i18n.$t('SpotExchangeRate').SpotExchangeRateTable.Add"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="addSpotExchangeRate"
            />
            <Button
              :label="i18n.$t('SpotExchangeRate').SpotExchangeRateTable.Delete"
              icon="pi pi-trash"
              class="p-button-danger"
              @click="confirmDeleteSelected"
              :disabled="
                !data.selectedSpotExchangeRates ||
                !data.selectedSpotExchangeRates.length
              "
            />
          </div>
        </div>
      </template>
      <Column class="p-col-1" selectionMode="multiple"></Column>
      <Column
        class="p-col-1"
        :field="i18n.$t('SpotExchangeRate').SpotExchangeRateTable.ID.Field"
        :header="i18n.$t('SpotExchangeRate').SpotExchangeRateTable.ID.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('SpotExchangeRate').SpotExchangeRateTable.CurrencyName.Field
        "
        :header="
          i18n.$t('SpotExchangeRate').SpotExchangeRateTable.CurrencyName.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :field="
          i18n.$t('SpotExchangeRate').SpotExchangeRateTable.ExchangeRate.Field
        "
        :header="
          i18n.$t('SpotExchangeRate').SpotExchangeRateTable.ExchangeRate.Show
        "
      ></Column>
      <Column
        class="p-col-2"
        :header="
          i18n.$t('SpotExchangeRate').SpotExchangeRateTable.RateOfMonth.Show
        "
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.rate_of_month, "YYYY-MM") }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('SpotExchangeRate').SpotExchangeRateTable.Status.Show"
      >
        <template #body="slotProps">
          {{
            slotProps.data.status == 1
              ? i18n.$t("SpotExchangeRate").SpotExchangeRateTable.OFF
              : i18n.$t("SpotExchangeRate").SpotExchangeRateTable.ON
          }}
        </template>
      </Column>
      <Column class="p-col-2">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            v-if="slotProps.data.status == 1"
            @click="editSpotExchangeRate(slotProps.data)"
          />
          <Button
            icon="pi pi-check-square"
            class="p-button-rounded p-button-info p-mr-2"
            v-if="slotProps.data.status == 1"
            @click="completeSpotExchangeRate(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDelete(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <!-- 新增視窗 -->
    <Dialog
      v-model:visible="data.addDialog"
      :style="{ width: '450px', minWidth: '350px' }"
      :header="i18n.$t('SpotExchangeRate').AddSpotExchangeRate.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SpotExchangeRate").SpotExchangeRateTable.CurrencyName.Show
        }}</label>
        <!-- <InputText
          id="name"
          v-model.trim="data.add_spot_exchange_rate.name"
          required="true"
        /> -->
        <Dropdown
          v-model="data.AddCurrency"
          :options="data.selectCurrencyList"
          optionLabel="name"
          :filter="true"
          :placeholder="
            i18n.$t('SpotExchangeRate').SpotExchangeRateTable.CurrencyName.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SpotExchangeRate").SpotExchangeRateTable.ExchangeRate.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.add_spot_exchange_rate.exchange_rate"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SpotExchangeRate").SpotExchangeRateTable.RateOfMonth.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.add_spot_exchange_rate.rate_of_month"
          view="month"
          dateFormat="yy/mm"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('SpotExchangeRate').SpotExchangeRateTable.RateOfMonth.Show
          "
          :showButtonBar="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SpotExchangeRate').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.addDialog = false"
        />
        <Button
          :label="i18n.$t('SpotExchangeRate').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveAddSpotExchangeRate"
        />
      </template>
    </Dialog>
    <!-- 詳細視窗 -->
    <Dialog
      v-model:visible="data.editDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SpotExchangeRate').EditSpotExchangeRate.Title"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SpotExchangeRate").SpotExchangeRateTable.CurrencyName.Show
        }}</label>
        <Dropdown
          v-model="data.EditCurrency"
          :options="data.selectCurrencyList"
          optionLabel="name"
          :filter="true"
          :placeholder="
            i18n.$t('SpotExchangeRate').SpotExchangeRateTable.CurrencyName.Show
          "
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SpotExchangeRate").SpotExchangeRateTable.ExchangeRate.Show
        }}</label>
        <InputNumber
          id="name"
          v-model="data.spot_exchange_rate.exchange_rate"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("SpotExchangeRate").SpotExchangeRateTable.RateOfMonth.Show
        }}</label>
        <Calendar
          id="range"
          v-model="data.spot_exchange_rate.rate_of_month"
          view="month"
          dateFormat="yy/mm"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('SpotExchangeRate').SpotExchangeRateTable.RateOfMonth.Show
          "
          :showButtonBar="true"
        />
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SpotExchangeRate').Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.editDialog = false"
        />
        <Button
          :label="i18n.$t('SpotExchangeRate').Save"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveEditSpotExchangeRate"
        />
      </template>
    </Dialog>
    <!-- 單一刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SpotExchangeRate').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.spot_exchange_rate"
          >{{ i18n.$t("SpotExchangeRate").Confirm.Content }}?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SpotExchangeRate').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteDialog = false"
        />
        <Button
          :label="i18n.$t('SpotExchangeRate').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSpotExchangeRate"
        />
      </template>
    </Dialog>
    <!-- 完成視窗 -->
    <Dialog
      v-model:visible="data.completeDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SpotExchangeRate').Complete.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span v-if="data.spot_exchange_rate"
          >{{ i18n.$t("SpotExchangeRate").Complete.Content }}?</span
        >
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SpotExchangeRate').Complete.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.completeDialog = false"
        />
        <Button
          :label="i18n.$t('SpotExchangeRate').Complete.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveCompleteSpotExchangeRate"
        />
      </template>
    </Dialog>
    <!-- 選擇刪除視窗 -->
    <Dialog
      v-model:visible="data.deleteSelectedDialog"
      :style="{ width: '450px' }"
      :header="i18n.$t('SpotExchangeRate').Confirm.Title"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>{{ i18n.$t("SpotExchangeRate").Confirm.SelectContent }}</span>
      </div>
      <template #footer>
        <Button
          :label="i18n.$t('SpotExchangeRate').Confirm.No"
          icon="pi pi-times"
          class="p-button-text"
          @click="data.deleteSelectedDialog = false"
        />
        <Button
          :label="i18n.$t('SpotExchangeRate').Confirm.Yes"
          icon="pi pi-check"
          class="p-button-text"
          @click="deleteSelectedSpotExchangeRate"
        />
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
