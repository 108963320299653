<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import { printHTML } from "../tool/print";
import PrintWater from "./PrintWater.vue";
import Dialog from "primevue/dialog";
import router from "../router";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordionTab";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Chip from "primevue/chip";
import MultiSelect from "primevue/multiselect";
import TabMenu from "primevue/tabmenu";
import FileUpload from "primevue/fileUpload";
import InputText from "primevue/inputText";
import InputNumber from "primevue/inputNumber";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "../i18nPlugin";
import { useStore } from "vuex";
import User from "../api/salary/auth/user";
import AdminEmployerDetail from "../api/salary/admin/employer_detail";
import ManagerEmployerDetail from "../api/salary/manager/employer_detail";
import SelfEmployerDetail from "../api/salary/self/employer_detail";

import AdminEmployer from "../api/salary/admin/employer";
import ManagerEmployer from "../api/salary/manager/employer";
import SelfEmployer from "../api/salary/self/employer";
import Organisation from "../api/salary/admin/organisation";
import Position from "../api/salary/admin/position";
import WorkPermit from "../api/salary/admin/work_permit";
import Currency from "../api/salary/admin/currency";
import Department from "../api/salary/admin/department";
import Nationality from "../api/salary/admin/nationality";
import { dateFormate } from "../tool/formate";
import { getGMT_8 } from "../tool/gmt";
import { getBase64 } from "../tool/image";
import SelfSalary from "../api/salary/self/salary";
import AdminSalary from "../api/salary/admin/salary";
import ManagerSalary from "../api/salary/manager/salary";
import africa_logo from "../assets/africa_logo.jpg";
const printDom = ref(null);
let userApi = new User();

let admin_employer_detailAPI = new AdminEmployerDetail();
let admin_employerAPI = new AdminEmployer();
let manager_employer_detailAPI = new ManagerEmployerDetail();
let manager_employerAPI = new ManagerEmployer();
let self_employer_detailAPI = new SelfEmployerDetail();
let self_employerAPI = new SelfEmployer();
let organisationAPI = new Organisation();
let positionAPI = new Position();
let work_permitAPI = new WorkPermit();
let currencyAPI = new Currency();
let departmentAPI = new Department();
let nationalityAPI = new Nationality();

let self_salaryAPI = new SelfSalary();
let manager_salaryAPI = new ManagerSalary();
let admin_salaryAPI = new AdminSalary();

const i18n = useI18n();
const toast = useToast();
const store = useStore();
const data = reactive({
  check: false,
  export_excel_water: false,
  export_excel_water_settlement_of_month: "",
  active: 0,
  ReceiptTypeChoose: "",
  ReceiptTypeList: [
    {
      id: 1,
      name: i18n.$t("Employer").EmployerTable.ReceiptTypeSelect
        .LocalReceiptTypeStatus,
    },
    {
      id: 2,
      name: i18n.$t("Employer").EmployerTable.ReceiptTypeSelect
        .ExternalReceiptTypeStatus,
    },
  ],
  items: [
    {
      active: 0,
      label: i18n.$t("Employer").Info,
      icon: "pi pi-fw pi-home",
    },
    {
      active: 1,
      label: i18n.$t("Employer").Detail,
      icon: "pi pi-fw pi-id-card",
    },
    {
      active: 2,
      label: i18n.$t("Employer").WorkCardImage,
      icon: "pi pi-fw pi-image",
    },
    {
      active: 3,
      label: i18n.$t("Employer").PassportImage,
      icon: "pi pi-fw pi-image",
    },
    {
      active: 4,
      label: i18n.$t("Employer").ContractImage,
      icon: "pi pi-fw pi-image",
    },
    {
      active: 5,
      label: i18n.$t("Employer").Salary,
      icon: "pi pi-fw pi-dollar",
    },
  ],
  OrganisationChoose: "",
  OrganisationList: [],
  AddEmpolyerPositionsSearch: [],
  AddEmpolyerWorkPermitsSearch: [],
  AddEmpolyerCurrencySearch: [],
  AddEmpolyerDepartmentSearch: [],
  AddEmpolyerNationalitySearch: [],
  selectPositionsList: [],
  selectWorkPermitsList: [],
  selectCurrencyList: [],
  selectDepartmentList: [],
  selectNationalityList: [],
  RoleChoose: "",
  RoleList: [
    {
      id: 1,
      name: "Admin",
    },
    {
      id: 2,
      name: "Manager",
    },
    {
      id: 3,
      name: "Employer",
    },
  ],
  SexChoose: "",
  SexList: [
    {
      id: 1,
      name: "Male",
    },
    {
      id: 2,
      name: "Female",
    },
  ],
  employer: {
    username: "",
    password: "",
    organisation_id: 0,
    new_position_ids: [],
    new_work_permit_ids: [],
    new_currency_id: 0,
    delete_position_ids: [],
    delete_work_permit_ids: [],
    delete_currency_id: 0,
    name: "",
    english_name: "",
    salary: 0,
    role: 0,
    role_addition_binding: 0,
  },
  employer_detail: {
    new_nationality_ids: [],
    delete_nationality_ids: [],
    new_department_ids: [],
    delete_department_ids: [],
    sex: "",
    birthday: "",
    start_working: "",
    identity_card_number: "",
    phone: "",
    income_tax_number: "",
    local_bank: "",
    local_account_number: "",
    local_branch_code: "",
    foreign_bank: "",
    foreign_account_number: "",
    foreign_branch_code: "",
    work_card_effective_date: "",
    work_card_expire_date: "",
    work_card_image: "",
    work_card_image_url: "",
    passport_expire_date: "",
    passport_image: "",
    passport_image_url: "",
    email: "",
    contract_image: "",
    contract_image_url: "",
    passport_number: "",
    work_card_number: "",
    contract_effective_date: "",
    contract_expire_date: "",
  },
  salaryLoading: false,
  page: 1,
  page_num: 10,
  salaryFilters: {
    pay_date_begin: { value: "", matchMode: "contains" },
    pay_date_end: { value: "", matchMode: "contains" },
  },
  totalRecords: 0,
  salarys: { data: null },
});
const beforemounted = onBeforeMount(async () => {
  data.employer = store.state.employer;
  await getSalaryList();
  if (store.state.user.role == 1) {
    await getOrganisationSelectList();
    await getPositionSelectList();
    await getWorkPermitSelectList();
    await getCurrencySelectList();
    await getDepartmentSelectList();
    await getNationalitySelectList();
    if (store.state.user.role == 1) {
      if (data.employer.work_permits != null) {
        data.AddEmpolyerWorkPermitsSearch = data.employer.work_permits;
      }
      if (data.employer.positions != null) {
        data.AddEmpolyerPositionsSearch = data.employer.positions;
      }
      if (data.employer.currency.length != 0) {
        if (data.employer.currency.id != 0)
          data.AddEmpolyerCurrencySearch = [data.employer.currency];
      }
    }
  }
});
const mounted = onMounted(async () => {
  CheckUser();
  GetEmployerDetailInfo();
});
function SaveExportExcelWater(dom) {
  if (data.export_excel_water_settlement_of_month != "") {
    console.log(data.export_excel_water_settlement_of_month);

    let start_date = new Date(
      data.export_excel_water_settlement_of_month.getFullYear(),
      data.export_excel_water_settlement_of_month.getMonth(),
      1,
      0,
      0,
      0,
      0
    );
    let end_date = new Date(
      data.export_excel_water_settlement_of_month.getFullYear(),
      data.export_excel_water_settlement_of_month.getMonth() + 1,
      1,
      23,
      59,
      59,
      999
    );

    const GMT_8_END_DATE =
      end_date.getHours() - (8 + end_date.getTimezoneOffset() / 60) - 24;

    end_date.setHours(GMT_8_END_DATE);

    const GMT_8_START_DATE =
      start_date.getHours() - (8 + start_date.getTimezoneOffset() / 60);

    start_date.setHours(GMT_8_START_DATE);

    let water_tmp = [];
    if (
      router.currentRoute.value.path == "/" ||
      router.currentRoute.value.path.indexOf("/info") != -1
    ) {
      self_salaryAPI
        .getWaterExcel(start_date.toISOString(), end_date.toISOString())
        .then((res) => {
          if (res.data.error_code == 0) {
            let water = res.data.data.datas;
            for (let i = 0; i < water.length; i++) {
              let tmp_index = water_tmp.findIndex(
                (x) =>
                  x.recipient_employer_id === water[i].recipient_employer_id
              );
              if (tmp_index != -1) {
                if (water[i].salary_positive_or_negative == 2) {
                  water_tmp[tmp_index].total_positive =
                    water_tmp[tmp_index].total_positive +
                    parseFloat(water[i].dollar);

                  water_tmp[tmp_index].salary_positive.push({
                    salary_item_name: water[i].salary_item_name,
                    dollar: water[i].dollar,
                    settlement_of_month: water[i].settlement_of_month,
                  });
                } else {
                  water_tmp[tmp_index].total_negative =
                    water_tmp[tmp_index].total_negative +
                    parseFloat(water[i].dollar);

                  water_tmp[tmp_index].salary_negative.push({
                    salary_item_name: water[i].salary_item_name,
                    dollar: water[i].dollar,
                    settlement_of_month: water[i].settlement_of_month,
                  });
                }
              } else {
                if (water[i].salary_positive_or_negative == 2) {
                  water_tmp.push({
                    total_positive: parseFloat(water[i].dollar),
                    total_negative: 0,
                    pay_period: data.export_excel_water_settlement_of_month,
                    recipient_employer_id: water[i].recipient_employer_id,
                    recipient_employer_name: water[i].recipient_employer_name,

                    recipient_employer_english_name:
                      water[i].recipient_employer_english_name,
                    recipient_employer_identity_card_number:
                      water[i].recipient_employer_identity_card_number,
                    work_permits: water[i].work_permits,
                    organisation_name: water[i].organisation_name,
                    positions: water[i].positions,
                    recipient_employer_start_working:
                      water[i].recipient_employer_start_working,
                    salary_positive: [
                      {
                        salary_item_name: water[i].salary_item_name,
                        dollar: water[i].dollar,
                        settlement_of_month: water[i].settlement_of_month,
                      },
                    ],
                    salary_negative: [],
                  });
                } else {
                  water_tmp.push({
                    total_positive: 0,
                    total_negative: parseFloat(water[i].dollar),
                    pay_period: data.export_excel_water_settlement_of_month,
                    recipient_employer_id: water[i].recipient_employer_id,
                    recipient_employer_name: water[i].recipient_employer_name,

                    recipient_employer_english_name:
                      water[i].recipient_employer_english_name,
                    recipient_employer_identity_card_number:
                      water[i].recipient_employer_identity_card_number,
                    work_permits: water[i].work_permits,
                    organisation_name: water[i].organisation_name,
                    positions: water[i].positions,
                    recipient_employer_start_working:
                      water[i].recipient_employer_start_working,
                    salary_positive: [],
                    salary_negative: [
                      {
                        salary_item_name: water[i].salary_item_name,
                        dollar: water[i].dollar,
                        settlement_of_month: water[i].settlement_of_month,
                      },
                    ],
                  });
                }
              }
            }
            // data.selectSalaryItemList = res.data.data.datas;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(async () => {
          await store.commit("set_water", water_tmp);
          await store.commit("set_logo", africa_logo);
        })
        .then(() => {
          console.log(store.state.logo);
          printHTML(dom);
        });
    } else {
      admin_salaryAPI
        .getWaterExcelInfo(
          start_date.toISOString(),
          end_date.toISOString(),
          store.state.employer.id
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            let water = res.data.data.datas;
            for (let i = 0; i < water.length; i++) {
              let tmp_index = water_tmp.findIndex(
                (x) =>
                  x.recipient_employer_id === water[i].recipient_employer_id
              );
              if (tmp_index != -1) {
                if (water[i].salary_positive_or_negative == 2) {
                  water_tmp[tmp_index].total_positive =
                    water_tmp[tmp_index].total_positive +
                    parseFloat(water[i].dollar);

                  water_tmp[tmp_index].salary_positive.push({
                    salary_item_name: water[i].salary_item_name,
                    dollar: water[i].dollar,
                    settlement_of_month: water[i].settlement_of_month,
                  });
                } else {
                  water_tmp[tmp_index].total_negative =
                    water_tmp[tmp_index].total_negative +
                    parseFloat(water[i].dollar);

                  water_tmp[tmp_index].salary_negative.push({
                    salary_item_name: water[i].salary_item_name,
                    dollar: water[i].dollar,
                    settlement_of_month: water[i].settlement_of_month,
                  });
                }
              } else {
                if (water[i].salary_positive_or_negative == 2) {
                  water_tmp.push({
                    total_positive: parseFloat(water[i].dollar),
                    total_negative: 0,
                    pay_period: data.export_excel_water_settlement_of_month,
                    recipient_employer_id: water[i].recipient_employer_id,
                    recipient_employer_name: water[i].recipient_employer_name,

                    recipient_employer_english_name:
                      water[i].recipient_employer_english_name,
                    recipient_employer_identity_card_number:
                      water[i].recipient_employer_identity_card_number,
                    work_permits: water[i].work_permits,
                    organisation_name: water[i].organisation_name,
                    positions: water[i].positions,
                    recipient_employer_start_working:
                      water[i].recipient_employer_start_working,
                    salary_positive: [
                      {
                        salary_item_name: water[i].salary_item_name,
                        dollar: water[i].dollar,
                        settlement_of_month: water[i].settlement_of_month,
                      },
                    ],
                    salary_negative: [],
                  });
                } else {
                  water_tmp.push({
                    total_positive: 0,
                    total_negative: parseFloat(water[i].dollar),
                    pay_period: data.export_excel_water_settlement_of_month,
                    recipient_employer_id: water[i].recipient_employer_id,
                    recipient_employer_name: water[i].recipient_employer_name,

                    recipient_employer_english_name:
                      water[i].recipient_employer_english_name,
                    recipient_employer_identity_card_number:
                      water[i].recipient_employer_identity_card_number,
                    work_permits: water[i].work_permits,
                    organisation_name: water[i].organisation_name,
                    positions: water[i].positions,
                    recipient_employer_start_working:
                      water[i].recipient_employer_start_working,
                    salary_positive: [],
                    salary_negative: [
                      {
                        salary_item_name: water[i].salary_item_name,
                        dollar: water[i].dollar,
                        settlement_of_month: water[i].settlement_of_month,
                      },
                    ],
                  });
                }
              }
            }
            // data.selectSalaryItemList = res.data.data.datas;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(async () => {
          await store.commit("set_water", water_tmp);
          await store.commit("set_logo", africa_logo);
        })
        .then(() => {
          console.log(store.state.logo);
          printHTML(dom);
        });
    }
  }
}
function getSalaryList() {
  switch (store.state.user.role) {
    case 1:
      admin_salaryAPI
        .getSalaryList(
          store.state.employer.id,
          data.salaryFilters.pay_date_begin.value,
          data.salaryFilters.pay_date_end.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salarys.data = res.data.data.datas;
            data.page = res.data.data.page;
            data.salaryLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.salaryLoading = false;
          }
        });

      break;
    case 2:
      manager_salaryAPI
        .getSalaryList(
          store.state.employer.id,
          data.salaryFilters.pay_date_begin.value,
          data.salaryFilters.pay_date_end.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salarys.data = res.data.data.datas;
            data.page = res.data.data.page;
            data.salaryLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.salaryLoading = false;
          }
        });

      break;
    case 3:
      self_salaryAPI
        .getSalaryList(
          data.salaryFilters.pay_date_begin.value,
          data.salaryFilters.pay_date_end.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.salarys.data = res.data.data.datas;
            data.page = res.data.data.page;
            data.salaryLoading = false;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });

            data.totalRecords = 0;
            data.salaryLoading = false;
          }
        });
      break;
  }
}
function getDepartmentSelectList() {
  departmentAPI.getDepartmentSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectDepartmentList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getNationalitySelectList() {
  nationalityAPI.getNationalitySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectNationalityList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getCurrencySelectList() {
  currencyAPI.getCurrencySelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectCurrencyList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getPositionSelectList() {
  positionAPI.getPositionSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectPositionsList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getWorkPermitSelectList() {
  work_permitAPI.getWorkPermitSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectWorkPermitsList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function ExportExcelWater() {
  data.export_excel_water = true;
  data.export_excel_water_settlement_of_month = "";
}
function onUploadContract(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.employer_detail.contract_image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function onUploadWorkCard(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.employer_detail.work_card_image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function onUploadPasspart(event) {
  getBase64(event.files[0]).then((image_data) => {
    data.employer_detail.passport_image = image_data
      .replace("data:image/png;base64,", "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "");
  });
}
function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.OrganisationList = res.data.data.datas;
      for (let i = 0; i < data.OrganisationList.length; i++) {
        if (data.OrganisationList[i].id == data.employer.organisation_id) {
          data.OrganisationChoose = data.OrganisationList[i];
        }
      }
      for (let i = 0; i < data.RoleList.length; i++) {
        if (data.RoleList[i].id == data.employer.role) {
          data.RoleChoose = data.RoleList[i];
        }
      }

      if (data.RoleChoose.id == 1) {
        data.RoleAdditionList = [
          {
            id: 100,
            name: "Admin1",
          },
          {
            id: 101,
            name: "Admin2",
          },
          {
            id: 102,
            name: "Admin3",
          },
          {
            id: 103,
            name: "Admin4",
          },
        ];
      } else if (data.RoleChoose.id == 2) {
        data.RoleAdditionList = [
          {
            id: 200,
            name: "Manager1",
          },
          {
            id: 201,
            name: "Manager2",
          },
        ];
      } else {
        data.RoleAdditionList = [];
      }
      setTimeout(() => {
        data.check = true;
      }, "2000");

      for (let i = 0; i < data.RoleAdditionList.length; i++) {
        if (
          data.RoleAdditionList[i].id == data.employer.role_addition_binding
        ) {
          data.RoleAdditionChoose = data.RoleAdditionList[i];
        }
      }

      for (let i = 0; i < data.ReceiptTypeList.length; i++) {
        if (data.ReceiptTypeList[i].id == data.employer.receipt_type) {
          data.ReceiptTypeChoose = data.ReceiptTypeList[i];
        }
      }
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      store.commit("set_user", res.data.data);
    } else {
      store.commit("set_user", null);
    }
  });
}
function EditEmployer() {
  self_employerAPI.editEmployer(data.employer.password).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "Success",
        summary: "Success Message",
        detail: i18n.$t("Employer").EditSuccess,
        life: 3000,
      });
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function AddEmployerDetail() {
  admin_employer_detailAPI
    .getEmployerDetailInfo(store.state.employer.id)
    .then((res) => {
      if (res.data.error_code == 0) {
        admin_employer_detailAPI
          .editEmployerDetail(
            store.state.employer.id,
            data.employer_detail.new_nationality_ids,
            data.employer_detail.delete_nationality_ids,
            data.employer_detail.new_department_ids,
            data.employer_detail.delete_department_ids,
            data.employer_detail.sex,
            data.employer_detail.birthday == ""
              ? ""
              : data.employer_detail.birthday.toISOString(),
            data.employer_detail.start_working == ""
              ? ""
              : data.employer_detail.start_working.toISOString(),
            data.employer_detail.identity_card_number,
            data.employer_detail.phone,
            data.employer_detail.income_tax_number,
            data.employer_detail.local_bank,
            data.employer_detail.local_account_number,
            data.employer_detail.local_branch_code,
            data.employer_detail.foreign_bank,
            data.employer_detail.foreign_account_number,
            data.employer_detail.foreign_branch_code,
            data.employer_detail.work_card_effective_date == ""
              ? ""
              : data.employer_detail.work_card_effective_date.toISOString(),
            data.employer_detail.work_card_expire_date == ""
              ? ""
              : data.employer_detail.work_card_expire_date.toISOString(),
            data.employer_detail.work_card_image,
            data.employer_detail.passport_expire_date == ""
              ? ""
              : data.employer_detail.passport_expire_date.toISOString(),
            data.employer_detail.passport_image,
            data.employer_detail.email,
            data.employer_detail.contract_image,
            data.employer_detail.passport_number,
            data.employer_detail.work_card_number,
            data.employer_detail.contract_effective_date == ""
              ? ""
              : data.employer_detail.contract_effective_date.toISOString(),
            data.employer_detail.contract_expire_date == ""
              ? ""
              : data.employer_detail.contract_expire_date.toISOString()
          )
          .then((res) => {
            if (res.data.error_code == 0) {
              toast.add({
                severity: "Success",
                summary: "Success Message",
                detail: i18n.$t("Employer").EditSuccess,
                life: 3000,
              });
              GetEmployerDetailInfo();
            } else {
              toast.add({
                severity: "warn",
                summary: "Warn Message",
                detail: res.data.error_text,
                life: 3000,
              });
            }
          });
      } else {
        if (res.data.error_code == 8106) {
          if (
            data.employer_detail.sex == "" ||
            data.employer_detail.sex == null
          ) {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail:
                i18n.$t("Employer").Fill +
                i18n.$t("Employer").EmployerDetailTable.Sex.Show,
              life: 3000,
            });
          } else {
            admin_employer_detailAPI
              .addEmployerDetail(
                store.state.employer.id,
                data.employer_detail.new_nationality_ids,
                data.employer_detail.delete_nationality_ids,
                data.employer_detail.new_department_ids,
                data.employer_detail.delete_department_ids,
                data.employer_detail.sex,
                data.employer_detail.birthday == ""
                  ? ""
                  : data.employer_detail.birthday.toISOString(),
                data.employer_detail.start_working == ""
                  ? ""
                  : data.employer_detail.start_working.toISOString(),
                data.employer_detail.identity_card_number,
                data.employer_detail.phone,
                data.employer_detail.income_tax_number,
                data.employer_detail.local_bank,
                data.employer_detail.local_account_number,
                data.employer_detail.local_branch_code,
                data.employer_detail.foreign_bank,
                data.employer_detail.foreign_account_number,
                data.employer_detail.foreign_branch_code,
                data.employer_detail.work_card_effective_date == ""
                  ? ""
                  : data.employer_detail.work_card_effective_date.toISOString(),
                data.employer_detail.work_card_expire_date == ""
                  ? ""
                  : data.employer_detail.work_card_expire_date.toISOString(),
                data.employer_detail.work_card_image,
                data.employer_detail.passport_expire_date == ""
                  ? ""
                  : data.employer_detail.passport_expire_date.toISOString(),
                data.employer_detail.passport_image,
                data.employer_detail.email,
                data.employer_detail.contract_image,
                data.employer_detail.passport_number,
                data.employer_detail.work_card_number,
                data.employer_detail.contract_effective_date == ""
                  ? ""
                  : data.employer_detail.contract_effective_date.toISOString(),
                data.employer_detail.contract_expire_date == ""
                  ? ""
                  : data.employer_detail.contract_expire_date.toISOString()
              )
              .then((res) => {
                if (res.data.error_code == 0) {
                  toast.add({
                    severity: "Success",
                    summary: "Success Message",
                    detail: i18n.$t("Employer").AddSuccess,
                    life: 3000,
                  });
                  GetEmployerDetailInfo();
                } else {
                  toast.add({
                    severity: "warn",
                    summary: "Warn Message",
                    detail: res.data.error_text,
                    life: 3000,
                  });
                }
              });
          }
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      }
    });
}
function AddEmployer() {
  console.log(data.employer);
  admin_employerAPI
    .editEmployer(
      store.state.employer.id,
      data.employer.password,
      data.employer.organisation_id,
      data.employer.new_position_ids,
      data.employer.delete_position_ids,
      data.employer.new_work_permit_ids,
      data.employer.delete_work_permit_ids,
      data.employer.new_currency_id,
      data.employer.delete_currency_id,
      data.employer.name,
      data.employer.english_name,
      String(data.employer.salary),
      data.employer.role,
      data.employer.role_addition_binding,
      data.employer.receipt_type
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        toast.add({
          severity: "Success",
          summary: "Success Message",
          detail: i18n.$t("Employer").EditSuccess,
          life: 3000,
        });
        store.commit("set_employer_check", data.employer);
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
      }
    });
}
function GetEmployerDetailInfo() {
  switch (store.state.user.role) {
    case 1:
      admin_employer_detailAPI
        .getEmployerDetailInfo(store.state.employer.id)
        .then((res) => {
          if (res.data.error_code == 0) {
            data.employer_detail = res.data.data;
            for (let i = 0; i < data.SexList.length; i++) {
              if (data.SexList[i].id == data.employer_detail.sex)
                data.SexChoose = data.SexList[i];
            }
            data.AddEmpolyerDepartmentSearch = data.employer_detail.departments;
            data.AddEmpolyerNationalitySearch =
              data.employer_detail.nationalitys;
            data.employer_detail.contract_effective_date =
              data.employer_detail.contract_effective_date != ""
                ? getGMT_8(data.employer_detail.contract_effective_date)
                : "";
            data.employer_detail.contract_expire_date =
              data.employer_detail.contract_expire_date != ""
                ? getGMT_8(data.employer_detail.contract_expire_date)
                : "";
            data.employer_detail.passport_expire_date =
              data.employer_detail.passport_expire_date != ""
                ? getGMT_8(data.employer_detail.passport_expire_date)
                : "";
            data.employer_detail.birthday =
              data.employer_detail.birthday != ""
                ? getGMT_8(data.employer_detail.birthday)
                : "";
            data.employer_detail.work_card_effective_date =
              data.employer_detail.work_card_effective_date != ""
                ? getGMT_8(data.employer_detail.work_card_effective_date)
                : "";
            data.employer_detail.work_card_expire_date =
              data.employer_detail.work_card_expire_date != ""
                ? getGMT_8(data.employer_detail.work_card_expire_date)
                : "";
            data.employer_detail.start_working =
              data.employer_detail.start_working != ""
                ? getGMT_8(data.employer_detail.start_working)
                : "";
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
    case 2:
      manager_employer_detailAPI
        .getEmployerDetailInfo(store.state.employer.id)
        .then((res) => {
          if (res.data.error_code == 0) {
            data.employer_detail = res.data.data;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
    case 3:
      self_employer_detailAPI.getEmployerDetailInfo().then((res) => {
        if (res.data.error_code == 0) {
          data.employer_detail = res.data.data;
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
  }
}
watch(
  () => data.SexChoose,
  () => {
    data.employer_detail.sex = data.SexChoose == null ? "" : data.SexChoose.id;
  }
);
watch(
  () => data.OrganisationChoose,
  () => {
    data.employer.organisation_id =
      data.OrganisationChoose == null ? "" : data.OrganisationChoose.id;
  }
);
watch(
  () => data.RoleChoose,
  () => {
    data.employer.role = data.RoleChoose == null ? "" : data.RoleChoose.id;
    if (data.check) data.RoleAdditionChoose = "";
    if (data.RoleChoose.id == 1) {
      data.RoleAdditionList = [
        {
          id: 100,
          name: "Admin1",
        },
        {
          id: 101,
          name: "Admin2",
        },
        {
          id: 102,
          name: "Admin3",
        },
        {
          id: 103,
          name: "Admin4",
        },
      ];
      if (data.check)
        data.RoleAdditionChoose = {
          id: 103,
          name: "Admin4",
        };
    } else if (data.RoleChoose.id == 2) {
      data.RoleAdditionList = [
        {
          id: 200,
          name: "Manager1",
        },
        {
          id: 201,
          name: "Manager2",
        },
      ];
      if (data.check)
        data.RoleAdditionChoose = {
          id: 201,
          name: "Manager2",
        };
    } else {
      data.RoleAdditionList = [];
      data.RoleAdditionChoose = "";
    }
  }
);
watch(
  () => data.RoleAdditionChoose,
  () => {
    data.employer.role_addition_binding =
      data.RoleAdditionChoose == null ? 0 : data.RoleAdditionChoose.id;
  }
);

watch(
  () => data.ReceiptTypeChoose,
  () => {
    data.employer.receipt_type =
      data.ReceiptTypeChoose == null ? "" : data.ReceiptTypeChoose.id;
  }
);

watch(
  () => data.AddEmpolyerCurrencySearch,
  async () => {
    // console.log(data.AddEmpolyerCurrencySearch);
    if (data.AddEmpolyerCurrencySearch.length > 0) {
      if (data.employer.currency.id != 0) {
        if (data.AddEmpolyerCurrencySearch[0].id == data.employer.currency.id) {
          data.employer.new_currency_id = 0;
          data.employer.delete_currency_id = 0;
        } else {
          data.employer.new_currency_id = data.AddEmpolyerCurrencySearch[0].id;
          data.employer.delete_currency_id = data.employer.currency.id;
        }
      } else {
        data.employer.new_currency_id = data.AddEmpolyerCurrencySearch[0].id;
      }
    }
  }
);
watch(
  () => data.AddEmpolyerWorkPermitsSearch,
  async () => {
    let tmpIDs = [];
    let checkIDs = [];
    if (data.AddEmpolyerWorkPermitsSearch != null)
      for (let i = 0; i < data.AddEmpolyerWorkPermitsSearch.length; i++) {
        await tmpIDs.push(data.AddEmpolyerWorkPermitsSearch[i].id);
      }
    if (data.employer.work_permits != null)
      for (let i = 0; i < data.employer.work_permits.length; i++) {
        await checkIDs.push(data.employer.work_permits[i].id);
      }

    data.employer.new_work_permit_ids = [];
    data.employer.delete_work_permit_ids = [];

    for (let i = 0; i < tmpIDs.length; i++) {
      if (checkIDs.indexOf(tmpIDs[i]) == -1) {
        await data.employer.new_work_permit_ids.push(tmpIDs[i]);
      }
    }

    for (let i = 0; i < checkIDs.length; i++) {
      if (
        tmpIDs.indexOf(checkIDs[i]) == -1 &&
        data.employer.new_work_permit_ids.indexOf(checkIDs[i]) == -1
      ) {
        await data.employer.delete_work_permit_ids.push(checkIDs[i]);
      }
    }
  }
);

watch(
  () => data.AddEmpolyerPositionsSearch,
  async () => {
    let tmpIDs = [];
    let checkIDs = [];
    if (data.AddEmpolyerPositionsSearch != null)
      for (let i = 0; i < data.AddEmpolyerPositionsSearch.length; i++) {
        await tmpIDs.push(data.AddEmpolyerPositionsSearch[i].id);
      }
    if (data.employer.positions != null)
      for (let i = 0; i < data.employer.positions.length; i++) {
        await checkIDs.push(data.employer.positions[i].id);
      }

    data.employer.new_position_ids = [];
    data.employer.delete_position_ids = [];

    for (let i = 0; i < tmpIDs.length; i++) {
      if (checkIDs.indexOf(tmpIDs[i]) == -1) {
        await data.employer.new_position_ids.push(tmpIDs[i]);
      }
    }

    for (let i = 0; i < checkIDs.length; i++) {
      if (
        tmpIDs.indexOf(checkIDs[i]) == -1 &&
        data.employer.new_position_ids.indexOf(checkIDs[i]) == -1
      ) {
        await data.employer.delete_position_ids.push(checkIDs[i]);
      }
    }
  }
);
watch(
  () => data.AddEmpolyerNationalitySearch,
  async () => {
    let tmpIDs = [];
    let checkIDs = [];
    if (data.employer_detail.nationalitys == null) {
      data.employer_detail.new_nationality_ids = [];
      if (data.AddEmpolyerNationalitySearch != null)
        for (let i = 0; i < data.AddEmpolyerNationalitySearch.length; i++) {
          data.employer_detail.new_nationality_ids.push(
            data.AddEmpolyerNationalitySearch[i].id
          );
        }
      data.employer_detail.delete_nationality_ids = [];
    } else {
      for (let i = 0; i < data.AddEmpolyerNationalitySearch.length; i++) {
        await tmpIDs.push(data.AddEmpolyerNationalitySearch[i].id);
      }
      for (let i = 0; i < data.employer_detail.nationalitys.length; i++) {
        await checkIDs.push(data.employer_detail.nationalitys[i].id);
      }

      data.employer_detail.new_nationality_ids = [];
      data.employer_detail.delete_nationality_ids = [];

      for (let i = 0; i < tmpIDs.length; i++) {
        if (checkIDs.indexOf(tmpIDs[i]) == -1) {
          await data.employer_detail.new_nationality_ids.push(tmpIDs[i]);
        }
      }

      for (let i = 0; i < checkIDs.length; i++) {
        if (
          tmpIDs.indexOf(checkIDs[i]) == -1 &&
          data.employer_detail.new_nationality_ids.indexOf(checkIDs[i]) == -1
        ) {
          await data.employer_detail.delete_nationality_ids.push(checkIDs[i]);
        }
      }
    }
  }
);
watch(
  () => data.AddEmpolyerDepartmentSearch,
  async () => {
    let tmpIDs = [];
    let checkIDs = [];
    if (data.employer_detail.departments == null) {
      data.employer_detail.new_department_ids = [];
      if (data.AddEmpolyerDepartmentSearch != null)
        for (let i = 0; i < data.AddEmpolyerDepartmentSearch.length; i++) {
          data.employer_detail.new_department_ids.push(
            data.AddEmpolyerDepartmentSearch[i].id
          );
        }
      data.employer_detail.delete_department_ids = [];
    } else {
      for (let i = 0; i < data.AddEmpolyerDepartmentSearch.length; i++) {
        await tmpIDs.push(data.AddEmpolyerDepartmentSearch[i].id);
      }
      for (let i = 0; i < data.employer_detail.departments.length; i++) {
        await checkIDs.push(data.employer_detail.departments[i].id);
      }

      data.employer_detail.new_department_ids = [];
      data.employer_detail.delete_department_ids = [];

      for (let i = 0; i < tmpIDs.length; i++) {
        if (checkIDs.indexOf(tmpIDs[i]) == -1) {
          await data.employer_detail.new_department_ids.push(tmpIDs[i]);
        }
      }

      for (let i = 0; i < checkIDs.length; i++) {
        if (
          tmpIDs.indexOf(checkIDs[i]) == -1 &&
          data.employer_detail.new_department_ids.indexOf(checkIDs[i]) == -1
        ) {
          await data.employer_detail.delete_department_ids.push(checkIDs[i]);
        }
      }
    }
  }
);
watch(
  () => data.date_search,
  async () => {
    data.salaryLoading = true;
    if (data.date_search != null) {
      const GMT_8 =
        data.date_search.getHours() -
        (8 + data.date_search.getTimezoneOffset() / 60);

      let date = new Date(data.date_search.toISOString());
      await date.setHours(GMT_8);
      data.salaryFilters.pay_date_begin.value = date.toISOString();
      data.salaryFilters.pay_date_end.value = date.toISOString();
    } else {
      data.salaryFilters.pay_date_begin.value = "";
      data.salaryFilters.pay_date_end.value = "";
    }
    getSalaryList();
  }
);
function onPage(event) {
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.salaryLoading = true;
    getSalaryList();
  }
);
</script>

<template>
  <Toast />
  <TabMenu class="p-mb-3" :model="data.items" v-model:activeIndex="data.active">
  </TabMenu>
  <div v-if="data.active == 0" class="p-grid">
    <div class="p-col-12 p-md-6" style="font-size: 0.8em">
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.ID.Show
        }}</label>
        :
        <span>{{ data.employer.id }}</span>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.UserName.Show
        }}</label>
        :
        <span>{{ data.employer.username }}</span>
      </div>
      <div
        class="p-field"
        v-if="
          store.state.user.role == 1 || store.state.user.id == data.employer.id
        "
      >
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.PassWord.Show
        }}</label>
        :
        <InputText
          v-model="data.employer.password"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
        <Button
          v-if="store.state.user.role != 1"
          :label="i18n.$t('Employer').Save"
          class="p-button-success p-button-sm p-ml-1"
          @click="EditEmployer"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Role.Show
        }}</label>
        :
        <span
          v-if="
            store.state.user.role != 1 ||
            store.state.user.role_addition_binding == 103
          "
          >{{
            data.employer.role == 1
              ? "Admin"
              : data.employer.role == 2
              ? "Manager"
              : data.employer.role == 3
              ? "Employer"
              : "Unknow"
          }}</span
        >
        <Dropdown
          v-else
          v-model="data.RoleChoose"
          :options="data.RoleList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Role.Show"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.RoleAdditionBinding.Show
        }}</label>
        :
        <span
          v-if="
            store.state.user.role != 1 ||
            store.state.user.role_addition_binding == 101 ||
            store.state.user.role_addition_binding == 102 ||
            store.state.user.role_addition_binding == 103
          "
          >{{
            data.employer.role_addition_binding == 100
              ? "Admin1"
              : data.employer.role_addition_binding == 101
              ? "Admin2"
              : data.employer.role_addition_binding == 102
              ? "Admin3"
              : data.employer.role_addition_binding == 103
              ? "Admin4"
              : data.employer.role_addition_binding == 200
              ? "Manager1"
              : data.employer.role_addition_binding == 201
              ? "Manager2"
              : ""
          }}</span
        >
        <Dropdown
          v-else
          v-model="data.RoleAdditionChoose"
          :options="data.RoleAdditionList"
          optionLabel="name"
          :placeholder="
            i18n.$t('Employer').EmployerTable.RoleAdditionBinding.Show
          "
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Organisation.Show
        }}</label>
        :
        <span
          v-if="
            store.state.user.role != 1 ||
            store.state.user.role_addition_binding == 103
          "
          >{{ data.employer.organisation_name }}</span
        >
        <Dropdown
          v-else
          v-model="data.OrganisationChoose"
          :options="data.OrganisationList"
          optionLabel="name"
          :filter="true"
          :placeholder="i18n.$t('Employer').EmployerTable.Organisation.Show"
          :showClear="true"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
        <!-- <InputText class="p-ml-1 p-inputtext-sm" id="name" required="true" /> -->
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Currency.Show
        }}</label>
        :
        <div v-if="true">
          <Chip class="p-mr-1" :label="data.employer.currency.name" />
        </div>
        <MultiSelect
          v-else
          v-model="data.AddEmpolyerCurrencySearch"
          :options="data.selectCurrencyList"
          optionLabel="name"
          :selectionLimit="1"
          :placeholder="i18n.$t('Employer').EmployerTable.Currency.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.Currency.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
    <div class="p-col-12 p-md-6" style="font-size: 0.8em">
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Name.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{ data.employer.name }}</span>
        <InputText
          v-else
          v-model="data.employer.name"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.EnglishName.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer.english_name
        }}</span>
        <InputText
          v-else
          v-model="data.employer.english_name"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Positions.Show
        }}</label>
        :
        <div v-if="store.state.user.role != 1">
          <Chip
            :key="position_idex"
            class="p-mr-1"
            v-for="(position, position_idex) in data.employer.positions"
            :label="position.name"
          />
        </div>
        <MultiSelect
          v-else
          v-model="data.AddEmpolyerPositionsSearch"
          :options="data.selectPositionsList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.Positions.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.Positions.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.WorkPermits.Show
        }}</label>
        :
        <div v-if="store.state.user.role != 1">
          <Chip
            :key="work_permit_idex"
            class="p-mr-1"
            v-for="(work_permit, work_permit_idex) in data.employer
              .work_permits"
            :label="work_permit.name"
          />
        </div>
        <MultiSelect
          v-else
          v-model="data.AddEmpolyerWorkPermitsSearch"
          :options="data.selectWorkPermitsList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.WorkPermits.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerTable.WorkPermits.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.Salary.Show
        }}</label>
        :
        <!-- <span>{{ data.employer.salary }}</span> -->
        <span v-if="store.state.user.id != 5">{{ data.employer.salary }}</span>
        <InputNumber
          v-else
          v-model="data.employer.salary"
          mode="decimal"
          :minFractionDigits="2"
          :maxFractionDigits="5"
          required="true"
        />
        <!-- <InputText class="p-ml-1 p-inputtext-sm" id="name" required="true" /> -->
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerTable.ReceiptType.Show
        }}</label>
        :
        <div v-if="store.state.user.role != 1">
          <Chip
            class="p-mr-1"
            :label="
              data.employer.receipt_type == 1
                ? i18n.$t('Employer').EmployerTable.ReceiptTypeSelect
                    .LocalReceiptTypeStatus
                : data.employer.receipt_type == 2
                ? i18n.$t('Employer').EmployerTable.ReceiptTypeSelect
                    .ExternalReceiptTypeStatus
                : i18n.$t('Employer').EmployerTable.ReceiptTypeSelect.Unknow
            "
          />
        </div>
        <Dropdown
          v-else
          v-model="data.ReceiptTypeChoose"
          :options="data.ReceiptTypeList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerTable.ReceiptType.Show"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
  </div>
  <div v-if="data.active == 1" class="p-grid">
    <div class="p-col-12 p-md-6" style="font-size: 0.8em">
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerDetailTable.Sex.Show }}</label
        >
        :
        <Dropdown
          v-if="
            data.employer_detail.sex != 1 &&
            data.employer_detail.sex != 2 &&
            store.state.user.role == 1
          "
          v-model="data.SexChoose"
          :options="data.SexList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerDetailTable.Sex.Show"
        >
          <template #value="slotProps">
            <div class="country-item country-item-value" v-if="slotProps.value">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
        <span v-else>{{
          data.employer_detail.sex == 1
            ? "Male"
            : data.employer_detail.sex == 2
            ? "Female"
            : "Unknow"
        }}</span>
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerDetailTable.Phone.Show }}</label
        >
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.phone
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.phone"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{ i18n.$t("Employer").EmployerDetailTable.BirthDay.Show }}</label
        >
        :
        <span v-if="store.state.user.role != 1">{{
          dateFormate(data.employer_detail.birthday, "YYYY-MM-DD")
        }}</span>
        <Calendar
          v-else
          class="p-ml-1 p-inputtext-sm"
          id="range"
          v-model="data.employer_detail.birthday"
          :manualInput="false"
          :showIcon="true"
          :placeholder="i18n.$t('Employer').EmployerDetailTable.BirthDay.Show"
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.LocalBank.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.local_bank
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.local_bank"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.LocalBranchCode.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.local_branch_code
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.local_branch_code"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.LocalAccountNumber.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.local_account_number
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.local_account_number"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.WorkCardEffectiveDate.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          dateFormate(
            data.employer_detail.work_card_effective_date,
            "YYYY-MM-DD"
          )
        }}</span>
        <Calendar
          v-else
          class="p-ml-1 p-inputtext-sm"
          id="range"
          v-model="data.employer_detail.work_card_effective_date"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('Employer').EmployerDetailTable.WorkCardEffectiveDate.Show
          "
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.WorkCardExpiryDate.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          dateFormate(data.employer_detail.work_card_expire_date, "YYYY-MM-DD")
        }}</span>
        <Calendar
          v-else
          class="p-ml-1 p-inputtext-sm"
          id="range"
          v-model="data.employer_detail.work_card_expire_date"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('Employer').EmployerDetailTable.WorkCardExpiryDate.Show
          "
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.WorkCardNumber.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.work_card_number
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.work_card_number"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.ContractEffectiveDate.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          dateFormate(
            data.employer_detail.contract_effective_date,
            "YYYY-MM-DD"
          )
        }}</span>
        <Calendar
          v-else
          class="p-ml-1 p-inputtext-sm"
          id="range"
          v-model="data.employer_detail.contract_effective_date"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('Employer').EmployerDetailTable.ContractEffectiveDate.Show
          "
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.ContractExpireDate.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          dateFormate(data.employer_detail.contract_expire_date, "YYYY-MM-DD")
        }}</span>
        <Calendar
          v-else
          class="p-ml-1 p-inputtext-sm"
          id="range"
          v-model="data.employer_detail.contract_expire_date"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('Employer').EmployerDetailTable.ContractExpireDate.Show
          "
          :showButtonBar="true"
        />
      </div>
    </div>
    <div class="p-col-12 p-md-6" style="font-size: 0.8em">
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.Nationality.Show
        }}</label>
        :
        <div v-if="store.state.user.role != 1">
          <Chip
            :key="nationality_idex"
            class="p-mr-1"
            v-for="(nationality, nationality_idex) in data.employer_detail
              .nationalitys"
            :label="nationality.name"
          />
        </div>
        <MultiSelect
          v-else
          v-model="data.AddEmpolyerNationalitySearch"
          :options="data.selectNationalityList"
          optionLabel="name"
          :placeholder="
            i18n.$t('Employer').EmployerDetailTable.Nationality.Show
          "
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerDetailTable.Nationality.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.Department.Show
        }}</label>
        :
        <div v-if="store.state.user.role != 1">
          <Chip
            :key="department_idex"
            class="p-mr-1"
            v-for="(department, department_idex) in data.employer_detail
              .departments"
            :label="department.name"
          />
        </div>
        <MultiSelect
          v-else
          v-model="data.AddEmpolyerDepartmentSearch"
          :options="data.selectDepartmentList"
          optionLabel="name"
          :placeholder="i18n.$t('Employer').EmployerDetailTable.Department.Show"
          :filter="true"
          class="multiselect-custom"
        >
          <template #value="slotProps">
            <div
              class="country-item country-item-value"
              v-for="option of slotProps.value"
              :key="option.id"
            >
              <div>{{ option.name }}</div>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ i18n.$t("Employer").EmployerDetailTable.Department.Show }}
            </template>
          </template>
          <template #option="slotProps">
            <div class="country-item">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </MultiSelect>
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.IdentityCardNumber.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.identity_card_number
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.identity_card_number"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.Email.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.email
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.email"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name"
          ><span style="color: red">*</span
          >{{
            i18n.$t("Employer").EmployerDetailTable.StartWorking.Show
          }}</label
        >
        :
        <span v-if="store.state.user.role != 1">{{
          dateFormate(data.employer_detail.start_working, "YYYY-MM-DD")
        }}</span>
        <Calendar
          v-else
          class="p-ml-1 p-inputtext-sm"
          id="range"
          v-model="data.employer_detail.start_working"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('Employer').EmployerDetailTable.StartWorking.Show
          "
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.ForeignBank.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.foreign_bank
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.foreign_bank"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.ForeignBranchCode.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.foreign_branch_code
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.foreign_branch_code"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.ForeignAccountNumber.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.foreign_account_number
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.foreign_account_number"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.PassportExpireDate.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          dateFormate(data.employer_detail.passport_expire_date, "YYYY-MM-DD")
        }}</span>
        <Calendar
          v-else
          class="p-ml-1 p-inputtext-sm"
          id="range"
          v-model="data.employer_detail.passport_expire_date"
          :manualInput="false"
          :showIcon="true"
          :placeholder="
            i18n.$t('Employer').EmployerDetailTable.PassportExpireDate.Show
          "
          :showButtonBar="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.PassportNumber.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.passport_number
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.passport_number"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
      <div class="p-field">
        <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.IncomeTaxNumber.Show
        }}</label>
        :
        <span v-if="store.state.user.role != 1">{{
          data.employer_detail.income_tax_number
        }}</span>
        <InputText
          v-else
          v-model="data.employer_detail.income_tax_number"
          class="p-ml-1 p-inputtext-sm"
          id="name"
          required="true"
        />
      </div>
    </div>
  </div>
  <div v-if="data.active == 2" class="p-grid">
    <div class="p-col-12" style="font-size: 0.8em">
      <div class="p-field">
        <!-- <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.WorkCardImage.Show
        }}</label> -->
        <div v-if="data.employer_detail.work_card_image_url != ''">
          <Button
            v-if="store.state.user.role == 1"
            :label="i18n.$t('Employer').EmployerTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="data.employer_detail.work_card_image_url = ''"
          /><br />
          <img
            :src="data.employer_detail.work_card_image_url"
            style="width: 80%"
          />
        </div>
        <FileUpload
          v-if="
            store.state.user.role == 1 &&
            data.employer_detail.work_card_image_url == ''
          "
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Employer').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Employer').FileLoad.cancelLabel"
          @uploader="onUploadWorkCard"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Employer").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
    </div>
  </div>
  <div v-if="data.active == 3" class="p-grid">
    <div class="p-col-12" style="font-size: 0.8em">
      <div class="p-field">
        <!-- <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.ContractImage.Show
        }}</label> -->
        <div v-if="data.employer_detail.contract_image_url != ''">
          <Button
            v-if="store.state.user.role == 1"
            :label="i18n.$t('Employer').EmployerTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="data.employer_detail.contract_image_url = ''"
          /><br />
          <img
            :src="data.employer_detail.contract_image_url"
            style="width: 80%"
          />
        </div>
        <FileUpload
          v-if="
            store.state.user.role == 1 &&
            data.employer_detail.contract_image_url == ''
          "
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Employer').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Employer').FileLoad.cancelLabel"
          @uploader="onUploadContract"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Employer").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
    </div>
  </div>
  <div v-if="data.active == 4" class="p-grid">
    <div class="p-col-12" style="font-size: 0.8em">
      <div class="p-field">
        <!-- <label for="name">{{
          i18n.$t("Employer").EmployerDetailTable.PassportImageUrl.Show
        }}</label> -->
        <div v-if="data.employer_detail.passport_image_url != ''">
          <Button
            v-if="store.state.user.role == 1"
            :label="i18n.$t('Employer').EmployerTable.Delete"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="data.employer_detail.passport_image_url = ''"
          /><br />
          <img
            :src="data.employer_detail.passport_image_url"
            style="width: 80%"
          />
        </div>
        <FileUpload
          v-if="
            store.state.user.role == 1 &&
            data.employer_detail.passport_image_url == ''
          "
          mode="advanced"
          :showUploadButton="false"
          :chooseLabel="i18n.$t('Employer').FileLoad.chooseLabel"
          :cancelLabel="i18n.$t('Employer').FileLoad.cancelLabel"
          @uploader="onUploadPasspart"
          :multiple="false"
          :customUpload="true"
          :auto="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>{{ i18n.$t("Employer").FileLoad.content }}</p>
          </template>
        </FileUpload>
      </div>
    </div>
  </div>
  <div v-if="data.active == 5" class="p-grid">
    <DataTable
      :lazy="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      :value="data.salarys.data"
      :loading="data.salaryLoading"
      dataKey="id"
      responsiveLayout="scroll"
      :scrollable="true"
      scrollHeight="calc(100vh - 335px)"
      style="min-width: 375px; width: 100%"
      :paginator="true"
      :rows="10"
      :totalRecords="data.totalRecords"
      v-model:filters="data.salaryFilters"
      v-model:selection="data.selectedSalarys"
      :row-hover="true"
      @page="onPage($event)"
      @filter="onFilter($event)"
      paginatorTemplate="FirstPageLink PageLinks LastPageLink"
    >
      <template #header>
        <div class="table-header p-grid">
          <Accordion class="accordion-custom p-col-12" :activeIndex="0">
            <AccordionTab>
              <template #header>
                <i class="pi pi-search p-mr-1"></i>
                <span>{{ i18n.$t("Salary").SalaryTable.Search }}</span>
              </template>
              <p style="text-align: left">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    class="p-mr-1"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
                <Button
                  :label="i18n.$t('Employer').ExportExcelWater"
                  icon="pi pi-book"
                  style="float: right"
                  class="p-button-secondary p-mr-2"
                  @click="ExportExcelWater"
                />
              </p>
            </AccordionTab>
          </Accordion>
          <!-- <div class="p-col-12 p-d-flex p-jc-end">
            <Button
              icon="pi pi-book"
              class="p-button-info"
              label="Excel"
              @click="Excel"
            />
          </div> -->
        </div>
      </template>
      <Column
        class="p-col-1"
        :field="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Field"
        :header="i18n.$t('Salary').SalaryTable.RecipientEmployerName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.SalaryItemName.Field"
        :header="i18n.$t('Salary').SalaryTable.SalaryItemName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.Dollar.Field"
        :header="i18n.$t('Salary').SalaryTable.Dollar.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.ExchangeRate.Field"
        :header="i18n.$t('Salary').SalaryTable.ExchangeRate.Show"
      ></Column>
      <Column
        class="p-col-2"
        :header="i18n.$t('Salary').SalaryTable.ExchangeDollar.Show"
      >
        <template #body="slotProps">
          {{
            (slotProps.data.dollar * slotProps.data.exchange_rate).toFixed(2)
          }}
        </template>
      </Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.CurrencyName.Field"
        :header="i18n.$t('Salary').SalaryTable.CurrencyName.Show"
      ></Column>
      <Column
        class="p-col-2"
        :field="i18n.$t('Salary').SalaryTable.Description.Field"
        :header="i18n.$t('Salary').SalaryTable.Description.Show"
      ></Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Salary').SalaryTable.PayDate.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.pay_date, "YYYY-MM") }}
        </template>
      </Column>
      <Column
        class="p-col-1"
        :header="i18n.$t('Salary').SalaryTable.CreatedEmployerName.Show"
        :field="i18n.$t('Salary').SalaryTable.CreatedEmployerName.Field"
      >
      </Column>

      <Column
        class="p-col-1"
        :header="i18n.$t('Salary').SalaryTable.CreatedAt.Show"
      >
        <template #body="slotProps">
          {{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
        </template>
      </Column>
    </DataTable>
  </div>
  <div
    v-if="store.state.user.role == 1 && data.active != 0 && data.active != 5"
    class="p-col-12 p-text-center"
  >
    <Button
      :label="i18n.$t('Employer').EmployerTable.Complete"
      class="p-button-success p-button-sm"
      @click="AddEmployerDetail"
    />
  </div>
  <div
    v-if="store.state.user.role == 1 && data.active == 0"
    class="p-col-12 p-text-center"
  >
    <Button
      :label="i18n.$t('Employer').EmployerTable.Complete"
      class="p-button-success p-button-sm"
      @click="AddEmployer"
    />
  </div>
  <!-- 匯出水單視窗 -->
  <Dialog
    v-model:visible="data.export_excel_water"
    :style="{ width: '450px' }"
    :header="i18n.$t('Employer').ExportExcelWater"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-field">
      <label for="name">{{ i18n.$t("Employer").SettlementOfMonth }}</label>
      <Calendar
        id="range"
        v-model="data.export_excel_water_settlement_of_month"
        view="month"
        dateFormat="yy/mm"
        :manualInput="false"
        :showIcon="true"
        :placeholder="i18n.$t('Employer').SettlementOfMonth"
        :showButtonBar="true"
      />
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('Employer').ReFireConfirm.No"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.export_excel_water = false"
      />
      <Button
        :label="i18n.$t('Employer').FireConfirm.Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="SaveExportExcelWater(printDom)"
      />
    </template>
  </Dialog>
  <div class="p-d-none">
    <div ref="printDom">
      <PrintWater />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
