<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  watch,
  ref,
} from "vue";
import Chip from "primevue/chip";
import router from "../../router";
import Toast from "primevue/toast";
import Textarea from "primevue/textarea";
import InputNumber from "primevue/inputNumber";
// import Dialog from "primevue/dialog";
import Button from "primevue/button";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import User from "../../api/salary/auth/user";
import Employer from "../../api/salary/self/employer";
import EmployerDetail from "../../api/salary/self/employer_detail";
import HolidayItem from "../../api/salary/self/holiday_item";
import Holiday from "../../api/salary/self/holiday";
import { dateFormate } from "../../tool/formate";
import PrintHoliday from "../../components/PrintHoliday.vue";
import { printHTML } from "../../tool/print";
import africa_logo from "../../assets/africa_logo.jpg";
const i18n = useI18n();
const toast = useToast();
const store = useStore();
const printDom = ref(null);
let userApi = new User();
let employerApi = new Employer();
let employer_detailApi = new EmployerDetail();
let holiday_itemApi = new HolidayItem();
let holidayApi = new Holiday();

const data = reactive({
  printDialog: false,
  selectHolidayItemList: [],
  item: [],
  send: true,
  holidays: { data: null },
  holiday: null,
  holidayLoading: false,
  page: 1,
  page_num: 10,
  totalRecords: 0,
  apply_days: 0,
  apply_date: "",
  date_search: "",
  employer: {
    organisation_name: "",
    name: "",
    english_name: "",
    position_name: "",
  },
  employer_detail: {
    new_nationality_ids: [],
    delete_nationality_ids: [],
    new_department_ids: [],
    delete_department_ids: [],
    sex: "",
    birthday: "",
    start_working: "",
    identity_card_number: "",
    phone: "",
    income_tax_number: "",
    local_bank: "",
    local_account_number: "",
    local_branch_code: "",
    foreign_bank: "",
    foreign_account_number: "",
    foreign_branch_code: "",
    work_card_effective_date: "",
    work_card_expiry_date: "",
    work_card_image: "",
    work_card_image_url: "",
    passport_expire_date: "",
    passport_image: "",
    passport_image_url: "",
    email: "",
    contract_image: "",
    contract_image_url: "",
  },
  reason: "",
  add_holidays: [],
  holidayFilters: {
    created_at_begin: { value: "", matchMode: "contains" },
    created_at_end: { value: "", matchMode: "contains" },
  },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  getEmployerInfo();
  getEmployerDetail();
  getHolidayItemSelectList();
  getHolidayList();
  store.commit("set_logo", africa_logo);
});

function getHolidayItemSelectList() {
  holiday_itemApi.getHolidayItemSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectHolidayItemList = res.data.data.datas;
      for (let i = 0; i < data.selectHolidayItemList.length; i++) {
        data.item.push({
          id: data.selectHolidayItemList[i].id,
          name: data.selectHolidayItemList[i].name,
          days: 0,
        });
      }
      // console.log(data.selectHolidayItemList);
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getHolidayList() {
  holidayApi
    .getHolidayList(
      data.holidayFilters.created_at_begin.value,
      data.holidayFilters.created_at_end.value,
      data.page,
      data.page_num
    )
    .then((res) => {
      if (res.data.error_code == 0) {
        data.totalRecords = res.data.data.total_count;
        data.holidays.data = res.data.data.datas;
        data.page = res.data.data.page;
      } else {
        toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: res.data.error_text,
          life: 3000,
        });
        if (res.data.error_code == 8106) {
          data.totalRecords = 0;
          data.holidays.data = [];
          // data.page = 0;
        }
      }
      data.holidayLoading = false;
    });
}
async function printSaveApplyHoliday(tmp_holiday, dom) {
  let holiday = {
    created_at: tmp_holiday.created_at,
    apply_days: 0,
    apply_start_date: "",
    apply_end_date: "",
    reason: "",
    apply_type: [],
  };
  let tmp_holidays = [tmp_holiday];
  for (let i = 0; i < tmp_holidays.length; i++) {
    holiday.apply_days = holiday.apply_days + tmp_holidays[i].apply_days;
    if (i == 0) {
      holiday.apply_start_date = tmp_holidays[i].apply_start_date;
      holiday.reason = tmp_holidays[i].reason;
    }
    if (i == tmp_holidays.length - 1) {
      holiday.apply_end_date = tmp_holidays[i].apply_end_date;
    }
    for (let j = 0; j < data.selectHolidayItemList.length; j++) {
      if (data.selectHolidayItemList[j].id == tmp_holidays[i].holiday_item_id) {
        holiday.apply_type.push(
          data.selectHolidayItemList[j].name +
            tmp_holidays[i].apply_days +
            " 天/Days"
        );
      }
    }
  }

  data.send = false;
  data.printDialog = true;

  await store.commit("set_employer", data.employer);
  await store.commit("set_employer_detail", data.employer_detail);
  await store.commit("set_holiday", holiday);

  printHTML(dom);
}
async function printApplyHoliday(dom) {
  let holiday = {
    created_at: new Date(),
    apply_days: 0,
    apply_start_date: "",
    apply_end_date: "",
    reason: "",
    apply_type: [],
  };
  for (let i = 0; i < data.add_holidays.length; i++) {
    holiday.apply_days = holiday.apply_days + data.add_holidays[i].apply_days;
    if (i == 0) {
      holiday.apply_start_date = data.add_holidays[i].apply_start_date;
      holiday.reason = data.add_holidays[i].reason;
    }
    if (i == data.add_holidays.length - 1) {
      holiday.apply_end_date = data.add_holidays[i].apply_end_date;
    }
    for (let j = 0; j < data.selectHolidayItemList.length; j++) {
      if (
        data.selectHolidayItemList[j].id == data.add_holidays[i].holiday_item_id
      ) {
        holiday.apply_type.push(
          data.selectHolidayItemList[j].name +
            data.add_holidays[i].apply_days +
            " 天/Days"
        );
      }
    }
    // switch (data.add_holidays[i].holiday_item_id) {
    //   case 1:
    //     holiday.apply_type.push(
    //       "合約休假 Annual leave" + data.add_holidays[i].apply_days + " 天/Days"
    //     );
    //     break;
    //   case 2:
    //     holiday.apply_type.push(
    //       "事假 Personal leave " + data.add_holidays[i].apply_days + " 天/Days"
    //     );
    //     break;
    //   case 3:
    //     holiday.apply_type.push(
    //       "公假 Occupational leave " +
    //         data.add_holidays[i].apply_days +
    //         " 天/Days"
    //     );
    //     break;
    //   case 4:
    //     holiday.apply_type.push(
    //       "其他 Other " + data.add_holidays[i].apply_days + " 天/Days"
    //     );
    //     break;
    //   case 5:
    //     holiday.apply_type.push(
    //       "述職 Debrief " + data.add_holidays[i].apply_days + " 天/Days"
    //     );
    //     break;
    //   case 6:
    //     holiday.apply_type.push(
    //       "病假 Sick leave " + data.add_holidays[i].apply_days + " 天/Days"
    //     );
    //     break;
    //   case 7:
    //     holiday.apply_type.push(
    //       "出差 Business trip " + data.add_holidays[i].apply_days + " 天/Days"
    //     );
    //     break;
    // }
  }

  data.send = false;
  data.printDialog = true;

  await store.commit("set_employer", data.employer);
  await store.commit("set_employer_detail", data.employer_detail);
  await store.commit("set_holiday", holiday);

  printHTML(dom);
}

function saveApplyHoliday() {
  data.send = true;
  holidayApi.batchHoliday(data.add_holidays).then((res) => {
    if (res.data.error_code == 0) {
      toast.add({
        severity: "success",
        summary: "Success Message",
        detail: i18n.$t("ApplyHoliday").AddSuccess,
        life: 3000,
      });
      data.holidayLoading = true;
      getHolidayList();
    } else {
      data.send = false;
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getEmployerDetail() {
  employer_detailApi.getEmployerDetailInfo().then((res) => {
    if (res.data.error_code == 0) {
      data.employer_detail = res.data.data;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function getEmployerInfo() {
  employerApi.getEmployerInfo().then((res) => {
    if (res.data.error_code == 0) {
      data.employer = res.data.data;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}
function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}

watch(
  () => data.item,
  () => {
    let total_days = 0;
    data.add_holidays = [];
    for (let i = 0; i < data.item.length; i++) {
      total_days = total_days + data.item[i].days;
    }
    if (total_days > data.apply_days) {
      for (let i = 0; i < data.item.length; i++) {
        data.item[i].days = 0;
      }
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: i18n.$t("ApplyHoliday").ErroDays,
        life: 3000,
      });
    }
    if (total_days == data.apply_days && data.apply_days != 0) {
      let start_day = data.apply_date[0];
      for (let i = 0; i < data.item.length; i++) {
        if (data.item[i].days > 0) {
          let end_day = new Date(start_day.toISOString());
          end_day.setDate(end_day.getDate() + (data.item[i].days - 1));
          data.add_holidays.push({
            apply_days: data.item[i].days,
            apply_start_date: start_day.toISOString(),
            apply_end_date: end_day.toISOString(),
            holiday_item_id: data.item[i].id,
            reason: data.reason,
          });
          start_day = new Date(end_day.toISOString());
          start_day.setDate(start_day.getDate() + 1);
        }
      }
    }
    console.log(data.add_holidays);
  },
  { deep: true }
);
watch(
  () => data.apply_date,
  () => {
    if (data.apply_date != null) {
      data.add_holidays = [];
      if (data.apply_date[0] != null && data.apply_date[1] != null) {
        var difference = Math.abs(data.apply_date[1] - data.apply_date[0]);
        let days = difference / (1000 * 3600 * 24) + 1;
        data.apply_days = days;
        for (let i = 0; i < data.item.length; i++) {
          data.item[i].days = 0;
        }
      }
    } else {
      data.apply_days = 0;
      for (let i = 0; i < data.item.length; i++) {
        data.item[i].days = 0;
      }
    }
  }
);
watch(
  () => data.page,
  () => {
    data.borrowLoading = true;
    getBorrowList();
  }
);
watch(
  () => data.date_search,
  () => {
    data.holidayLoading = true;
    if (data.date_search != null) {
      data.holidayFilters.created_at_begin.value = new Date(
        data.date_search.getFullYear(),
        data.date_search.getMonth(),
        1
      ).toISOString();

      data.holidayFilters.created_at_end.value = new Date(
        data.date_search.getFullYear(),
        data.date_search.getMonth() + 1,
        1
      ).toISOString();
    } else {
      data.holidayFilters.created_at_begin.value = "";
      data.holidayFilters.created_at_end.value = "";
    }
    getHolidayList();
  }
);
watch(
  () => data.reason,
  () => {
    for (let i = 0; i < data.add_holidays.length; i++) {
      data.add_holidays[i].reason = data.reason;
    }
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-grid">
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyHoliday").Employer }}
        </div>
        <div class="p-col-12">
          {{ i18n.$t("Employer").EmployerTable.Organisation.Show }}:{{
            data.employer.organisation_name
          }}
          {{ i18n.$t("Employer").EmployerTable.Name.Show }}:{{
            data.employer.name
          }}
          {{ i18n.$t("Employer").EmployerTable.EnglishName.Show }}:{{
            data.employer.english_name
          }}
          {{ i18n.$t("Employer").EmployerTable.PositionName.Show }}:
          <span
            v-if="
              data.employer.positions != null && data.employer.positions != []
            "
          >
            <Chip
              :key="position_idex"
              class="p-mr-1"
              v-for="(position, position_idex) in data.employer.positions"
              :label="position.name"
            />
          </span>
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyHoliday").HolidayInfo }}
        </div>
        <div class="p-col-12">
          <Calendar
            id="range"
            v-model="data.apply_date"
            selectionMode="range"
            :manualInput="false"
            :showIcon="true"
            :placeholder="i18n.$t('ApplyHoliday').ApplyDate"
            :showButtonBar="true"
          />
          {{
            `${i18n.$t("ApplyHoliday").Total}:${data.apply_days}${
              i18n.$t("ApplyHoliday").Day
            }`
          }}
        </div>
        <div class="p-col-12 p-grid" style="font-size: 0.8em">
          <div
            :key="index"
            v-for="(item, index) in data.selectHolidayItemList"
            class="p-col-6"
          >
            {{ item.id }}.{{ item.name }}
            <InputNumber
              class="p-inputtext-sm"
              inputId="minmax-buttons"
              v-model="data.item[index].days"
              mode="decimal"
              showButtons
              :min="0"
              :max="100"
            />
          </div>
          <!-- data.selectHolidayItemList -->
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyHoliday").Reason }}
        </div>
        <div class="p-col-12">
          <Textarea
            id="description"
            v-model="data.reason"
            required="true"
            rows="4"
            cols="43"
          />
        </div>
        <div class="p-col-12">
          <Button
            :label="i18n.$t('ApplyHoliday').Send"
            icon="pi pi-send"
            class="p-button-info p-mr-2"
            :disabled="data.send"
            @click="saveApplyHoliday"
          />
          <Button
            :label="i18n.$t('ApplyHoliday').Print"
            icon="pi pi-print"
            class="p-button-success p-mr-2"
            @click="printApplyHoliday(printDom)"
          />
        </div>
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("ApplyHoliday").History }}
        </div>
        <div class="p-col-12">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.holidays.data"
            :loading="data.holidayLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 335px)"
            style="min-width: 375px; margin-top: -5px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.holidayFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
              </div>
            </template>
            <Column
              class="p-col-1"
              :header="i18n.$t('ApplyHoliday').ApplyHolidayTable.CreatedAt.Show"
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.created_at, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyHoliday').ApplyHolidayTable.ApplyStartDate.Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.apply_start_date, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyHoliday').ApplyHolidayTable.ApplyEndDate.Show
              "
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.apply_end_date, "YYYY-MM-DD") }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="
                i18n.$t('ApplyHoliday').ApplyHolidayTable.HolidayItemName.Field
              "
              :header="
                i18n.$t('ApplyHoliday').ApplyHolidayTable.HolidayItemName.Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyHoliday').ApplyHolidayTable.ApplyDays.Field"
              :header="i18n.$t('ApplyHoliday').ApplyHolidayTable.ApplyDays.Show"
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyHoliday').ApplyHolidayTable.Reason.Field"
              :header="i18n.$t('ApplyHoliday').ApplyHolidayTable.Reason.Show"
            ></Column>
            <Column
              class="p-col-2"
              :header="i18n.$t('ApplyHoliday').ApplyHolidayTable.Status.Show"
            >
              <template #body="slotProps">
                {{
                  slotProps.data.status == 1
                    ? i18n.$t("ApplyBorrow").Reject
                    : slotProps.data.status == 2
                    ? i18n.$t("ApplyBorrow").Pass
                    : slotProps.data.status == 3
                    ? i18n.$t("ApplyBorrow").Doing
                    : i18n.$t("ApplyBorrow").UnKnow
                }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyHoliday').ApplyHolidayTable.AuditEmployerName.Show
              "
              :field="
                i18n.$t('ApplyHoliday').ApplyHolidayTable.AuditEmployerName
                  .Field
              "
            >
            </Column>
            <Column class="p-col-2">
              <template #body="slotProps">
                <Button
                  :label="i18n.$t('ApplyBorrow').Print"
                  icon="pi pi-print"
                  class="p-button-success p-mr-2"
                  @click="printSaveApplyHoliday(slotProps.data, printDom)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <div class="p-d-none">
    <div ref="printDom">
      <PrintHoliday />
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
