import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Document {
  getDocumentInfo(id) {
    return axios.get(
      `/api/manager/document/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getDocumentList(
    id,
    document_type_id,
    title,
    organization_id,
    department_id,
    status,
    published_at_begin,
    published_at_end,
    end_at_begin,
    end_at_end,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/document/list`,
      {
        params: {
          id: id,
          title: title,
          document_type_id: document_type_id,
          organization_id: organization_id,
          department_id: department_id,
          status: status,
          published_at_begin: published_at_begin,
          published_at_end: published_at_end,
          end_at_begin: end_at_begin,
          end_at_end: end_at_end,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getDocumentSelectList() {
    return axios.get(
      `/api/manager/document/select-list`,
      {
        params: {},
      },
      config
    );
  }
  addDocument(
    document_type_id,
    title,
    content,
    organization_id,
    department_id,
    status,
    published_at,
    end_at
  ) {
    return axios.post(
      `/api/manager/document`,
      {
        document_type_id: document_type_id,
        title: title,
        content: content,
        organization_id: organization_id,
        // department_id: department_id,
        status: status,
        published_at: published_at,
        end_at: end_at,
      },
      config
    );
  }
  editDocument(
    id,
    document_type_id,
    title,
    content,
    organization_id,
    department_id,
    status,
    published_at,
    end_at
  ) {
    return axios.put(
      `/api/manager/document`,
      {
        id: id,
        document_type_id: document_type_id,
        title: title,
        content: content,
        organization_id: organization_id,
        // department_id: department_id,
        status: status,
        published_at: published_at,
        end_at: end_at,
      },
      config
    );
  }
  editDocumentPDF(id, attachment, title, content) {
    return axios.put(
      `/api/manager/document/`,
      {
        id: id,
        attachment: attachment,
        title: title,
        content: content,
      },
      config
    );
  }
  deleteDocument(id) {
    return axios.delete(
      `/api/manager/document`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
