import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Borrow {
  getBorrowInfo(id) {
    return axios.get(
      `/api/manager/borrow/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getBorrowList(
    apply_employer_id,
    apply_employer_name,
    status,
    apply_date_begin,
    apply_date_end,
    organisation_id,
    page,
    page_num
  ) {
    return axios.get(
      `/api/manager/borrow/list`,
      {
        params: {
          apply_employer_id: apply_employer_id,
          apply_employer_name: apply_employer_name,
          status: status,
          apply_date_begin: apply_date_begin,
          apply_date_end: apply_date_end,
          organisation_id: organisation_id,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  editBorrow(
    id,
    dollar,
    exchange_rate,
    szl_dollar,
    reason,
    status,
    apply_date,
    organisation_id,
    audit_employer_id,
    apply_employer_id,
    spot_exchange_rate
  ) {
    return axios.put(
      `/api/manager/borrow`,
      {
        id: id,
        dollar: dollar,
        exchange_rate: exchange_rate,
        szl_dollar: szl_dollar,
        reason: reason,
        status: status,
        apply_date: apply_date,
        organisation_id: organisation_id,
        audit_employer_id: audit_employer_id,
        apply_employer_id: apply_employer_id,
        spot_exchange_rate: spot_exchange_rate,
      },
      config
    );
  }
  editBorrowStatus(id, status, reason, dollar) {
    return axios.put(
      `/api/manager/borrow`,
      {
        id: id,
        status: status,
        reason: reason,
        dollar: dollar,
      },
      config
    );
  }
}
