import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class SalaryItem {
  getSalaryItemInfo(id) {
    return axios.get(
      `/api/admin/salary-item/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getSalaryItemList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/salary-item/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getSalaryItemSelectList(name) {
    return axios.get(
      `/api/admin/salary-item/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchSalaryItem(datas) {
    return axios.post(
      `/api/admin/batch/salary-item`,
      {
        datas: datas,
      },
      config
    );
  }
  addSalaryItem(name, category, taxed, positive_or_negative) {
    return axios.post(
      `/api/admin/salary-item`,
      {
        name: name,
        category: category,
        taxed: taxed,
        positive_or_negative: positive_or_negative,
      },
      config
    );
  }
  editSalaryItem(id, name, category, taxed, positive_or_negative) {
    return axios.put(
      `/api/admin/salary-item`,
      {
        id: id,
        name: name,
        category: category,
        taxed: taxed,
        positive_or_negative: positive_or_negative,
      },
      config
    );
  }
  deleteSalaryItem(id) {
    return axios.delete(
      `/api/admin/salary-item`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
