import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class EmployerDetail {
  getEmployerDetailInfo() {
    return axios.get(
      `/api/self/employer-detail/`,
      {
        params: {},
      },
      config
    );
  }
}
