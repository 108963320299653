<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  toRefs,
  computed,
  ref,
  watch,
} from "vue";
import router from "../../router";
import Toast from "primevue/toast";
import InputNumber from "primevue/inputNumber";
import InputText from "primevue/inputText";
import Button from "primevue/button";
import DataTable from "primevue/dataTable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
// import AdminSpotExchangeRate from "../../api/salary/admin/spot_exchange_rate";
// import ManagerSpotExchangeRate from "../../api/salary/manager/spot_exchange_rate";

import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { useI18n } from "../../i18nPlugin";
import Dialog from "primevue/dialog";
import User from "../../api/salary/auth/user";
import Employer from "../../api/salary/self/employer";
import ManagerBouns from "../../api/salary/manager/bouns";
import AdminBouns from "../../api/salary/admin/bouns";
import { dateFormate } from "../../tool/formate";
import Organisation from "../../api/salary/admin/organisation";
import PrintApplication from "../../components/PrintApplication.vue";
import { printHTML } from "../../tool/print";
import africa_logo from "../../assets/africa_logo.jpg";

const i18n = useI18n();
const toast = useToast();
const store = useStore();
let userApi = new User();

// let admin_spot_exchange_rateAPI = new AdminSpotExchangeRate();
// let manager_spot_exchange_rateAPI = new ManagerSpotExchangeRate();

let manager_bounsAPI = new ManagerBouns();
let admin_bounsAPI = new AdminBouns();
let organisationAPI = new Organisation();
const printDom = ref(null);
const data = reactive({
  bounsDialog: false,
  OrganisationSearch: "",
  selectOrganisationList: [],
  StatusSearch: "",
  selectStatusList: [
    {
      id: 1,
      name: i18n.$t("ApplyBouns").Reject,
    },
    {
      id: 2,
      name: i18n.$t("ApplyBouns").Pass,
    },
    {
      id: 3,
      name: i18n.$t("ApplyBouns").Doing,
    },
  ],
  bounss: { data: null },
  bouns: null,
  bounsLoading: false,
  page: 1,
  page_num: 10,
  totalRecords: 0,
  exchange_rate: 0,
  employer: {
    organisation_name: "",
    name: "",
    english_name: "",
    position_name: "",
  },
  add_bouns: {
    dollar: 0,
    reason: "",
  },
  audit_bounsFilters: {
    apply_employer_id: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    apply_employer_name: { value: "", matchMode: "contains" },
    apply_date_begin: { value: "", matchMode: "contains" },
    apply_date_end: { value: "", matchMode: "contains" },
    organisation_id: { value: "", matchMode: "contains" },
  },
  excel: { data: null },
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {
  CheckUser();
  getBounsList();
  store.commit("set_logo", africa_logo);
  if (store.state.user.role == 1) {
    getOrganisationSelectList();
  }
});
const dt = ref();

async function printApplyBouns(bouns, dom) {
  //   await store.commit("set_bouns", bouns);
  await store.commit("set_payment_content", []);
  await store.commit("set_payment", {
    company_name: bouns.organisation_name,
    id: bouns.id,
    apply_employer_name: bouns.apply_employer_name,
    created_at: bouns.created_at,
    total_dollar: bouns.dollar,
    pay_date: bouns.created_at,
    description: bouns.reason,
    remark: "",
  });
  //   await store.commit("set_payment_content", data.payment_contents);
  // data.send = false;
  printHTML(dom);
}

function Excel() {
  switch (store.state.user.role) {
    case 1:
      admin_bounsAPI
        .getBounsList(
          data.audit_bounsFilters.apply_employer_id.value,
          data.audit_bounsFilters.apply_employer_name.value,
          data.audit_bounsFilters.status.value,
          data.audit_bounsFilters.apply_date_begin.value,
          data.audit_bounsFilters.apply_date_end.value,
          data.audit_bounsFilters.organisation_id.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            // data.excel.data = res.data.data.datas;
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].apply_date = dateFormate(tmp[i].apply_date, "YYYY-MM");
              tmp[i].status =
                tmp[i].status == 1
                  ? i18n.$t("ApplyBouns").Reject
                  : tmp[i].status == 2
                  ? i18n.$t("ApplyBouns").Pass
                  : tmp[i].status == 3
                  ? i18n.$t("ApplyBouns").Doing
                  : i18n.$t("ApplyBouns").UnKnow;
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });
      break;
    case 2:
      manager_bounsAPI
        .getBounsList(
          data.audit_bounsFilters.apply_employer_id.value,
          data.audit_bounsFilters.apply_employer_name.value,
          data.audit_bounsFilters.status.value,
          data.audit_bounsFilters.apply_date_begin.value,
          data.audit_bounsFilters.apply_date_end.value,
          data.audit_bounsFilters.organisation_id.value,
          1,
          500
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            // data.excel.data = res.data.data.datas;
            let tmp = res.data.data.datas;
            for (let i = 0; i < tmp.length; i++) {
              tmp[i].apply_date = dateFormate(tmp[i].apply_date, "YYYY-MM");
              tmp[i].status =
                tmp[i].status == 1
                  ? i18n.$t("ApplyBouns").Reject
                  : tmp[i].status == 2
                  ? i18n.$t("ApplyBouns").Pass
                  : tmp[i].status == 3
                  ? i18n.$t("ApplyBouns").Doing
                  : i18n.$t("ApplyBouns").UnKnow;
            }
            data.excel.data = tmp;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        })
        .then(() => {
          dt.value.exportCSV();
        });

      break;
  }
}
function SetBounsSuccess(bouns) {
  data.bounsDialog = true;
  data.add_bouns = bouns;
}

function SetBouns(id, status, reason, dollar) {
  switch (store.state.user.role) {
    case 1:
      admin_bounsAPI.editBounsStatus(id, status, reason, dollar).then((res) => {
        if (res.data.error_code == 0) {
          toast.add({
            severity: "success",
            summary: "Success Message",
            detail: i18n.$t("AuditBouns").Success,
            life: 3000,
          });
          data.bounsLoading = true;
          data.bounsDialog = false;
          getBounsList();
        } else {
          toast.add({
            severity: "warn",
            summary: "Warn Message",
            detail: res.data.error_text,
            life: 3000,
          });
        }
      });
      break;
    case 2:
      manager_bounsAPI
        .editBounsStatus(id, status, reason, dollar)
        .then((res) => {
          if (res.data.error_code == 0) {
            toast.add({
              severity: "success",
              summary: "Success Message",
              detail: i18n.$t("AuditBouns").Success,
              life: 3000,
            });
            data.bounsLoading = true;
            data.bounsDialog = false;
            getBounsList();
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
          }
        });
      break;
  }
}
function getOrganisationSelectList() {
  organisationAPI.getOrganisationSelectList("").then((res) => {
    if (res.data.error_code == 0) {
      data.selectOrganisationList = res.data.data.datas;
    } else {
      toast.add({
        severity: "warn",
        summary: "Warn Message",
        detail: res.data.error_text,
        life: 3000,
      });
    }
  });
}

function getBounsList() {
  switch (store.state.user.role) {
    case 1:
      admin_bounsAPI
        .getBounsList(
          data.audit_bounsFilters.apply_employer_id.value,
          data.audit_bounsFilters.apply_employer_name.value,
          data.audit_bounsFilters.status.value,
          data.audit_bounsFilters.apply_date_begin.value,
          data.audit_bounsFilters.apply_date_end.value,
          data.audit_bounsFilters.organisation_id.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.bounss.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.bounss.data = [];
              // data.page = 0;
            }
          }
          data.bounsLoading = false;
        });
      break;
    case 2:
      manager_bounsAPI
        .getBounsList(
          data.audit_bounsFilters.apply_employer_id.value,
          data.audit_bounsFilters.apply_employer_name.value,
          data.audit_bounsFilters.status.value,
          data.audit_bounsFilters.apply_date_begin.value,
          data.audit_bounsFilters.apply_date_end.value,
          data.audit_bounsFilters.organisation_id.value,
          data.page,
          data.page_num
        )
        .then((res) => {
          if (res.data.error_code == 0) {
            data.totalRecords = res.data.data.total_count;
            data.bounss.data = res.data.data.datas;
            data.page = res.data.data.page;
          } else {
            toast.add({
              severity: "warn",
              summary: "Warn Message",
              detail: res.data.error_text,
              life: 3000,
            });
            if (res.data.error_code == 8106) {
              data.totalRecords = 0;
              data.bounss.data = [];
              // data.page = 0;
            }
          }
          data.bounsLoading = false;
        });

      break;
  }
}

function CheckUser() {
  userApi.checkUser().then((res) => {
    if (res.data.error_code == 0) {
      console.log(res.data.data);
      store.commit("set_user", res.data.data);
    } else {
      router.push("/");
      store.commit("set_user", null);
    }
  });
}
function onPage(event) {
  console.log(event);
  data.page = event.page + 1;
}
watch(
  () => data.page,
  () => {
    data.bounsLoading = true;
    getBounsList();
  }
);
watch(
  () => data.StatusSearch,
  () => {
    data.bounsLoading = true;
    if (data.StatusSearch != null) {
      data.audit_bounsFilters.status.value =
        data.StatusSearch == null ? "" : data.StatusSearch.id;
    } else {
      data.audit_bounsFilters.status.value = "";
    }
    getBounsList();
  }
);
watch(
  () => data.audit_bounsFilters.apply_employer_id.value,
  () => {
    data.bounsLoading = true;
    getBounsList();
  }
);
watch(
  () => data.audit_bounsFilters.apply_employer_name.value,
  () => {
    data.bounsLoading = true;
    getBounsList();
  }
);
watch(
  () => data.date_search,
  async () => {
    data.bounsLoading = true;
    if (data.date_search != null) {
      const GMT_8 =
        data.date_search.getHours() -
        (8 + data.date_search.getTimezoneOffset() / 60);

      let date = new Date(data.date_search.toISOString());
      await date.setHours(GMT_8);
      data.audit_bounsFilters.apply_date_begin.value = date.toISOString();
      data.audit_bounsFilters.apply_date_end.value = date.toISOString();
    } else {
      data.audit_bounsFilters.apply_date_begin.value = "";
      data.audit_bounsFilters.apply_date_end.value = "";
    }
    getBounsList();
  }
);
watch(
  () => data.OrganisationSearch,
  () => {
    data.bounsLoading = true;
    data.audit_bounsFilters.organisation_id.value =
      data.OrganisationSearch == null ? "" : data.OrganisationSearch.id;
    getBounsList();
  }
);
</script>

<template>
  <Toast />
  <div class="p-mt-5">
    <div style="margin-top: 90px" class="p-text-left">
      <div class="p-grid">
        <!-- <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("AuditBouns").ExchangeRate }}
        </div>
        <div class="p-col-12">
          <span v-if="data.exchange_rate == 0" style="color: red">
            {{ i18n.$t("AuditBouns").NoExchangeRate }}
          </span>
          <span v-else class="p-text-danger"> 1:{{ data.exchange_rate }} </span>
        </div> -->
        <div style="background: lightgray" class="p-col-12">
          {{ i18n.$t("AuditBouns").Employer }}
        </div>
        <div class="p-col-12">
          <DataTable
            :lazy="true"
            :resizableColumns="true"
            columnResizeMode="fit"
            :value="data.bounss.data"
            :loading="data.bounsLoading"
            dataKey="id"
            responsiveLayout="scroll"
            :scrollable="true"
            scrollHeight="calc(100vh - 255px)"
            style="min-width: 375px; margin-top: -5px"
            :paginator="true"
            :rows="10"
            :totalRecords="data.totalRecords"
            v-model:filters="data.bounsFilters"
            :row-hover="true"
            @page="onPage($event)"
            @filter="onFilter($event)"
            paginatorTemplate="FirstPageLink PageLinks LastPageLink"
          >
            <template #header>
              <div class="table-header p-grid">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <Calendar
                    id="range"
                    v-model="data.date_search"
                    class="p-mr-1"
                    view="month"
                    dateFormat="yy/mm"
                    :manualInput="false"
                    :showIcon="true"
                    :placeholder="
                      i18n.$t('SpotExchangeRate').SpotExchangeRateTable
                        .RateOfMonth.Show
                    "
                    :showButtonBar="true"
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.audit_bounsFilters[
                        i18n.$t('AuditBouns').AuditBounsTable.ApplyEmployerID
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AuditBouns').AuditBounsTable.ApplyEmployerID.Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <InputText
                    style="width: 150px"
                    v-model="
                      data.audit_bounsFilters[
                        i18n.$t('AuditBouns').AuditBounsTable.ApplyEmployerName
                          .Field
                      ].value
                    "
                    :placeholder="
                      i18n.$t('AuditBouns').AuditBounsTable.ApplyEmployerName
                        .Show
                    "
                  />
                </span>
                <span class="p-input-icon-left p-mr-1">
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.StatusSearch"
                    :options="data.selectStatusList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('AuditBouns').AuditBounsTable.Status.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
                <span
                  class="p-input-icon-left p-ml-1"
                  v-if="store.state.user.role == 1"
                >
                  <i class="pi pi-search" />
                  <Dropdown
                    v-model="data.OrganisationSearch"
                    :options="data.selectOrganisationList"
                    optionLabel="name"
                    :filter="true"
                    :placeholder="
                      i18n.$t('Employer').EmployerTable.Organisation.Show
                    "
                    :showClear="true"
                  >
                    <template #value="slotProps">
                      <div
                        class="country-item country-item-value"
                        v-if="slotProps.value"
                      >
                        <div>{{ slotProps.value.name }}</div>
                      </div>
                      <span v-else>
                        {{ slotProps.placeholder }}
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </span>
              </div>
              <div class="p-w-100 p-text-right">
                <Button
                  icon="pi pi-book"
                  class="p-button-info"
                  label="Excel"
                  @click="Excel"
                />
              </div>
            </template>

            <Column
              class="p-col-1"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.ApplyDate.Show"
            >
              <template #body="slotProps">
                {{ dateFormate(slotProps.data.apply_date, "YYYY-MM") }}
              </template>
            </Column>
            <Column
              class="p-col-1"
              :field="
                i18n.$t('AuditBouns').AuditBounsTable.ApplyEmployerName.Field
              "
              :header="
                i18n.$t('AuditBouns').AuditBounsTable.ApplyEmployerName.Show
              "
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyBouns').ApplyBounsTable.USDollar.Field"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.USDollar.Show"
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyBouns').ApplyBounsTable.ExchangeRate.Field"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.ExchangeRate.Show"
            ></Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyBouns').ApplyBounsTable.ExchangeDollar.Show
              "
            >
              <template #body="slotProps">
                {{ slotProps.data.dollar * slotProps.data.exchange_rate }}
              </template>
            </Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyBouns').ApplyBounsTable.CurrencyName.Field"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.CurrencyName.Show"
            ></Column>
            <Column
              class="p-col-2"
              :field="i18n.$t('ApplyBouns').ApplyBounsTable.Reason.Field"
              :header="i18n.$t('ApplyBouns').ApplyBounsTable.Reason.Show"
            ></Column>
            <Column
              class="p-col-2"
              :header="
                i18n.$t('ApplyBouns').ApplyBounsTable.AuditEmployerName.Show
              "
              :field="
                i18n.$t('ApplyBouns').ApplyBounsTable.AuditEmployerName.Field
              "
            >
            </Column>

            <Column
              class="p-col-2"
              :header="i18n.$t('AuditBouns').AuditBounsTable.Status.Show"
            >
              <template #body="slotProps">
                <span
                  style="line-height: 37px"
                  v-if="slotProps.data.status != 3"
                >
                  {{
                    slotProps.data.status == 1
                      ? i18n.$t("ApplyBouns").Reject
                      : slotProps.data.status == 2
                      ? i18n.$t("ApplyBouns").Pass
                      : slotProps.data.status == 3
                      ? i18n.$t("ApplyBouns").Doing
                      : i18n.$t("ApplyBouns").UnKnow
                  }}
                  <Button
                    v-if="slotProps.data.status == 2"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click="
                      SetBouns(
                        slotProps.data.id,
                        1,
                        slotProps.data.reason,
                        slotProps.data.dollar
                      )
                    "
                  />
                </span>
                <div v-else>
                  <Button
                    icon="pi pi-check"
                    class="p-button-rounded p-button-success p-mr-2"
                    @click="SetBounsSuccess(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger"
                    @click="
                      SetBouns(
                        slotProps.data.id,
                        1,
                        slotProps.data.reason,
                        slotProps.data.dollar
                      )
                    "
                  />
                </div>
              </template>
            </Column>
            <Column>
              <template #body="slotProps">
                <Button
                  :label="i18n.$t('ApplyBouns').Print"
                  icon="pi pi-print"
                  class="p-button-success p-mr-2"
                  @click="printApplyBouns(slotProps.data, printDom)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <!-- 確認視窗 -->
  <Dialog
    v-model:visible="data.bounsDialog"
    :style="{ width: '450px', minWidth: '350px' }"
    :header="i18n.$t('AuditBouns').BounsInfo"
    :modal="true"
    class="p-fluid"
  >
    <div class="p-field">
      <label for="name">{{
        i18n.$t("AuditBouns").AuditBounsTable.USDollar.Show
      }}</label>
      <InputNumber
        id="name"
        v-model="data.add_bouns.dollar"
        mode="currency"
        currency="USD"
        locale="en-US"
        :minFractionDigits="2"
        :maxFractionDigits="5"
        required="true"
      />
    </div>
    <template #footer>
      <Button
        :label="i18n.$t('AuditBouns').Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="data.bounsDialog = false"
      />
      <Button
        :label="i18n.$t('AuditBouns').Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="
          SetBouns(
            data.add_bouns.id,
            2,
            data.add_bouns.reason,
            String(data.add_bouns.dollar)
          )
        "
      />
    </template>
  </Dialog>
  <div class="p-d-none">
    <div ref="printDom">
      <PrintApplication />
    </div>
  </div>
  <DataTable
    class="p-d-none"
    :value="data.excel.data"
    ref="dt"
    responsiveLayout="scroll"
  >
    <Column
      class="p-col-1"
      :header="i18n.$t('AuditBouns').AuditBounsTable.ApplyDate.Show"
      :field="i18n.$t('AuditBouns').AuditBounsTable.ApplyDate.Field"
    >
    </Column>
    <Column
      class="p-col-1"
      :field="i18n.$t('AuditBouns').AuditBounsTable.ApplyEmployerName.Field"
      :header="i18n.$t('AuditBouns').AuditBounsTable.ApplyEmployerName.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('AuditBouns').AuditBounsTable.USDollar.Field"
      :header="i18n.$t('AuditBouns').AuditBounsTable.USDollar.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('AuditBouns').AuditBounsTable.ExchangeRate.Field"
      :header="i18n.$t('AuditBouns').AuditBounsTable.ExchangeRate.Show"
    ></Column>
    <Column
      class="p-col-2"
      :field="i18n.$t('AuditBouns').AuditBounsTable.Reason.Field"
      :header="i18n.$t('AuditBouns').AuditBounsTable.Reason.Show"
    ></Column>
    <Column
      class="p-col-2"
      :header="i18n.$t('AuditBouns').AuditBounsTable.Status.Show"
      :field="i18n.$t('AuditBouns').AuditBounsTable.Status.Field"
    >
    </Column>
  </DataTable>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
