import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Nationality {
  getNationalityInfo(id) {
    return axios.get(
      `/api/admin/nationality/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getNationalityList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/nationality/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getNationalitySelectList(name) {
    return axios.get(
      `/api/admin/nationality/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchNationality(datas) {
    return axios.post(
      `/api/admin/batch/nationality`,
      {
        datas: datas,
      },
      config
    );
  }
  addNationality(name, description) {
    return axios.post(
      `/api/admin/nationality`,
      {
        name: name,
        description: description,
      },
      config
    );
  }
  editNationality(id, name, description) {
    return axios.put(
      `/api/admin/nationality`,
      {
        id: id,
        name: name,
        description: description,
      },
      config
    );
  }
  deleteNationality(id) {
    return axios.delete(
      `/api/admin/nationality`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
