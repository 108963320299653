import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class Employer {
  getEmployerInfo() {
    return axios.get(
      `/api/self/employer/`,
      {
        params: {},
      },
      config
    );
  }
  editEmployer(password) {
    return axios.put(
      `/api/self/employer/`,
      {
        password: password,
      },
      config
    );
  }
}
