import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class AccountType {
  getAccountTypeInfo(id) {
    return axios.get(
      `/api/admin/account-type/`,
      {
        params: {
          id: id,
        },
      },
      config
    );
  }
  getAccountTypeList(id, name, page, page_num) {
    return axios.get(
      `/api/admin/account-type/list`,
      {
        params: {
          id: id,
          name: name,
          page: page,
          page_num: page_num,
        },
      },
      config
    );
  }
  getAccountTypeSelectList(name) {
    return axios.get(
      `/api/admin/account-type/select-list`,
      {
        params: {
          name: name,
        },
      },
      config
    );
  }
  batchAccountType(datas) {
    return axios.post(
      `/api/admin/account-type/batch`,
      {
        datas: datas,
      },
      config
    );
  }
  addAccountType(name, description) {
    return axios.post(
      `/api/admin/account-type`,
      {
        name: name,
        description: description,
      },
      config
    );
  }
  editAccountType(id, name, description) {
    return axios.put(
      `/api/admin/account-type`,
      {
        id: id,
        name: name,
        description: description,
      },
      config
    );
  }
  deleteAccountType(id) {
    return axios.delete(
      `/api/admin/account-type`,
      {
        data: {
          ids: id,
        },
      },
      config
    );
  }
}
